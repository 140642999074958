import { createSlice } from "@reduxjs/toolkit";

const fallBack = createSlice({
    name: "fallBack",
    initialState: { value: false },
    reducers: {
        spinLoading: (state) => {
            state.value = true;
        },
        spinUnLoading: (state) => {
            state.value = false;
        }
    }
});

export const { spinLoading, spinUnLoading } = fallBack.actions;

export default fallBack.reducer;