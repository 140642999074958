import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IQueryParams, serializeAxiosError } from "app/shared/reducers/reducer.utils";
import axios from "axios";

export type dataStatistical = {
    name: string;
    deactivate: number;
    total: number;
    activate: number;
}
type TypeStatistical = {
    data: dataStatistical
}

const initialState: TypeStatistical = {
    data: {
        name: "",
        deactivate: 0,
        total: 0,
        activate: 0
    }
}

const apiUrl = 'api/statistical';
const apiUrlHotel = 'api/statistical-hotel';

// Actions

type TypeParams = {
    type: string;
    endTime?: string;
}

type TypePramsHotel = {
    hotelId?: string;
    endTime?: string;
}

type TParamFilterDevice = {
    startTime?: string;
    endTime?: string;
    idPack?: string;
    statusDevice?: boolean;
    deviceDeleted?: boolean;
    nameSearch?: string;
    page?: string | number;
    size?: string | number;
}

export const getStatisticalAsync = createAsyncThunk(
    'statistical/get_type',
    async (params: TypeParams) => {
        const apiUrlRequest = `${apiUrl}?type=${params.type}${params.endTime ? `&endTime=${params.endTime}` : ''}`;
        return axios.get<any>(apiUrlRequest);
    },
    { serializeError: serializeAxiosError }
)

export const getStatisticalHotelAsync = createAsyncThunk(
    'statistical/get_hotel',
    async ({ page, size, sort, endTime }: IQueryParams & { endTime?: string }) => {
        const apiUrlRequest = `${apiUrl}/hotel${sort ? `?page=${page}&size=${size}&sort=${sort}${endTime ? `&endTime=${endTime}` : ''}` : '?'}`;
        return axios.get<any>(apiUrlRequest);
    },
    { serializeError: serializeAxiosError }
)

export const getStatisticalChannelPackMasterAsync = createAsyncThunk(
    'satistical/get_channel_pack_master',
    async (params: TypePramsHotel) => {
        const apiUrlRequest = `${apiUrlHotel}/channelPackMaster?${params.hotelId ? `hotelId=${params.hotelId}` : ''}${params.endTime ? `&endTime=${params.endTime}` : ''}`;
        return axios.get<any>(apiUrlRequest);
    },
    { serializeError: serializeAxiosError }
)

export const getStatisticalDeviceAsync = createAsyncThunk(
    'satistical/get_device',
    async (params: TypePramsHotel) => {
        const apiUrlRequest = `${apiUrlHotel}/device?${params.hotelId ? `?hotelId=${params.hotelId}` : ''}${params.endTime ? `&endTime=${params.endTime}` : ''}`;
        return axios.get<any>(apiUrlRequest);
    },
    { serializeError: serializeAxiosError }
)

export const getStatisticalDevicePackAsync = createAsyncThunk(
    'satistical/get_device_pack',
    async (params: TypePramsHotel) => {
        const apiUrlRequest = `${apiUrlHotel}/devicePack?${params.hotelId ? `?hotelId=${params.hotelId}` : ''}${params.endTime ? `&endTime=${params.endTime}` : ''}`;
        return axios.get<any>(apiUrlRequest);
    },
    { serializeError: serializeAxiosError }
)

export const filterStatisticalDeviceAsync = createAsyncThunk(
    'statistical/filter_device',
    async (params: TParamFilterDevice) => {
        const apiUrlRequest = `${apiUrlHotel}/deviceChannelPackMaster?${params.startTime ? `startTime=${params.startTime}&` : ''}${params.endTime ? `endTime=${params.endTime}&` : ''}${params.idPack ? `idPack=${params.idPack}&` : ''}${params.statusDevice !== null ? `statusDevice=${params.statusDevice}&` : ''}${params.nameSearch ? `nameSearch=${params.nameSearch}&` : ''}page=${params.page}&${params.size ? `size=${params.size}` : ''}${params.deviceDeleted !== null ? `&deviceDeleted=${params.deviceDeleted}` : ''}`;
        return axios.get<any>(apiUrlRequest);
    },
    { serializeError: serializeAxiosError }
)

export const StatisticalSlice = createSlice({
    name: "statistical",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getStatisticalAsync.fulfilled, (state, action) => {
                state.data = action.payload.data;
            })
    },
});

export default StatisticalSlice.reducer;