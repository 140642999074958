import React from "react";
import "./style.scss";
import { Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";

const FallBack = ({ isLoading }: { isLoading: Boolean }) => {
    return <>
        {isLoading &&
            <div className="container-fallback-component">
                <div className="container-spin">
                    <Spinner 
                        color="light"
                        style={{
                            height: "60px",
                            width: "60px"
                        }}
                    />
                </div>
            </div>
        }
    </>
};

export default FallBack;