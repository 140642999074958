import React, { useState } from 'react';
import { useLoadScript, Autocomplete, Libraries, GoogleMap, Marker } from '@react-google-maps/api';
import { ValidatedField } from 'react-jhipster';

const libraries: Libraries = ['places'];

interface Place {
  address_components?: { long_name: string; short_name: string; types: string[] }[];
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
  formatted_address?: string;
}

export type SelectedPlace = {
  lat: number;
  lng: number;
};

export interface ILocationMapProp {
  location: ILocationMap;
}
export interface ILocationMap {
  long: number;
  lat: number;
}

type TLocationGoogle = {
  name: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  defaultValue: string;
  selectedPlace?: SelectedPlace;
  setSelectedPlace: React.Dispatch<React.SetStateAction<SelectedPlace>>;
  setFormattedAddress: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const LocationGoole = (props: TLocationGoogle) => {
  const { name, setFormattedAddress, selectedPlace, setSelectedPlace, placeholder, label, disabled, onChange, defaultValue } = props;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCrv2z2ZhOVT05DIO1Ii5kFcQf7ituPzuU',
    libraries,
    language: 'vi'
  });

  const mapContainerStyle = {
    height: '400px',
    width: '100%',
  };

  const center = {
    lat: -3.745,
    lng: -38.523,
  };

  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  const onLoad = (autoC: google.maps.places.Autocomplete) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace() as Place;
      if (place.address_components) {
        let city: string | undefined;
        let country: { longName: string; shortName: string } | undefined;
        for (const component of place.address_components) {
          const componentType = component.types[0];
          if (componentType === 'locality') {
            city = component.long_name;
          } else if (componentType === 'country') {
            country = {
              longName: component.long_name,
              shortName: component.short_name
            };
          }
        }
      }
      setSelectedPlace({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      });
      setFormattedAddress(place.formatted_address);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }
  if (!isLoaded) {
    return <div>Loading Maps</div>;
  }

  return (
    <div className="mb-3">
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <ValidatedField
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          label={label}
          onChange={onChange ? e => onChange(e) : null}
          type="text"
          defaultValue={defaultValue}
        />
      </Autocomplete>
      <GoogleMap mapContainerStyle={mapContainerStyle} center={selectedPlace || center} zoom={14}>
        {selectedPlace && <Marker position={selectedPlace}/>}
      </GoogleMap>
    </div>
  );
};

export default LocationGoole;
