import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IWelcome, defaultValue } from 'app/shared/model/welcome.model';

type TypeMultiDelete = {
  listId: string[];
}

const initialState: EntityState<IWelcome> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/welcomes';
const apiUrlExtendCreate = 'api/welcomes-extend/create'
const apiUrlExtend = 'api/welcomes-extend'

// Actions

export const getEntities = createAsyncThunk(
  'welcome/fetch_entity_list',
  async ({ page, size, sort, hotelId, nameSearch }: IQueryParams & {hotelId?: string; nameSearch?: string}) => {
    const requestUrl = `${apiUrlExtend}?${sort ? `page=${page}&size=${size}&sort=${sort}${hotelId ? `&hotelId=${hotelId}`: ''}&nameSearch=${nameSearch}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<IWelcome[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'welcome/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IWelcome>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const uploadFile = createAsyncThunk(
  'welcome/upload_single_file',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.post<IWelcome>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const uploadMultipleFile = createAsyncThunk(
  'welcome/upload_multiple_file',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.post<IWelcome>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);


export const createEntity = createAsyncThunk(
  'welcome/create_entity',
  async ({hotelId, ...entity}: IWelcome & {hotelId?: string}, thunkAPI) => {
    const requestUrl = `${apiUrlExtendCreate}?${hotelId ? `hotelId=${hotelId}` : ''}`; 
    const result = await axios.post<IWelcome>(requestUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'welcome/update_entity',
  async (entity: IWelcome, thunkAPI) => {
    const result = await axios.put<IWelcome>(`${apiUrlExtend}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'welcome/partial_update_entity',
  async (entity: IWelcome, thunkAPI) => {
    const result = await axios.patch<IWelcome>(`${apiUrlExtend}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'welcome/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IWelcome>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const multiDeleteWelcome = createAsyncThunk(
  'hotelExtend/multi_delete_hotel_extend',
  async (params: TypeMultiDelete, thunkAPI) => {
    const requestUrl = `${apiUrlExtend}/delete-many`;
    const result = await axios.post(requestUrl, params);
    return result;
  },
  { serializeError: serializeAxiosError }
)
// slice

export const WelcomeSlice = createEntitySlice({
  name: 'welcome',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(multiDeleteWelcome.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          updateSuccess: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, multiDeleteWelcome), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = WelcomeSlice.actions;

// Reducer
export default WelcomeSlice.reducer;
