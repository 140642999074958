import room from 'app/entities/room/room.reducer';
import userInformation from 'app/entities/user-information/user-information.reducer';
import welcome from 'app/entities/welcome/welcome.reducer';
import contract from 'app/entities/contract/contract.reducer';
import contractExtend from 'app/entities/contract/contract-extend.reducer';
import pack from 'app/entities/pack/pack.reducer';
import channelPackMaster from 'app/entities/channel-pack-master/channel-pack-master.reducer';
import channelPackMasterExtend from 'app/entities/channel-pack-master/channel-pack-master-extend.reducer';
import channel from 'app/entities/channel/channel.reducer';
import channelExtend from 'app/entities/channel/channel-extend.reducer';
import serviceHotelExtend from 'app/entities/service-hotel/service-hotel-extend.reducer';
import menuServiceExtend from 'app/entities/menu-service/menu-service-extend.reducer';
import serviceHotel from 'app/entities/service-hotel/service-hotel.reducer';
import apiKey from 'app/entities/api-key/api-key.reducer';
import menuService from 'app/entities/menu-service/menu-service.reducer';
import seriesMovie from 'app/entities/series-movie/series-movie.reducer';
import seriesMovieExtend from 'app/entities/series-movie/series-movie-extend.reducer';
import hotel from 'app/entities/hotel/hotel.reducer';
import hotelPack from 'app/entities/hotel-pack/hotel-pack.reducer';
import hotelExtend from 'app/entities/hotel/hotel-extend.reducer';
import confirmDevice from './confirmDevice';
import device from 'app/entities/device/device.reducer';
import userInformationExtend from 'app/entities/user-information/user-information-extend.reducer';
import introHotel from 'app/entities/intro-hotel/intro-hotel.reducer';
import introHotelExtend from 'app/entities/intro-hotel/intro-hotel-extend.reducer';
import authorities from './authority.reducer';
import notificationApiKeyExtend from 'app/entities/notification-api-key/notification-api-key-extend.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  room,
  userInformation,
  welcome,
  contract,
  contractExtend,
  pack,
  channelPackMaster,
  channelPackMasterExtend,
  channel,
  channelExtend,
  serviceHotel,
  serviceHotelExtend,
  menuServiceExtend,
  apiKey,
  menuService,
  seriesMovie,
  seriesMovieExtend,
  hotel,
  hotelExtend,
  hotelPack,
  confirmDevice,
  device,
  userInformationExtend,
  introHotel,
  introHotelExtend,
  authorities,
  notificationApiKeyExtend,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
