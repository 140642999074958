import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { JhiItemCount, JhiPagination, TextFormat, Translate, ValidatedField, getSortState, translate, Storage } from 'react-jhipster';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, Table } from 'reactstrap';
import { APP_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { TYPE_SERVICE_PACK, getEntities, multiDeleteChannelPackMaster } from './channel-pack-master-extend.reducer';
import ModalConfirm from 'app/components/modal-confirm/ModalConfirm';
import { useDebounce } from 'app/utils/customHooks/useDebounce';
import InputSearch from 'app/components/input-search';
import { Tooltip } from 'antd';
import { EPath } from 'app/utils/constants/EPath';
import { Status } from 'app/shared/model/enumerations/status.model';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';
import { toast } from 'react-toastify';

export const ChannelPackMasterExtend = () => {
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'createdAt', 'desc'), location.search)
  );

  const channelPackMasterList = useAppSelector(state => state.channelPackMasterExtend.entities);
  const loading = useAppSelector(state => state.channelPackMasterExtend.loading);
  const totalItems = useAppSelector(state => state.channelPackMasterExtend.totalItems);

  const [textSearch, setTextSearch] = useState('');
  const debouncedValue = useDebounce<string>(textSearch, 500);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const openConfirmDelete = () => {
    setOpenConfirm(!openConfirm);
  };

  const onSubmitConfirmDelete = async () => {
    dispatch(spinLoading());
    try {
      const params = {
        listId: selectedRows
      };
      await dispatch(multiDeleteChannelPackMaster(params));
      setSelectedRows([]);
      toast.success("Success");
    } catch (err) {
      toast.error(err.errorFields[0].errors[0]);
    } finally {
      openConfirmDelete();
      dispatch(spinUnLoading());
      sortEntities();
    }
  };

  const selectRow = (id: string): void => {
    setSelectedRows([...selectedRows, id]);
  };

  const deleteRow = (id: string): void => {
    setSelectedRows(selectedRows.filter(i => i !== id));
  };

  useEffect(() => {
    selectedRows.length > 0 && selectedRows.length === channelPackMasterList.length ? setSelectAll(true) : setSelectAll(false);
  }, [selectedRows]);

  useEffect(() => {
    selectAll ? setSelectedRows(channelPackMasterList.map(channelPackMaster => channelPackMaster.id)) : null;
    if (!selectAll && selectedRows.length === channelPackMasterList.length) {
      setSelectedRows([]);
    }
  }, [selectAll]);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        type: TYPE_SERVICE_PACK.ALL,
        ...(role.includes(AUTHORITIES.ADMIN) ? { hotelId: id } : {}),
        nameSearch: textSearch
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=createdAt,desc`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, debouncedValue]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1]
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="channel-pack-master-heading" data-cy="ChannelPackMasterHeading">
        <div className="d-flex gap-3 align-items-center">
          {role.includes(AUTHORITIES.ADMIN) ? <FontAwesomeIcon icon="arrow-left" onClick={() => navigate(EPath.ChannelPackMaster)} /> : null}
          <Translate contentKey="hotelTvApp.channelPackMaster.home.title">Channel Pack Masters</Translate>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="w-25">
            <InputSearch name={'textSearch'} onChange={e => setTextSearch(e.target.value)} />
          </div>
          <div>
            <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              <Translate contentKey="hotelTvApp.channelPackMaster.home.refreshListLabel">Refresh List</Translate>
            </Button>
            {(!role.includes(AUTHORITIES.MONITOR) && role.includes(AUTHORITIES.ADMIN))
              ? (<Link
                to={role.includes(AUTHORITIES.ADMIN) ? `${EPath.ChannelPackMasterADMIN}/${id}/new` : `${EPath.ChannelPackMaster}/new`}
                className="btn btn-primary jh-create-entity"
                id="jh-create-entity"
                data-cy="entityCreateButton"
              >
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="hotelTvApp.channelPackMaster.home.createLabel">Create new Channel Pack Master</Translate>
              </Link>)
              : null
            }
          </div>
        </div>
      </h2>
      <div className="table-responsive">
        <div className="position-absolute mt-2">
          {selectedRows.length > 0 ? (
            <div className="d-flex align-items-center fs-6 gap-2">
              <div style={{ minWidth: '120px' }}>
                {translate('global.multiDelete.titleSelected')}: {selectedRows.length}
              </div>
              {(!role.includes(AUTHORITIES.MONITOR) && role.includes(AUTHORITIES.ADMIN))
                ? (<Button size="sm" color="danger" onClick={openConfirmDelete}>
                  <FontAwesomeIcon icon="trash" />{' '}
                </Button>)
                : null
              }
            </div>
          ) : null}
        </div>
        {channelPackMasterList && channelPackMasterList.length > 0 ? (
          <Table responsive className="table table-hover mt-5">
            <thead>
              <tr>
                {role.includes(AUTHORITIES.ADMIN) && <th className="hand position-sticky start-0 bg-white">
                  <ValidatedField
                    className="checkbox-select-table-row"
                    type="checkbox"
                    name={'checkboxAllRow'}
                    checked={selectAll}
                    onChange={e => {
                      const { checked } = e.target;
                      checked ? setSelectAll(true) : setSelectAll(false);
                    }}
                  />
                </th>}
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hotelTvApp.channelPackMaster.stt">STT</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="hotelTvApp.channelPackMaster.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="hotelTvApp.channelPackMaster.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand text-center" onClick={sort('liveTVNumber')}>
                  <Translate contentKey="hotelTvApp.channelPackMaster.liveTVNumber">Live TV Number</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand text-center" onClick={sort('movieNumber')}>
                  <Translate contentKey="hotelTvApp.channelPackMaster.movieNumber">Movie Number</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('packageDuration')}>
                  <Translate contentKey="hotelTvApp.channelPackMaster.packageDuration">Package Duration</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand text-center" onClick={sort('status')}>
                  <Translate contentKey="hotelTvApp.channelPackMaster.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('author')}>
                  <Translate contentKey="hotelTvApp.channelPackMaster.author">Author</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="hotelTvApp.channelPackMaster.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="hotelTvApp.channelPackMaster.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand text-center position-sticky end-0 bg-white">
                  <Translate contentKey="hotelTvApp.hotel.actions">Actions</Translate>
                </th>
              </tr>
            </thead>
            <tbody>
              {channelPackMasterList.map((channelPackMaster, i) => (
                <tr
                  key={`entity-${i}`}
                  data-cy="entityTable"
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(
                      role.includes(AUTHORITIES.ADMIN)
                        ? `${EPath.ChannelPackMasterADMIN}/${id}/${channelPackMaster.id}`
                        : `${EPath.ChannelPackMaster}/${channelPackMaster.id}`
                    )
                  }
                >
                  {role.includes(AUTHORITIES.ADMIN) && <td className="position-sticky start-0 bg-white">
                    <div onClick={e => e.stopPropagation()}>
                      <ValidatedField
                        className="checkbox-select-table-row"
                        type="checkbox"
                        name={`channel${i}`}
                        checked={selectedRows.includes(channelPackMaster.id)}
                        onChange={e => {
                          const { checked } = e.target;
                          if (checked) {
                            !selectedRows.includes(channelPackMaster.id) ? selectRow(channelPackMaster.id) : null;
                          } else {
                            deleteRow(channelPackMaster.id);
                          }
                        }}
                      />
                    </div>
                  </td>}
                  <td className='stt'>{(paginationState.activePage) * paginationState.itemsPerPage + i + 1 - paginationState.itemsPerPage}</td>
                  <Tooltip placement="topLeft" title={channelPackMaster.name}>
                    <td className="data-row-lg">{channelPackMaster.name}</td>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={channelPackMaster.description}>
                    <td className="data-row-lg">{channelPackMaster.description}</td>
                  </Tooltip>
                  <td className='data-row-lg text-center'>{channelPackMaster.liveTVNumber}</td>
                  <td className='data-row-lg text-center'>{channelPackMaster.movieNumber}</td>
                  <td className='data-row-lg'>
                    {channelPackMaster.packageDuration 
                      ? `${channelPackMaster.packageDuration} ${translate("hotelTvApp.apiKey.day")}` 
                      : translate("hotelTvApp.apiKey.noDuration")
                    }
                  </td>
                  <td className='data-row-sm text-center'>{channelPackMaster.status
                    ? channelPackMaster.status === Status.ACTIVATE
                      ? <Badge color="primary">{translate("global.form.active")}</Badge>
                      : <Badge color="danger">{translate("global.form.deactive")}</Badge>
                    : null
                  }
                  </td>
                  <td className='data-row-lg'>{channelPackMaster.author}</td>
                  <td className='data-row-sm'>
                    {channelPackMaster.createdAt ? (
                      <TextFormat type="date" value={channelPackMaster.createdAt} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td className='data-row-sm'>
                    {channelPackMaster.updatedAt ? (
                      <TextFormat type="date" value={channelPackMaster.updatedAt} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td className="text-end position-sticky end-0 bg-white">
                    <div className="btn-group flex-btn-group-container" onClick={e => e.stopPropagation()}>
                      <Button
                        tag={Link}
                        to={
                          role.includes(AUTHORITIES.ADMIN)
                            ? `${EPath.ChannelPackMasterADMIN}/${id}/${channelPackMaster.id}`
                            : `${EPath.ChannelPackMaster}/${channelPackMaster.id}`
                        }
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        hidden={role.includes(AUTHORITIES.MONITOR)}
                        tag={Link}
                        to={
                          role.includes(AUTHORITIES.ADMIN)
                            ? `${EPath.ChannelPackMasterADMIN}/${id}/${channelPackMaster.id}/edit`
                            : `${EPath.ChannelPackMaster}/${channelPackMaster.id}/edit`
                        }
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      {role.includes(AUTHORITIES.ADMIN) && <Button
                        hidden={role.includes(AUTHORITIES.MONITOR)}
                        tag={Link}
                        to={
                          role.includes(AUTHORITIES.ADMIN)
                            ? `${EPath.ChannelPackMasterADMIN}/${id}/${channelPackMaster.id}/delete`
                            : `${EPath.ChannelPackMaster}/${channelPackMaster.id}/delete`
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hotelTvApp.channelPackMaster.home.notFound">No Channel Pack Masters found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={channelPackMasterList && channelPackMasterList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <ModalConfirm
        isOpen={openConfirm}
        toggle={openConfirmDelete}
        title={translate('global.multiDelete.confirm.titleConfirm')}
        onSubmit={onSubmitConfirmDelete}
      >
        {`
          ${translate('global.multiDelete.confirm.contentConfirm')} 
          ${selectedRows.length} 
          ${translate('global.multiDelete.confirm.dataConfirm')}?
        `}
      </ModalConfirm>
    </div>
  );
};

export default ChannelPackMasterExtend;
