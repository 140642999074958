import React from 'react';
import { Route } from 'react-router-dom';
import { Storage } from 'react-jhipster';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import WelcomeExtend from 'app/entities/welcome/welcome-extend';
import WelcomeExtendDetail from 'app/entities/welcome/welcome-extend-detail';
import WelcomeExtendUpdate from 'app/entities/welcome/welcome-extend-update';
import WelcomeDeleteDialogExtend from './welcome-delete-dialog-extend';
import { AUTHORITIES } from 'app/config/constants';
import SelectHotelPage from 'app/components/select-hotel-page/SelectHotelPage';

const WelcomeRoutes = () => {
  const role = Storage.session.get('role');

  return (
    <ErrorBoundaryRoutes>
      {role.includes(AUTHORITIES.ADMIN)
        ? <>
          <Route index element={<SelectHotelPage />} />
          <Route path='hotel/:id' element={<WelcomeExtend />} />
        </>
        : <Route index element={<WelcomeExtend />} />
      }
      {role.includes(AUTHORITIES.ADMIN)
        ? <Route path="hotel/:id/new" element={<WelcomeExtendUpdate />} />
        : <Route path="new" element={<WelcomeExtendUpdate />} />
      }
      {role.includes(AUTHORITIES.ADMIN)
        ?<Route path="hotel/:id/:idWelcome">
          <Route index element={<WelcomeExtendDetail />} />
          <Route path="edit" element={<WelcomeExtendUpdate />} />
          <Route path="delete" element={<WelcomeDeleteDialogExtend />} />
        </Route>
        :<Route path=":id">
          <Route index element={<WelcomeExtendDetail />} />
          <Route path="edit" element={<WelcomeExtendUpdate />} />
          <Route path="delete" element={<WelcomeDeleteDialogExtend />} />
        </Route>
      }
    </ErrorBoundaryRoutes>
  );
};

export default WelcomeRoutes;
