import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import HotelDeleteDialog from './hotel-delete-dialog';
import HotelDetailExtend from './hotel-detail-extend';
import HotelExtend from './hotel-extend';
import HotelUpdateExtend from './hotel-update-extend';
import HotelDeleteDialogExtend from './hotel-delete-dialog-extend';

const HotelRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<HotelExtend />} />
    <Route path="new" element={<HotelUpdateExtend />} />
    <Route path=":id">
      <Route index element={<HotelDetailExtend />} />
      <Route path="edit" element={<HotelUpdateExtend />} />
      <Route path="delete" element={<HotelDeleteDialogExtend />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default HotelRoutes;
