import { JhiItemCount, JhiPagination, Storage, Translate, ValidatedField, getSortState, translate } from 'react-jhipster';
import './home.scss';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getStatisticalAsync, getStatisticalChannelPackMasterAsync, getStatisticalDeviceAsync, getStatisticalDevicePackAsync, getStatisticalHotelAsync } from './statistical.reducer';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePicker, DatePickerProps } from 'antd';
import { AUTHORITIES } from 'app/config/constants';
import HotelDashboard, { TDevicePack, TGenner } from './hotel-dashboard';

export const Dashboard = () => {
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [statisticalUser, setStatisticalUser] = useState<TGenner>();
  const [statisticalMovie, setStatisticalMovie] = useState({ seriesMovie: 0, total: 0, singleMovie: 0 });
  const [statisticalTv, setStatisticalTv] = useState({ deactivate: 0, total: 0, activate: 0 });
  const [statisticalAboutHotel, setStatisticalAboutHotel] = useState({ deactivate: 0, total: 0, activate: 0 });
  const [statisticalChannelPackMaster, setStatisticalChannelPackMaster] = useState<TGenner & { packAssign: number }>()
  const [statisticalDevice, setStatisticalDevice] = useState<TGenner>()
  const [statisticalDevicePack, setStatisticalDevicePack] = useState<TDevicePack>()
  const [statisticalHotels, setStatisticalHotels] = useState([]);
  const [endTime, setEndTime] = useState("");
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const getStatisticalUser = () => {
    dispatch(getStatisticalAsync({ type: 'User', endTime: endTime })).then((res: any) => {
      setStatisticalUser(res.payload.data);
    })
  }
  const getStatisticalMovie = () => {
    dispatch(getStatisticalAsync({ type: 'MOVIE', endTime: endTime })).then((res: any) => {
      setStatisticalMovie(res.payload.data);
    });
  }
  const getStatisticalTv = () => {
    dispatch(getStatisticalAsync({ type: 'TV', endTime: endTime })).then((res: any) => {
      setStatisticalTv(res.payload.data);
    });
  }
  const getStatisticalAboutHotel = () => {
    dispatch(getStatisticalAsync({ type: 'ABOUT_HOTEL', endTime: endTime })).then((res: any) => {
      setStatisticalAboutHotel(res.payload.data);
    });
  }

  const getStatisticalChannelPackMaster = () => {
    dispatch(getStatisticalChannelPackMasterAsync({ endTime: endTime })).then((res: any) => {
      setStatisticalChannelPackMaster(res.payload.data);
    });
  };

  const getStatisticalDevice = () => {
    dispatch(getStatisticalDeviceAsync({ endTime: endTime })).then((res: any) => {
      setStatisticalDevice(res.payload.data);
    });
  };

  const getStatisticalDevicePack = () => {
    dispatch(getStatisticalDevicePackAsync({ endTime: endTime })).then((res: any) => {
      setStatisticalDevicePack(res.payload.data);
    });
  };

  const getListHotel = () => {
    dispatch(
      getStatisticalHotelAsync({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        endTime: endTime
      })
    ).then((res: any) => setStatisticalHotels(res.payload.data));
  }

  const getStatisticalByHotel = () => {
    getStatisticalChannelPackMaster();
    getStatisticalDevice();
    getStatisticalDevicePack();
    getStatisticalUser();
  }

  const sortEntities = () => {
    getListHotel();
    getStatisticalUser();
    getStatisticalMovie();
    getStatisticalTv();
    getStatisticalAboutHotel();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    role.includes(AUTHORITIES.ADMIN) ? sortEntities() : getStatisticalByHotel();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, endTime]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1]
      });
    }
  }, [location.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  const handleSyncList = () => {
    role.includes(AUTHORITIES.ADMIN) ? sortEntities() : getStatisticalByHotel();
  };

  const onChangeTimeEnd: DatePickerProps['onChange'] = (date) => {
    setEndTime(date?.toISOString() ?? "");
  }

  return (
    <div className='container-dashboard'>
      <h2 id="api-key-heading" data-cy="ApiKeyHeading">
        <Translate contentKey="home.title">Statistical</Translate>
        <div className="d-flex justify-content-between align-items-center my-4">
          <div className='d-flex align-items-center gap-4'>
            <h4 className='m-0'>{translate("home.labelSelectDate")}:</h4>
            <DatePicker
              style={{ height: "38px", width: "200px" }}
              format={'DD/MM/YYYY'} placeholder={translate("home.placeholderSelectDate")}
              onChange={onChangeTimeEnd}
            />
          </div>
          <Button className="me-2" color="info" onClick={handleSyncList}>
            <FontAwesomeIcon icon="sync" />{' '}
            <Translate contentKey="home.refreshListLabel">Refresh List</Translate>
          </Button>
        </div>
      </h2>
      {(role.includes(AUTHORITIES.HOTEL) || role.includes(AUTHORITIES.MONITOR)) &&
        <HotelDashboard
          statisHotelChannelPackMaster={statisticalChannelPackMaster}
          statisHotelDevice={statisticalDevice} 
          statisHotelDevicePack={statisticalDevicePack} 
          statisHotelUser={statisticalUser} 
        />
      }
      {role.includes(AUTHORITIES.ADMIN) &&
        <>
          <Row md="12">
            <Col md="3">
              <div className='title-statistical'>
                {translate('home.statisticalTv')}:
              </div>
            </Col>
            <Col md="3">
              <div>{translate('home.total')}: {statisticalTv?.total}</div>
            </Col>
            <Col md="3">
              <div>{translate('home.active')}: {statisticalTv?.activate}</div>
            </Col>
            <Col md="3">
              <div>{translate('home.deactive')}: {statisticalTv?.deactivate}</div>
            </Col>
          </Row>
          <Row md="12">
            <Col md="3">
              <div className='title-statistical'>{translate('home.statisticalMovie')}:</div>
            </Col>
            <Col md="3">
              <div>{translate('home.total')}: {statisticalMovie?.total}</div>
            </Col>
            <Col md="3">
              <div>{translate('home.filmSeries')}: {statisticalMovie?.seriesMovie}</div>
            </Col>
            <Col md="3">
              <div>{translate('home.filmFeature')}: {statisticalMovie?.singleMovie}</div>
            </Col>
          </Row>
          <Row md="12">
            <Col md="3">
              <div className='title-statistical'>{translate('home.statisticalUser')}:</div>
            </Col>
            <Col md="3">
              <div>{translate('home.total')}: {statisticalUser?.activate}</div>
            </Col>
            <Col md="3">
              <div>{translate('home.active')}: {statisticalUser?.activate}</div>
            </Col>
            <Col md="3">
              <div>{translate('home.deactive')}: {statisticalUser?.deactivate}</div>
            </Col>
          </Row>
          <Row md="12">
            <Col md="3">
              <div className='title-statistical'>{translate('home.statisticalAboutHotel')}:</div>
            </Col>
            <Col md="3">
              <div>{translate('home.total')}: {statisticalAboutHotel?.total}</div>
            </Col>
          </Row>
        </>
      }
      {role.includes(AUTHORITIES.ADMIN) &&
        <div className='mt-4'>
          <div className='title-statistical'>{translate('home.statisticalHotel')}:</div>
          <div>
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand">
                    <Translate contentKey="home.stt">STT</Translate>
                  </th>
                  <th className="hand">
                    <Translate contentKey="home.nameHotel">Name Hotel</Translate>
                  </th>
                  <th className="hand">
                    <Translate contentKey="home.idHotel">ID Hotel</Translate>
                  </th>
                  <th className="hand">
                    <Translate contentKey="home.packageActived">Package Actived</Translate>
                  </th>
                  <th className="hand">
                    <Translate contentKey="home.deviceActived">Device Actived</Translate>
                  </th>
                </tr>
              </thead>
              <tbody>
                {statisticalHotels?.map((hotel, i) =>
                  <tr key={hotel.idHotel}>
                    <td>{paginationState.itemsPerPage * (paginationState.activePage - 1) + i + 1}</td>
                    <td>{hotel.nameHotel}</td>
                    <td>{hotel.idHotel}</td>
                    <td>{hotel.activateService}</td>
                    <td>{hotel.activateDevice}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {statisticalAboutHotel.total ? (
              <div className={statisticalHotels && statisticalHotels.length > 0 ? '' : 'd-none'}>
                <div className="justify-content-center d-flex">
                  <JhiItemCount page={paginationState.activePage} total={statisticalAboutHotel.total} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
                </div>
                <div className="justify-content-center d-flex">
                  <JhiPagination
                    activePage={paginationState.activePage}
                    onSelect={handlePagination}
                    maxButtons={5}
                    itemsPerPage={paginationState.itemsPerPage}
                    totalItems={statisticalAboutHotel.total}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      }
    </div>
  );
};

export default Dashboard;
