import dayjs from 'dayjs';
import { IRoom } from 'app/shared/model/room.model';
import { IChannelPackMaster } from 'app/shared/model/channel-pack-master.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface IApiKey {
  id?: string;
  key?: string | null;
  uniqueKey?: string | null;
  author?: string | null;
  activationDate?: string | null;
  expiryDate?: string | null;
  status?: Status | null;
  language?: Language | null;
  isActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isDeleted?: boolean | null;
  room?: IRoom | null;
  channelPackMaster?: IChannelPackMaster | null;
}

export const defaultValue: Readonly<IApiKey> = {
  isActive: false,
  isDeleted: false,
};
