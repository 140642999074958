import {
  AudioOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PictureOutlined,
  UploadOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { IMedia } from 'app/entities/welcome/welcome-extend-update';
import { getFileName, handleDownloadFile } from 'app/utils/helpers/func';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface IUploadSingleFile {
  media: IMedia;
  isVideo: boolean;
  isAudio: boolean;
  isSingleImage: boolean;
  isFile?: boolean;
  setAudioOrVideoOrImage: Dispatch<SetStateAction<IMedia | undefined>>;
  style?: React.CSSProperties | undefined;
  url?: string;
  isNew: boolean;
  accept: string;
  setUrl?: Dispatch<React.SetStateAction<string>>;
}

const UploadSingleFile = ({
  setAudioOrVideoOrImage,
  isAudio,
  isSingleImage,
  isVideo,
  isFile,
  media,
  style,
  url,
  isNew,
  accept,
  setUrl
}: IUploadSingleFile) => {
  const [preview, setPreview] = useState<UploadFile<any>[]>([] as UploadFile<any>[]);
  const [isEdit, setShowEdit] = useState(false);
  const [showFile, setShowFile] = useState(true);

  useEffect(() => {
    if (url) {
      setShowEdit(false);
    } else {
      setShowEdit(true);
    }
  }, [url]);

  useEffect(() => {
    handlePreviewListFile();
  }, [media]);

  const handlePreviewListFile = () => {
    if (media?.audio && isAudio) {
      setPreview([media.audio]);
    }
    if (media?.video && isVideo) {
      setPreview([media.video]);
    }
    if (media?.image && isSingleImage) {
      setPreview([media.image]);
    }
  };

  const handleChange = async info => {
    if (info.fileList.length > 0 && isAudio) {
      setAudioOrVideoOrImage && setAudioOrVideoOrImage({ ...media, audio: info.fileList[0] });
    }
    if (info.fileList.length > 0 && isVideo) {
      setAudioOrVideoOrImage && setAudioOrVideoOrImage({ ...media, video: info.fileList[0] });
    }
    if (info.fileList.length > 0 && isSingleImage) {
      setAudioOrVideoOrImage && setAudioOrVideoOrImage({ ...media, image: info.fileList[0] });
    }
    if (info.fileList.length > 0 && isFile) {
      setAudioOrVideoOrImage && setAudioOrVideoOrImage({ ...media, all: info.fileList[0] });
    }
  };

  const handleRemove = () => {
    if (media?.audio && isAudio) {
      setAudioOrVideoOrImage({ ...media, audio: undefined });
    }
    if (media?.video && isVideo) {
      setAudioOrVideoOrImage({ ...media, video: undefined });
    }
    if (media?.image && isSingleImage) {
      setAudioOrVideoOrImage({ ...media, image: undefined });
    }
    if (media?.all && isFile) {
      setAudioOrVideoOrImage({ ...media, all: undefined });
    }
  };

  const getIcon = (isAudio, isSingleImage, isVideo) => {
    if (isAudio && !isSingleImage && !isVideo) {
      return <AudioOutlined  />;
    }
    if (!isAudio && isSingleImage && !isVideo) {
      return <PictureOutlined  />;
    }
    if (!isAudio && !isSingleImage && isVideo) {
      return <VideoCameraOutlined  />;
    }
    return <UploadOutlined  />;
  };

  return (
    <div className="single_file mb-3" style={style}>
      {isEdit || isFile ? (
        <div className="d-flex flex-column">
          <div className="wrapper_edit">
            <Upload
              accept={accept}
              showUploadList={true}
              name="audio"
              onRemove={handleRemove}
              onChange={info => handleChange(info)}
              multiple={false}
              maxCount={1}
              className={`wrapper_button ${isEdit ? 'mx-0' : ''}`}
            >
              <div className="button_upload">
                <Button icon={getIcon(isAudio, isSingleImage, isVideo)}>
                  {isAudio && 'Upload Audio'}
                  {isSingleImage && 'Upload Image'}
                  {isVideo && 'Upload Video'}
                  {isFile && 'Upload File'}
                </Button>
              </div>
              <div className="wrapper_img">
                {media?.image?.originFileObj?.type.includes('image/') && isSingleImage && (
                  <img
                    className="mt-3"
                    style={{ height: '300px' }}
                    src={
                      media?.image?.originFileObj?.type.includes('image/')
                        ? URL.createObjectURL(media?.image?.originFileObj as unknown as File)
                        : (url as string)
                    }
                  />
                )}
              </div>
            </Upload>
            {url && !isFile && (
              <div className="wrapper_edit">
                <CloseOutlined onClick={() => setShowEdit(false)}  />
              </div>
            )}
          </div>
          {url && showFile && isFile && (
            <div className="d-flex gap-3">
              <div>{getFileName(url)}</div>
              <DownloadOutlined onClick={() => handleDownloadFile(url, getFileName(url))}  />
              <DeleteOutlined
                onClick={() => {
                  setAudioOrVideoOrImage({
                    all: null
                  });
                  setShowFile(false);
                }}
                style={{
                  color: 'red'
                }}
                
              />
            </div>
          )}
        </div>
      ) : (
        <>
          {isVideo && !isNew && url && (
            <div className="container-edit-file">
              <div className='d-flex align-items-center'>
                <video key={url} style={{ height: '400px', width: '600px' }} controls>
                  <source src={url} />
                </video>
                {!isEdit && <EditOutlined className='ms-4' onClick={() => setShowEdit(true)}  />}
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div>{getFileName(url)}</div>
                <DownloadOutlined onClick={() => handleDownloadFile(url, getFileName(url))}  />
                <DeleteOutlined
                  onClick={() => {
                    setUrl(null);
                    setAudioOrVideoOrImage({
                      ...media,
                      video: undefined
                    });
                  }}
                  style={{
                    color: 'red'
                  }}
                  
                />
              </div>
            </div>
          )}
          {isAudio && !isNew && url && (
            <div className="container-edit-file">
              <div className='d-flex align-items-center'>
                <audio key={url} controls>
                  <source src={url} />
                </audio>
                {!isEdit && <EditOutlined className='ms-4' onClick={() => setShowEdit(true)}  />}
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div>{getFileName(url)}</div>
                <DownloadOutlined onClick={() => handleDownloadFile(url, getFileName(url))}  />
                <DeleteOutlined
                  onClick={() => {
                    setUrl(null);
                    setAudioOrVideoOrImage({
                      ...media,
                      audio: undefined
                    });
                  }}
                  style={{
                    color: 'red'
                  }}
                  
                />
              </div>
            </div>
          )}
          {isSingleImage && !isNew && url && (
            <div className="container-edit-file">
              <div className='d-flex align-items-center'>
                <img className="mt-3" style={{ height: '400px' }} src={url} />
                {!isEdit && <EditOutlined className='ms-4' onClick={() => setShowEdit(true)}  />}
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div>{getFileName(url)}</div>
                <DownloadOutlined onClick={() => handleDownloadFile(url, getFileName(url))}  />
                <DeleteOutlined
                  onClick={() => {
                    if (media && media.image) {
                      const { image, ...rest } = media;
                      setAudioOrVideoOrImage(rest);
                    } else {
                      setAudioOrVideoOrImage(null);
                    }
                    setUrl(null);
                  }}
                  style={{
                    color: 'red'
                  }}
                  
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UploadSingleFile;
