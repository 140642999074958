// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../imgs/imageFallback.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".upload-image-about-hotel {\n  height: 280px;\n}\n.upload-image-about-hotel .wrapper_img img {\n  height: 200px !important;\n}\n\n.introHotelDetail .slick-dots li {\n  background-color: #8b6969 !important;\n}\n\n.asd {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}", "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/intro-hotel/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEI;EACE,wBAAA;AAAN;;AAME;EACE,oCAAA;AAHJ;;AAOA;EACE,yDAAA;AAJF","sourcesContent":[".upload-image-about-hotel {\n  height: 280px;\n\n  .wrapper_img {\n    img {\n      height: 200px !important;\n    }\n  }\n}\n\n.introHotelDetail {\n  .slick-dots li {\n    background-color: #8b6969 !important;\n  }\n}\n\n.asd {\n  background-image: url(\"../../imgs/imageFallback.jpeg\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
