import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Badge } from 'reactstrap';
import { Translate, translate, Storage } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity, getInformationEntity } from './user-information-extend.reducer';
import { EPath } from 'app/utils/constants/EPath';

export const UserInformationDetailExtend = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const role = Storage.session.get('role');
  const userInformationEntity = useAppSelector(state => state.userInformationExtend.entity);
  const [userLogin, setUserLogin] = useState({});

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getInformationEntity()).then((res) => setUserLogin(res.payload?.["data"]));
  }, []);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userInformationDetailsHeading">
          <Translate contentKey="hotelTvApp.userInformation.detail.title">UserInformation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <Translate contentKey="hotelTvApp.userInformation.hotel">Hotel</Translate>
          </dt>
          <dd>{userInformationEntity.hotel ? userInformationEntity.hotel.name : ''}</dd>
          <dt>
            <Translate contentKey="hotelTvApp.userInformation.role">Role</Translate>
          </dt>
          <dd>{userInformationEntity.userDTO?.authorities.map((i) => `${i.substring(5)}, `)}</dd>
          <dt>
            <Translate contentKey="hotelTvApp.userInformation.status">Status</Translate>
          </dt>
          <dd>{userInformationEntity.userDTO?.activated
            ? <Badge color="success">{translate("global.form.active")}</Badge>
            : <Badge color="danger">{translate("global.form.deactive")}</Badge>}
          </dd>
          <dt>
            <Translate contentKey="hotelTvApp.userInformation.login">Username</Translate>
          </dt>
          <dd>{userInformationEntity.userDTO?.login}</dd>
          <dt>
            <Translate contentKey="hotelTvApp.userInformation.email">Email</Translate>
          </dt>
          <dd>{userInformationEntity.userDTO?.email}</dd>
          <dt>
            <Translate contentKey="hotelTvApp.userInformation.name">Name</Translate>
          </dt>
          <dd>{userInformationEntity.name}</dd>
          <dt>
            <Translate contentKey="hotelTvApp.userInformation.phoneNumber">Phone Number</Translate>
          </dt>
          <dd>{userInformationEntity.phoneNumber}</dd>
          <dt>
            <Translate contentKey="hotelTvApp.userInformation.position">Position</Translate>
          </dt>
          <dd>{userInformationEntity.position}</dd>
        </dl>
        <Button onClick={() => history.back()} color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {userLogin && !(userLogin["indexUser"] > 0) &&
          <Button
            tag={Link}
            to={`${EPath.UserInformation}/${userInformationEntity.id}/edit`}
            replace color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        }
      </Col>
    </Row>
  );
};

export default UserInformationDetailExtend;
