import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate, Storage, translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Badge, Button, Col, Row } from 'reactstrap';
import { APP_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Carousel } from 'antd';
import ReadMore from 'app/components/ReadMore/ReadMore';
import { getEntity } from './intro-hotel.reducer';
import { Image } from 'antd';
import './style.scss';
import { EPath } from 'app/utils/constants/EPath';
import { Status } from 'app/shared/model/enumerations/status.model';

export const IntroHotelDetailExtend = () => {
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const { idIntroHotel } = useParams<'idIntroHotel'>();

  useEffect(() => {
    role.includes(AUTHORITIES.ADMIN) ? dispatch(getEntity(idIntroHotel)) : dispatch(getEntity(id))
  }, []);

  const introHotelEntity = useAppSelector(state => state.introHotel.entity);
  return (
    <Row className="introHotelDetail">
      <Col md="8">
        <h2 data-cy="introHotelDetailsHeading">
          <Translate contentKey="hotelTvApp.introHotel.detail.title">IntroHotel</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="title">
              <Translate contentKey="hotelTvApp.introHotel.title">Title</Translate>
            </span>
          </dt>
          <dd>{introHotelEntity.title}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="hotelTvApp.introHotel.status">Status</Translate>
            </span>
          </dt>
          <dd>{introHotelEntity.status === Status.ACTIVATE
            ? <Badge color="primary">{translate("global.form.active")}</Badge>
            : <Badge color="danger">{translate("global.form.deactive")}</Badge>}
          </dd>
          <dt>
            <span id="author">
              <Translate contentKey="hotelTvApp.introHotel.author">Author</Translate>
            </span>
          </dt>
          <dd>{introHotelEntity.author}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="hotelTvApp.introHotel.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {introHotelEntity.createdAt ? <TextFormat value={introHotelEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="hotelTvApp.introHotel.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {introHotelEntity.updatedAt ? <TextFormat value={introHotelEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
        </dl>
        <Carousel autoplay className="mb-3" style={{ backgroundColor: 'whitesmoke', width: '700px' }}>
          {introHotelEntity?.setMedias &&
            introHotelEntity?.setMedias?.map((media, i) => (
              <div key={i} className="border-1">
                <Image
                  width={700}
                  height={400}
                  src={media.imgUrl}
                  style={{ objectFit: 'cover' }}
                  fallback={'content/images/imageFallback.jpeg'}
                />
                <ReadMore text={media.textMedia} />
              </div>
            ))}
        </Carousel>
        <Button
          id="cancel-save"
          data-cy="entityCreateCancelButton"
          onClick={() => history.back()}
          color="info"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          hidden={role.includes(AUTHORITIES.MONITOR)}
          tag={Link}
          to={role.includes(AUTHORITIES.ADMIN)
            ? `${EPath.IntroHotelADMIN}/${id}/${introHotelEntity.id}/edit`
            : `${EPath.IntroHotel}/${introHotelEntity.id}/edit`
          }
          replace color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default IntroHotelDetailExtend;
