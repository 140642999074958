import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { Storage } from 'react-jhipster'
import { AUTHORITIES } from 'app/config/constants';
import SelectHotelPage from 'app/components/select-hotel-page/SelectHotelPage';
import { Inform } from 'app/shared/model/enumerations/inform.model';
import NotificationApiKeyExtend from './notification-api-key-extend';

const NotificationApiKeyRouter = () => {
    return (
        <ErrorBoundaryRoutes>
            <Route index element={<NotificationApiKeyExtend />} />
        </ErrorBoundaryRoutes>
    );
}

export default NotificationApiKeyRouter;
