export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  HOTEL: 'ROLE_ADMIN_HOTEL',
  CONTENT_MANAGER: 'ROLE_USER_CONTENT_MANAGER',
  DEVICE_MANAGER: 'ROLE_USER_DEVICE_MANAGER',
  MONITOR: 'ROLE_USER_MONITOR',
  SERVICE_PACK: 'ROLE_USER_SERVICE_PACK',
  WELCOME: 'ROLE_USER_WELCOME'
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error'
};

export const LANGUAGES_UNIT = [
  {
    value: 'VN',
    label: 'VND'
  },
  {
    value: 'EN',
    label: 'USD'
  },
  {
    value: 'JA',
    label: 'JPY'
  },
  {
    value: 'KO',
    label: 'KRW'
  }
];

export const STATUS_ENUM = [
  {
    value: 'ACTIVATE',
    label: 'ACTIVATE'
  },
  {
    value: 'DEACTIVATE',
    label: 'DEACTIVATE'
  }
];

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
