import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './contract-extend.reducer';
import './contract.scss';
import { getFileName, handleDownloadFile } from 'app/utils/helpers/func';
import { DownloadOutlined } from '@ant-design/icons';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

export const ContractDetailExtend = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const contractEntity = useAppSelector(state => state.contractExtend.entity);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="contractDetailsHeading">
          <Translate contentKey="hotelTvApp.contract.detail.title">Contract</Translate>
        </h2>
        {/* Hotel details start */}
        <h5>
          <Translate contentKey="hotelTvApp.contract.detail.hotelDetail">Hotel Detail</Translate>
        </h5>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.hotel.name">Hotel Name</Translate>
              </th>
              <td>{contractEntity?.hotel?.companyName}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.hotel.companyName">Company Name</Translate>
              </th>
              <td>{contractEntity?.hotel?.companyName}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.hotel.idHotel">ID Hotel</Translate>
              </th>
              <td>{contractEntity?.hotel?.idHotel}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.hotel.hotelAddress">Hotel Address</Translate>
              </th>
              <td>{contractEntity?.hotel?.hotelAddress}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.hotel.taxCode">Tax Code</Translate>
              </th>
              <td>{contractEntity?.hotel?.taxCode}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.hotel.representative">Legal Representative</Translate>
              </th>
              <td>{contractEntity?.hotel?.contact}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.hotel.position">Position</Translate>
              </th>
              <td>{contractEntity?.hotel?.position}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.hotel.phoneNumber">Phone Number</Translate>
              </th>
              <td>{contractEntity?.hotel?.phoneNumber}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.hotel.email">Email</Translate>
              </th>
              <td>{contractEntity?.hotel?.email}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.hotel.website">Website</Translate>
              </th>
              <td>{contractEntity?.hotel?.website}</td>
            </tr>
          </tbody>
        </table>
        {/* Hotel details end */}
        {/* ----------------------------- */}
        {/* Contract details start */}
        <h5>
          <Translate contentKey="hotelTvApp.contract.detail.contractDetail">Hotel Detail</Translate>
        </h5>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.contract.contractCode">Contract Code</Translate>
              </th>
              <td>{contractEntity.contractCode}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.contract.contractName">Contract Name</Translate>
              </th>
              <td>{contractEntity.contractName}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.contract.contractNumber">Contract Number</Translate>
              </th>
              <td>{contractEntity.contractNumber}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.contract.signDate">Sign Date</Translate>
              </th>
              <td>
                {contractEntity.signDate ? <TextFormat value={contractEntity.signDate} type="date" format={APP_DATE_FORMAT} /> : null}
              </td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.contract.activationDate">Activation Date</Translate>
              </th>
              <td>
                {contractEntity.activationDate ? (
                  <TextFormat value={contractEntity.activationDate} type="date" format={APP_DATE_FORMAT} />
                ) : null}
              </td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.contract.expire">Expire</Translate>
              </th>
              <td>{contractEntity.expire ? <TextFormat value={contractEntity.expire} type="date" format={APP_DATE_FORMAT} /> : null}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.contract.pretaxValue">Pretax Value</Translate>
              </th>
              <td>{contractEntity.pretaxValue}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.contract.tax">Tax</Translate>
              </th>
              <td>{contractEntity.tax}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.contract.totalValue">Total Value</Translate>
              </th>
              <td>{contractEntity.totalValue}</td>
            </tr>
            <tr>
              <th>
                <Translate contentKey="hotelTvApp.contract.description">Description</Translate>
              </th>
              <td>{contractEntity.description}</td>
            </tr>
          </tbody>
        </table>
        {/* Contract details end */}
        {/* Contract File */}
        {contractEntity.fileUpload && (
          <>
            <th>
              <Translate contentKey="hotelTvApp.contract.fileUpload">File Upload</Translate>
            </th>
            <div className="d-flex gap-3 align-items-center mb-3">
              <div>{getFileName(contractEntity.fileUpload ?? '')}</div>
              <DownloadOutlined
                style={{ fontSize: 20, cursor: 'pointer' }}
                onClick={() => handleDownloadFile(contractEntity.fileUpload, getFileName(contractEntity.fileUpload ?? ''))}
              />
            </div>
          </>
        )}
        <Button tag={Link} to="/contract" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/contract/${contractEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ContractDetailExtend;
