import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { UploadFile } from 'antd';
import MultipleUploadImage from 'app/components/upload/MultipleUploadImage';
import { Status } from 'app/shared/model/enumerations/status.model';
import { ResponseS3MultipleImg, uploadMultipleFileS3 } from '../s3-upload/s3.upload';
import { IResponsePayloadMultiple, IUploadFileExtend } from '../welcome/welcome-extend-update';
import { createEntity, getEntity, reset, updateEntity } from './hotel-extend.reducer';
import LocationGoole, { SelectedPlace } from 'app/components/location-google';
import { FormProvider, useForm } from 'react-hook-form';
import CommonInput from 'app/components/CommonInput';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

export const HotelUpdateExtend = () => {
  const dispatch = useAppDispatch();
  const [images, setImages] = useState<IUploadFileExtend[]>([]);
  const [fileList, setFileList] = useState<UploadFile[] | ResponseS3MultipleImg[]>([]);
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const hotelEntity = useAppSelector(state => state.hotelExtend.entity);
  const loading = useAppSelector(state => state.hotelExtend.loading);
  const updating = useAppSelector(state => state.hotelExtend.updating);
  const updateSuccess = useAppSelector(state => state.hotelExtend.updateSuccess);
  const statusValues = Object.keys(Status);
  const [selectedPlace, setSelectedPlace] = useState<SelectedPlace>();
  const [hotelAddress, setHotelAddress] = useState<string>("");

  const methods = useForm({
    mode: 'onSubmit', criteriaMode: 'firstError', reValidateMode: 'onChange'
  });

  const handleClose = () => {
    navigate('/hotel' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (!isNew && !loading) {
      if (hotelEntity?.imageHotels?.length > 0) {
        setImages(hotelEntity?.imageHotels);
      }
    }
  }, [hotelEntity?.imageHotels]);

  useEffect(() => {
    setHotelAddress(hotelEntity.hotelAddress);
    setSelectedPlace({
      lat: hotelEntity.lat,
      lng: hotelEntity.lon
    })
  }, [hotelEntity]);

  const getSlideShow = async () => {
    let mediaArr: ResponseS3MultipleImg[] = [];
    if (fileList.length > 0) {
      const resp3 = (await dispatch(uploadMultipleFileS3(fileList as UploadFile<any>[]))) as IResponsePayloadMultiple;
      if (resp3?.payload?.length > 0) {
        mediaArr = resp3?.payload;
      }
    }
    return mediaArr;
  };

  const updateSlideShow = async (currentSlide: ResponseS3MultipleImg[]) => {
    const newImages = await getSlideShow();

    return [...currentSlide, ...newImages];
  };

  const saveEntity = async values => {
    dispatch(spinLoading());
    const slideShows = isNew ? await getSlideShow() : await updateSlideShow(images);

    const entity = {
      ...hotelEntity,
      ...values,
      imageHotels: slideShows,
      hotelAddress: hotelAddress,
      lat: selectedPlace.lat,
      lon: selectedPlace.lng
    };

    if (isNew) {
      await dispatch(createEntity(entity));
    } else {
      await dispatch(updateEntity(entity));
    }
    dispatch(spinUnLoading());
  };

  useEffect(() => {
    isNew
      ? methods.reset({
        status: 'ACTIVATE'
      })
      : methods.reset({
        status: 'ACTIVATE',
        ...hotelEntity
      })
  }, [hotelEntity]);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hotelTvApp.hotel.home.createOrEditLabel" data-cy="HotelCreateUpdateHeading">
            {isNew
              ? <Translate contentKey="hotelTvApp.hotel.home.createLabel">Create a Hotel</Translate>
              : <Translate contentKey="hotelTvApp.hotel.home.editLabel">Edit a Hotel</Translate>
            }
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(saveEntity)}>
                <CommonInput
                  name='name'
                  label={
                    <p style={{ margin: 0 }}>
                      {`${translate('hotelTvApp.hotel.name')}`}
                      {<span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  type='text'
                  placeholder={translate('hotelTvApp.hotel.name')}
                  rules={{
                    required: {
                      value: true,
                      message: translate('hotelTvApp.hotel.message.missNameHotel')
                    }
                  }}
                />
                <CommonInput
                  name='companyName'
                  label={translate('hotelTvApp.hotel.companyName')}
                  type='text'
                  placeholder={translate('hotelTvApp.hotel.companyName')}
                />
                {!isNew &&
                  <CommonInput
                    name='idHotel'
                    label={translate('hotelTvApp.hotel.idHotel')}
                    type='text'
                    placeholder={translate('hotelTvApp.hotel.idHotel')}
                    disabled
                  />
                }
                <LocationGoole
                  name="hotelAddress"
                  defaultValue={hotelAddress}
                  selectedPlace={selectedPlace}
                  setSelectedPlace={setSelectedPlace}
                  setFormattedAddress={setHotelAddress}
                  label={translate('hotelTvApp.hotel.hotelAddress')}
                  placeholder={translate('hotelTvApp.hotel.instructHotelAddress')}
                />
                <CommonInput
                  name='taxCode'
                  label={translate('hotelTvApp.hotel.taxCode')}
                  type='text'
                  placeholder={translate('hotelTvApp.hotel.taxCode')}
                />
                <CommonInput
                  name='website'
                  label={translate('hotelTvApp.hotel.website')}
                  type='text'
                  placeholder={translate('hotelTvApp.hotel.website')}
                />
                <CommonInput
                  name='description'
                  label={translate('hotelTvApp.hotel.note')}
                  type='text'
                  placeholder={translate('hotelTvApp.hotel.note')}
                />
                <CommonInput
                  name='phoneNumber'
                  label={translate('hotelTvApp.hotel.phoneNumber')}
                  type='text'
                  placeholder={translate('hotelTvApp.hotel.phoneNumber')}
                />
                <MultipleUploadImage
                  fileList={fileList}
                  setFileList={setFileList}
                  slideShows={hotelEntity.listImage}
                  isNew={isNew}
                  images={images}
                  setImages={setImages}
                  maxFile={20}
                />
                <CommonInput
                  name='contact'
                  label={translate('hotelTvApp.hotel.representative')}
                  type='text'
                  placeholder={translate('hotelTvApp.hotel.representative')}
                />
                <CommonInput
                  name='position'
                  label={translate('hotelTvApp.hotel.position')}
                  type='text'
                  placeholder={translate('hotelTvApp.hotel.position')}
                />
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/hotel" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </form>
            </FormProvider>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default HotelUpdateExtend;
