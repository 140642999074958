import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from 'antd';
import { APP_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect } from 'react';
import { TextFormat, Translate, translate, Storage } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Badge, Button, Col, Row } from 'reactstrap';
import { getEntity } from './welcome-extend.reducer';
import { Status } from 'app/shared/model/enumerations/status.model';
import { EPath } from 'app/utils/constants/EPath';
import { Type } from 'app/shared/model/enumerations/type.model';

export const WelcomeExtendDetail = () => {
  const dispatch = useAppDispatch();
  const role = Storage.session.get('role');
  const { id } = useParams<'id'>();
  const { idWelcome } = useParams<'idWelcome'>();
  const typeValues = Object.keys(Type);
  const welcomeEntity = useAppSelector(state => state.welcome.entity);
  
  useEffect(() => {
    role.includes(AUTHORITIES.ADMIN) ? dispatch(getEntity(idWelcome)) : dispatch(getEntity(id));
  }, []);

  const renderLibaryWelcome = () => {
    switch (welcomeEntity.type) {
      case 'VIDEO':
        return (
          <>
            <dt>
              <Translate contentKey={'hotelTvApp.welcome.video'}>Video</Translate>
            </dt>
            {welcomeEntity.videoUrl ? (
              <video style={{ height: '300px', width: '500px' }} controls>
                <source src={welcomeEntity.videoUrl} />
              </video>
            ) : (
              translate('hotelTvApp.welcome.message.noVideo')
            )}
          </>
        );
      case 'IMAGE':
        return (
          <>
            <dt>
              <Translate contentKey={'hotelTvApp.welcome.image&audio'}>Image & Audio</Translate>
            </dt>
            <dd className="mb-3">{<img width={200} height={200} style={{ objectFit: 'cover' }} src={welcomeEntity.imageBackground} />}</dd>
            {welcomeEntity.audioUrl && (
              <audio controls>
                <source src={welcomeEntity.audioUrl} />
              </audio>
            )}
          </>
        );
      default:
        return (
          <>
            <dt>
              <span id="maxImageSlide">
                <Translate contentKey="hotelTvApp.welcome.maxImageSlide">Max Image Slide</Translate>
              </span>
            </dt>
            <dd>{welcomeEntity.maxImageSlide}</dd>
            <dt>
              <Translate contentKey={'hotelTvApp.welcome.slide&audio'}>Slide & Audio</Translate>
            </dt>
            <dd>
              <Image.PreviewGroup>
                <div className="d-flex gap-3 flex-wrap mb-3">
                  {welcomeEntity.slideShows?.map((img, i) => (
                    <Image key={i} width={100} height={100} src={img?.imgUrl} />
                  ))}
                </div>
              </Image.PreviewGroup>
            </dd>
          </>
        );
    }
  };
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="welcomeDetailsHeading">
          <Translate contentKey="hotelTvApp.welcome.detail.title">Welcome</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="title">
              <Translate contentKey="hotelTvApp.welcome.title">Title</Translate>
            </span>
          </dt>
          <dd>{welcomeEntity.title}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="hotelTvApp.welcome.status">Status</Translate>
            </span>
          </dt>
          <dd>
            {welcomeEntity.status === Status.ACTIVATE ? (
              <Badge color="primary">{translate('global.form.active')}</Badge>
            ) : (
              <Badge color="danger">{translate('global.form.deactive')}</Badge>
            )}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="hotelTvApp.welcome.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{welcomeEntity.updatedAt ? <TextFormat value={welcomeEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="hotelTvApp.welcome.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{welcomeEntity.createdAt ? <TextFormat value={welcomeEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="hotelTvApp.welcome.typeDisplay">Type Display</Translate>
            </span>
          </dt>
          <dd>{translate('hotelTvApp.Type.' + typeValues.filter((type) => type === welcomeEntity.type))}</dd>
          <dt>
            <span id="contentVi">
              <Translate contentKey="hotelTvApp.welcome.contentVi">Content Vi</Translate>
            </span>
          </dt>
          <dd>{welcomeEntity.contentVi}</dd>
          <dt>
            <span id="contentJa">
              <Translate contentKey="hotelTvApp.welcome.contentJa">Content Ja</Translate>
            </span>
          </dt>
          <dd>{welcomeEntity.contentJa}</dd>
          <dt>
            <span id="contentKo">
              <Translate contentKey="hotelTvApp.welcome.contentKo">Content Ko</Translate>
            </span>
          </dt>
          <dd>{welcomeEntity.contentKo}</dd>
          <dt>
            <span id="contentEn">
              <Translate contentKey="hotelTvApp.welcome.contentEn">Content En</Translate>
            </span>
          </dt>
          <dd>{welcomeEntity.contentEn}</dd>
          {renderLibaryWelcome()}
        </dl>
        <dt>
          <span id="imageUrl">
            <Translate contentKey="hotelTvApp.welcome.slideShowSmall">SubSlide</Translate>
          </span>
        </dt>
        <Image.PreviewGroup>
          <div className="d-flex gap-3 flex-wrap mb-3">
            {welcomeEntity?.slideShowSmalls?.map((img, i) => (
              <Image key={i} width={100} height={100} src={img?.imgUrl} />
            ))}
          </div>
        </Image.PreviewGroup>
        <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => history.back()} color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          hidden={role.includes(AUTHORITIES.MONITOR)}
          tag={Link}
          to={
            role.includes(AUTHORITIES.ADMIN)
              ? `${EPath.WelcomeADMIN}/${id}/${welcomeEntity.id}/edit`
              : `${EPath.Welcome}/${welcomeEntity.id}/edit`
          }
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default WelcomeExtendDetail;
