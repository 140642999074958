import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ETypeFile, uploadSingleFileS3 } from '../s3-upload/s3.upload';
import { IResponsePayload } from '../welcome/welcome-extend-update';
import { getEntity, updateLogoHotel } from '../hotel/hotel-extend.reducer';
import { Button } from 'reactstrap';
import { Storage, Translate, translate } from 'react-jhipster';
import { ID_HOTEL } from 'app/shared/reducers/authentication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AUTHORITIES } from 'app/config/constants';
import { useNavigate, useParams } from 'react-router-dom';
import './style.scss';
import { faImage, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CONST_CONVERSION, variables } from 'app/utils/constants/variables';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

export const LogoHotel = () => {
  const hotelEntity = useAppSelector(state => state.hotelExtend.entity);
  const role = Storage.session.get('role');
  const idHotel = Storage.session.get(ID_HOTEL);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [limitSize, setLimitSize] = useState(false);
  const [logoHotel, setLogoHotel] = useState<string | null>(null);
  const refInputFile = useRef<HTMLInputElement | null>(null);
  const { id } = useParams<'id'>();

  useEffect(() => {
    role.includes(AUTHORITIES.ADMIN) ? dispatch(getEntity(id)) : dispatch(getEntity(idHotel));
  }, []);

  useEffect(() => {
    if (hotelEntity) {
      setLogoHotel(hotelEntity.logoHotel);
    }
  }, [hotelEntity]);

  const selectAvatar = () => {
    refInputFile.current?.click();
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0];
    if (image) {
      try {
        setLimitSize((image.size / CONST_CONVERSION.VALUE) > variables.IMAGE_SIZE)
        setFile(image);
        setLogoHotel(URL.createObjectURL(image));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSaveData = url => {
    role.includes(AUTHORITIES.ADMIN)
      ? dispatch(
        updateLogoHotel({
          logoHotel: url,
          hotelId: id
        })
      )
      : dispatch(
        updateLogoHotel({
          logoHotel: url
        })
      );
  };

  const deleteLogo = () => {
    setFile(null);
    setLogoHotel('');
    refInputFile.current.value = '';
  };

  const submitChangeLogoHotel = async file => {
    dispatch(spinLoading());
    if (!limitSize) {
      if (logoHotel) {
        const urlLogoHotel =
          file &&
          ((await dispatch(
            uploadSingleFileS3({
              file: file,
              type: ETypeFile.image
            })
          )) as IResponsePayload);
        if (urlLogoHotel) {
          await handleSaveData(urlLogoHotel.payload?.imgUrl);
        }
      } else {
        await handleSaveData(null);
      }
    }
    dispatch(spinUnLoading());
  };

  return (
    <>
      <h2>
        <div className="d-flex gap-3 align-items-center">
          {role.includes(AUTHORITIES.ADMIN) ? (
            <FontAwesomeIcon className="cursor-pointer" icon="arrow-left" onClick={() => navigate('/logo-hotel')} />
          ) : null}
          <Translate contentKey="hotelTvApp.logoHotel.title">Logo Hotel</Translate>
        </div>
      </h2>
      <div className="d-flex flex-column justify-content-start align-items-center">
        <div className="container-logo-hotel mb-3">
          {!role.includes(AUTHORITIES.MONITOR)
            ? <>{logoHotel ? (
              <h4 className="position-absolute cursor-pointer" style={{ right: "-30px" }} onClick={deleteLogo}>
                <FontAwesomeIcon color="red" icon={faXmark} />
              </h4>
            ) : null}
            </>
            : null
          }
          <input ref={refInputFile} type="file" accept="image/*" onChange={e => handleImageChange(e)} className="input-file" />
          <div className='w-100 d-flex justify-content-center'>
            <div className='icon-image-select' onClick={selectAvatar}><FontAwesomeIcon icon={faImage} /> Upload image</div>
          </div>
          <div className="logo-hotel">
            {logoHotel && <img src={logoHotel} />}
          </div>
        </div>
        <div className='mb-3'>{`${translate('entity.recomment.image')} ${translate('entity.recomment.dimension')}: 103*64`}</div>
        {limitSize && <div className='mb-3' style={{ color: "red" }}>{translate("entity.recomment.image")}</div>}
        {!role.includes(AUTHORITIES.MONITOR)
          ? <>
            {logoHotel ? <p>{translate('hotelTvApp.logoHotel.instruction2')}</p> : null}
            <div className="d-flex gap-3">
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" onClick={() => submitChangeLogoHotel(file)}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </div>
          </>
          : null
        }
      </div>
    </>
  );
};

export default LogoHotel;
