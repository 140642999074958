import React, { useEffect, useState } from "react";
import { TextFormat, Translate, translate } from "react-jhipster";
import { Badge, Col, Row } from "reactstrap";
import { Button, DatePicker, GetProp, Input, Select, Table, TablePaginationConfig, TableProps, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "app/config/store";
import { TYPE_SERVICE_PACK, getEntities } from "app/entities/channel-pack-master/channel-pack-master-extend.reducer";
import { useDebounce } from "app/utils/customHooks/useDebounce";
import { RangePickerProps } from "antd/es/date-picker";
import { Dayjs } from "dayjs";
import { ITEMS_PER_PAGE_MIN } from "app/shared/util/pagination.constants";
import { APP_DATE_FORMAT } from "app/config/constants";
import { filterStatisticalDeviceAsync } from "./statistical.reducer";
import { exportExcelPackSChanelUsageHistoryAsync } from "./export-excel.reducer";
import { Status } from "app/shared/model/enumerations/status.model";

export type TGenner = {
    total: number;
    activate: number;
    deactivate: number;
}

export type TDevicePack = {
    totalDevicePack: number;
    totalDeviceNotPack: number;
    totalActivateDevicePack: number;
}

type TypeHotelDashboard = {
    statisHotelChannelPackMaster: TGenner & { packAssign: number };
    statisHotelDevice: TGenner;
    statisHotelDevicePack: TDevicePack,
    statisHotelUser: TGenner;
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const { RangePicker } = DatePicker;

const HotelDashboard = (props: TypeHotelDashboard) => {
    const {
        statisHotelChannelPackMaster,
        statisHotelDevice,
        statisHotelDevicePack,
        statisHotelUser
    } = props;
    const dispatch = useAppDispatch();
    const channelPackMasters = useAppSelector(state => state.channelPackMasterExtend.entities);
    const [deviceList, setDeviceList] = useState([]);
    const [textSearchChannelPackMaster, setTextSearchChannelPackMaster] = useState('');
    const debouncedChannelPackMaster = useDebounce<string>(textSearchChannelPackMaster, 500);
    const [textSearch, setTextSearch] = useState("");
    const debouncedValue = useDebounce<string>(textSearch, 500);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [idPack, setIdPack] = useState("");
    const [statusDevice, setStatusDevice] = useState<boolean | null>(null);
    const [deviceDeleted, setDeviceDeleted] = useState<boolean | null>(null);
    const [totalData, setTotalData] = useState(0);
    const [totalDeviceActiveHasService, setTotalDeviceActiveHasService] = useState(0);
    const [tableParamDevice, setTableParamDevice] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: ITEMS_PER_PAGE_MIN
        }
    });
    const columns: TableProps['columns'] = [
        {
            title: <Translate contentKey={"hotelTvApp.apiKey.stt"}>STT</Translate>,
            dataIndex: "stt",
            key: "stt",
            render: (_, record, index) => (tableParamDevice.pagination.current - 1) * tableParamDevice.pagination.pageSize + index + 1
        },
        {
            title: <Translate contentKey={"hotelTvApp.apiKey.room"}>Room</Translate>,
            dataIndex: "nameRoom",
            key: "nameRoom"
        },
        {
            title: <Translate contentKey={"hotelTvApp.apiKey.deviceId"}>Device ID</Translate>,
            dataIndex: "uniqueKey",
            key: "uniqueKey",
        },
        {
            title: <Translate contentKey={"hotelTvApp.apiKey.isActive"}>Is Active</Translate>,
            dataIndex: "isActive",
            key: "isActive",
            render: (_, record) => (
                record.isActive
                    ? <Badge color="primary">{translate("global.form.active")}</Badge>
                    : <Badge color="danger">{translate("global.form.deactive")}</Badge>
            )
        },
        {
            title: <Translate contentKey={"hotelTvApp.apiKey.status"}>Status Channel</Translate>,
            dataIndex: "status",
            key: "status",
            render: (_, record) => (
                record.status === Status.ACTIVATE
                    ? <Badge color="primary">{translate("global.form.active")}</Badge>
                    : <Badge color="danger">{translate("global.form.deactive")}</Badge>
            )
        },
        {
            title: <Translate contentKey={"hotelTvApp.apiKey.channelPackMaster"}>Channel PackMaster</Translate>,
            dataIndex: "nameChannelPackMaster",
            key: "nameChannelPackMaster",
        },
        {
            title: <Translate contentKey={"hotelTvApp.apiKey.activationDate"}>Activation Date</Translate>,
            dataIndex: "activationDate",
            key: "activationDate",
            render: (value, record, index) => value && <TextFormat type="date" value={value} format={APP_DATE_FORMAT} />
        },
        {
            title: <Translate contentKey={"hotelTvApp.apiKey.expirationDate"}>Expiration Date</Translate>,
            dataIndex: "expiryDate",
            key: "expiryDate",
            render: (value, record, index) => value && <TextFormat type="date" value={value} format={APP_DATE_FORMAT} />
        },
        {
            title: <Translate contentKey={"hotelTvApp.apiKey.updatedAt"}>Updated At</Translate>,
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (value, record, index) => value && <TextFormat type="date" value={value} format={APP_DATE_FORMAT} />
        },
        {
            title: <Translate contentKey={"hotelTvApp.deviceChannelPackMaster.isDeleted"}>Is Deleted</Translate>,
            dataIndex: "isDeleted",
            key: "isDeleted",
            render: (_, record) => (
                !record.isDeleted
                    ? <Badge color="primary">NOT DELETED</Badge>
                    : <Badge color="danger">DELETED</Badge>
            )
        },
        {
            title: <Translate contentKey={"hotelTvApp.apiKey.updatedBy"}>Updated By</Translate>,
            dataIndex: "author",
            key: "author",
        },
      {
          title: <Translate contentKey={"hotelTvApp.apiKey.deactivatedDate"}>Deactivated Date</Translate>,
          dataIndex: "deactivatedDate",
          key: "deactivatedDate",
          render: (value, record, index) => (value !== null ? value && <TextFormat type="date" value={value} format={APP_DATE_FORMAT} /> : null)
      },
    ];

    const data = [
        {
            title: translate("home.statistical.hotel.channelPackMaster.title"),
            total: statisHotelChannelPackMaster?.total,
            activeTitle: translate("home.statistical.hotel.channelPackMaster.active"),
            activeValue: statisHotelChannelPackMaster?.activate,
            deactiveTitle: translate("home.statistical.hotel.channelPackMaster.deactive"),
            deactiveValue: statisHotelChannelPackMaster?.deactivate,
            packAssignTitle: translate("home.statistical.hotel.channelPackMaster.packAssign"),
            packAssignValue: statisHotelChannelPackMaster?.packAssign
        },
        {
            title: translate("home.statistical.hotel.device.title"),
            total: statisHotelDevice?.total,
            activeTitle: translate("home.statistical.hotel.device.active"),
            activeValue: statisHotelDevice?.activate,
            deactiveTitle: translate("home.statistical.hotel.device.deactive"),
            deactiveValue: statisHotelDevice?.deactivate,
        },
        {
            title: translate("home.statistical.hotel.devicePack.title"),
            total: statisHotelDevicePack?.totalDevicePack,
            activeTitle: translate("home.statistical.hotel.devicePack.active"),
            activeValue: statisHotelDevicePack?.totalActivateDevicePack,
            deactiveTitle: translate("home.statistical.hotel.devicePack.deactive"),
            deactiveValue: statisHotelDevicePack?.totalDeviceNotPack,
        },
        {
            title: translate("home.statistical.hotel.user.title"),
            total: statisHotelUser?.total,
            activeTitle: translate("home.statistical.hotel.user.active"),
            activeValue: statisHotelUser?.activate,
            deactiveTitle: translate("home.statistical.hotel.user.deactive"),
            deactiveValue: statisHotelUser?.deactivate,
        }
    ];

    const getListChannelPackMaster = () => {
        dispatch(
            getEntities({
                type: TYPE_SERVICE_PACK.ALL,
                nameSearch: textSearchChannelPackMaster
            })
        )
    };

    const getListDevice = () => {
        dispatch(
            filterStatisticalDeviceAsync({
                startTime: startTime,
                endTime: endTime,
                nameSearch: textSearch,
                page: tableParamDevice.pagination.current - 1,
                size: tableParamDevice.pagination.pageSize,
                idPack: idPack,
                statusDevice: statusDevice,
                deviceDeleted: deviceDeleted,
            })
        ).then((res) => {
            setDeviceList(res.payload?.["data"]["historyDeviceChannelPackMaster"]);
            setTotalData(res.payload?.["data"]["totalItem"]);
            setTotalDeviceActiveHasService(res.payload?.["data"]["countDeviceActiveHasChannelPack"]);
        })
    }

    useEffect(() => {
        getListChannelPackMaster();
    }, [debouncedChannelPackMaster]);

    useEffect(() => {
        setTableParamDevice({
            ...tableParamDevice,
            pagination: {
                ...tableParamDevice.pagination,
                total: totalData
            }
        })
    }, [totalData]);

    const onSearchChannelPackMaster = (value: string) => {
        setTextSearchChannelPackMaster(value);
        setTableParamDevice({
            ...tableParamDevice,
            pagination: {
                ...tableParamDevice.pagination,
                current: 1,
                pageSize: ITEMS_PER_PAGE_MIN
            }
        })
    };

    const onFilterOptionChannelPackMaster = (input, options) => {
        return channelPackMasters.length > 0 && channelPackMasters.map((item) => (
            { key: item.id, value: item.id, label: item.name }
        ))
    };

    const onChangeRangePicker: RangePickerProps['onChange'] = (dates: [Dayjs, Dayjs], dateStrings: [string, string]) => {
        setStartTime(dates?.[0].toISOString() ?? "");
        setEndTime(dates?.[1].toISOString() ?? "");
        setTableParamDevice({
            ...tableParamDevice,
            pagination: {
                ...tableParamDevice.pagination,
                current: 1,
                pageSize: ITEMS_PER_PAGE_MIN
            }
        })
    };

    const onChangeFilterStatusDevice = (value) => {
        setStatusDevice(value)
        setTableParamDevice({
            ...tableParamDevice,
            pagination: {
                ...tableParamDevice.pagination,
                current: 1,
                pageSize: ITEMS_PER_PAGE_MIN
            }
        })
    };

    const onChangeFilterDeviceDeleted = (value) => {
        setDeviceDeleted(value)
        setTableParamDevice({
            ...tableParamDevice,
            pagination: {
                ...tableParamDevice.pagination,
                current: 1,
                pageSize: ITEMS_PER_PAGE_MIN
            }
        })
    };

    const onChangeFilterServicePack = (value) => {
        setIdPack(value);
        setTableParamDevice({
            ...tableParamDevice,
            pagination: {
                ...tableParamDevice.pagination,
                current: 1,
                pageSize: ITEMS_PER_PAGE_MIN
            }
        })
    };

    const onChangeFilterNameSearch = (value: string) => {
        setTextSearch(value);
        setTableParamDevice({
            ...tableParamDevice,
            pagination: {
                ...tableParamDevice.pagination,
                current: 1,
                pageSize: ITEMS_PER_PAGE_MIN
            }
        })
    };

    const handleTableDevice: TableProps['onChange'] = (pagination, filters, sorter) => {
        setTableParamDevice({
            pagination,
            filters,
            ...sorter
        });
    };

    const handleExport = () => {
        dispatch(exportExcelPackSChanelUsageHistoryAsync({
            startTime: startTime,
            endTime: endTime,
            idPack: idPack,
            statusDevice: statusDevice,
            nameSearch: textSearch,
            isDeleted: deviceDeleted
        })).then(response => {
            if (response.payload) {
                const url = window.URL.createObjectURL(new Blob([response.payload]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'service-usage-history.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            } else {
                console.error("No data received from the export API.");
            }
        }).catch(error => {
            console.error("Failed to export Excel file:", error);
        });
    };


    useEffect(() => {
        getListDevice();
    }, [
        startTime,
        endTime,
        tableParamDevice.pagination.current,
        tableParamDevice.pagination.pageSize,
        idPack,
        statusDevice,
        deviceDeleted,
        debouncedValue
    ]);

    return (
        <>
            <div>
                {data.map((item, index) => (
                    <Row key={index} md="12">
                        <Col md="3">
                            <div className="title-statistical">{item.title}: {item.total}</div>
                        </Col>
                        <Col md="3"><div>{item.activeTitle}: {item.activeValue}</div></Col>
                        <Col md="3"><div>{item.deactiveTitle}: {item.deactiveValue}</div></Col>
                        {item.packAssignTitle && <Col md="3"><div>{item.packAssignTitle}: {item.packAssignValue}</div></Col>}
                    </Row>
                ))}
            </div>
            <div className="my-5">
                <h4>{translate("home.filter.title")}:</h4>
                <Row md="12">
                    <Col md="3">
                        <RangePicker
                            style={{ height: "38px", width: "100%" }}
                            format={'DD/MM/YYYY'}
                            placeholder={[translate("home.filter.startTime"), translate("home.filter.endTime")]}
                            onChange={onChangeRangePicker}
                        />
                    </Col>
                    <Col md="3">
                        <Input
                            style={{ height: "38px" }}
                            placeholder={translate("home.filter.search")}
                            prefix={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                            onChange={(e) => onChangeFilterNameSearch(e.target.value)}
                            allowClear
                        />
                    </Col>
                    <Col md="2">
                        <Select
                            style={{ height: "38px", width: "100%" }}
                            placeholder={translate("home.filter.servicePack")}
                            allowClear
                            showSearch
                            onSearch={onSearchChannelPackMaster}
                            filterOption={onFilterOptionChannelPackMaster}
                            options={channelPackMasters.length > 0 && channelPackMasters.map((item) => (
                                { key: item.id, value: item.id, label: item.name }
                            ))}
                            onChange={(value) => onChangeFilterServicePack(value)}
                        />
                    </Col>
                    <Col md="2">
                        <Select
                            style={{ height: "38px", width: "100%" }}
                            placeholder={translate("home.filter.status")}
                            options={[
                                { value: true, label: translate('global.form.active') },
                                { value: false, label: translate('global.form.deactive') }
                            ]}
                            allowClear
                            onChange={(value) => onChangeFilterStatusDevice(value ?? null)}
                        />
                    </Col>
                    <Col md="2">
                        <Select
                            style={{ height: "38px", width: "100%" }}
                            placeholder={translate("hotelTvApp.deviceChannelPackMaster.isDeleted")}
                            options={[
                                { value: true, label: 'DELETED' },
                                { value: false, label: 'NOT DELETED' }
                            ]}
                            allowClear
                            onChange={(value) => onChangeFilterDeviceDeleted(value ?? null)}
                        />
                    </Col>
                    {/* {<Col md="2">
                        <Button type="primary" style={{ height: "38px" }} onClick={handleExport}>
                            <FontAwesomeIcon icon={faFileExport} />
                            &nbsp;
                            <Translate contentKey="home.filter.export">Export Excel</Translate>
                        </Button>
                    </Col>} */}
                </Row>
                <Button className="mt-4" type="primary" style={{ height: "38px" }} onClick={handleExport}>
                    <FontAwesomeIcon icon={faFileExport} />
                    &nbsp;
                    <Translate contentKey="home.filter.export">Export Excel</Translate>
                </Button>
                <h6 className="mt-4">
                    {translate("home.filter.deviceActiveHasServicePack")}:
                    <Badge color="primary" className="ms-3">{totalDeviceActiveHasService}</Badge>
                </h6>
            </div>
            <div>
                <Table
                    columns={columns}
                    dataSource={deviceList}
                    rowKey={record => record.id}
                    pagination={{
                        ...tableParamDevice.pagination,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 20, 30, 50]
                    }}
                    onChange={handleTableDevice}
                />
            </div>
        </>
    );
}

export default HotelDashboard;
