import dayjs from 'dayjs';
import { IServiceHotel } from 'app/shared/model/service-hotel.model';
import { ISeriesMovie } from 'app/shared/model/series-movie.model';
import { ISeriesMovieShip } from 'app/shared/model/series-movie-ship.model';
import { IHotel } from 'app/shared/model/hotel.model';
import { Language } from 'app/shared/model/enumerations/language.model';
import { Inform } from 'app/shared/model/enumerations/inform.model';

export interface IMenuService {
  id?: string;
  name?: string | null;
  language?: Language | null;
  type?: Inform | null;
  description?: string | null;
  author?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isDeleted?: boolean | null;
  serviceHotels?: IServiceHotel[] | null;
  seriesMovies?: ISeriesMovie[] | null;
  seriesMovieShips?: ISeriesMovieShip[] | null;
  hotel?: IHotel | null;
}

export const defaultValue: Readonly<IMenuService> = {
  isDeleted: false,
};
