import dayjs from 'dayjs';
import { IApiKey } from 'app/shared/model/api-key.model';
import { IHotel } from 'app/shared/model/hotel.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IRoom {
  id?: string;
  name?: string | null;
  status?: Status | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isDeleted?: boolean | null;
  apiKeys?: IApiKey[] | null;
  hotel?: IHotel | null;
}

export const defaultValue: Readonly<IRoom> = {
  isDeleted: false,
};
