import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Badge } from 'reactstrap';
import { Translate, TextFormat, Storage, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './channel-pack-master.reducer';
import { EPath } from 'app/utils/constants/EPath';
import { Status } from 'app/shared/model/enumerations/status.model';

export const ChannelPackMasterDetailExtend = () => {
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const { idChannelPackMaster } = useParams<'idChannelPackMaster'>();
  const channelPackMasterEntity = useAppSelector(state => state.channelPackMaster.entity);

  useEffect(() => {
    role.includes(AUTHORITIES.ADMIN) ? dispatch(getEntity(idChannelPackMaster)) : dispatch(getEntity(id));
  }, []);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="channelPackMasterDetailsHeading">
          <Translate contentKey="hotelTvApp.channelPackMaster.home.title">ChannelPackMaster</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">
              <Translate contentKey="hotelTvApp.channelPackMaster.name">Name</Translate>
            </span>
          </dt>
          <dd>{channelPackMasterEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="hotelTvApp.channelPackMaster.description">Description</Translate>
            </span>
          </dt>
          <dd>{channelPackMasterEntity.description}</dd>
          <dt>
            <span id="packageDuration">
              <Translate contentKey="hotelTvApp.channelPackMaster.packageDuration">Package Duration</Translate>
            </span>
          </dt>
          <dd>{channelPackMasterEntity.packageDuration}</dd>
          <dt>
            <span id="liveTVNumber">
              <Translate contentKey="hotelTvApp.channelPackMaster.liveTVNumber">Live TV Number</Translate>
            </span>
          </dt>
          <dd>{channelPackMasterEntity.liveTVNumber}</dd>
          <dt>
            <span id="movieNumber">
              <Translate contentKey="hotelTvApp.channelPackMaster.movieNumber">Movie Number</Translate>
            </span>
          </dt>
          <dd>{channelPackMasterEntity.movieNumber}</dd>
          {/* <dt>
            <span id="effectiveDate">
              <Translate contentKey="hotelTvApp.channelPackMaster.effectiveDate">Effective Date</Translate>
            </span>
          </dt>
          <dd>
            {channelPackMasterEntity.effectiveDate ? (
              <TextFormat value={channelPackMasterEntity.effectiveDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="expiryDate">
              <Translate contentKey="hotelTvApp.channelPackMaster.expiryDate">Expiry Date</Translate>
            </span>
          </dt>
          <dd>
            {channelPackMasterEntity.expiryDate ? (
              <TextFormat value={channelPackMasterEntity.expiryDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd> */}
          <dt>
            <span id="author">
              <Translate contentKey="hotelTvApp.channelPackMaster.author">Author</Translate>
            </span>
          </dt>
          <dd>{channelPackMasterEntity.author}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="hotelTvApp.channelPackMaster.status">Status</Translate>
            </span>
          </dt>
          <dd>{channelPackMasterEntity.status
            ? channelPackMasterEntity.status === Status.ACTIVATE
              ? <Badge color="primary">{translate("global.form.active")}</Badge>
              : <Badge color="danger">{translate("global.form.deactive")}</Badge>
            : null
          }</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="hotelTvApp.channelPackMaster.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {channelPackMasterEntity.createdAt ? (
              <TextFormat value={channelPackMasterEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="hotelTvApp.channelPackMaster.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {channelPackMasterEntity.updatedAt ? (
              <TextFormat value={channelPackMasterEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
        </dl>
        <Button
          id="cancel-save"
          data-cy="entityCreateCancelButton"
          onClick={() => history.back()}
          color="info"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          hidden={role.includes(AUTHORITIES.MONITOR)}
          tag={Link}
          to={role.includes(AUTHORITIES.ADMIN)
            ? `${EPath.ChannelPackMasterADMIN}/${id}/${channelPackMasterEntity.id}/edit`
            : `${EPath.ChannelPackMaster}/${channelPackMasterEntity.id}/edit`
          }
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ChannelPackMasterDetailExtend;
