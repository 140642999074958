import React, { useState } from "react";
import "./style.scss";
import { Button, Modal, ModalBody } from "reactstrap";
import { ValidatedField, ValidatedForm, translate } from "react-jhipster";
import { useAppDispatch, useAppSelector } from "app/config/store";
import { createEntityExtend } from "app/entities/room/room-extend.reducer";
import { deleteEntity, getEntities } from "app/entities/device/device.reducer";

type TModalTerminalDevice = {
    deviceId?: string | null;
    idHotel?: string | null;
    isOpen: boolean;
    toggle: () => void;
}

const ModalTerminalDevice = (props: TModalTerminalDevice) => {
    const dispatch = useAppDispatch();
    const paramsEndpointDevice = useAppSelector(state => state.confirmDevice.data);
    const { deviceId, idHotel, isOpen, toggle } = props;
    const [confirm, setConfirm] = useState(true);

    const openRegisDevice = () => setConfirm(!confirm);

    const onSubmit = (value: { room: string }) => {
        openRegisDevice();
        const params = {
            name: value.room,
            deviceId: paramsEndpointDevice.deviceId,
            pinCode: paramsEndpointDevice.pinCode
        };
        dispatch(createEntityExtend(params));
        dispatch(getEntities({}));
        toggle();
    }

    const onDeclined = () => {
        dispatch(deleteEntity(paramsEndpointDevice.deviceId));
        toggle();
    }

    return (
        <Modal isOpen={isOpen} centered className="modal-terminal-device">
            <ModalBody>
                <div className="modal-body-terminal-device">
                    {confirm
                        ? (
                            <div className="confirm-terminal-service">
                                <div className="d-flex flex-column mt-50">
                                    <span className="text-center">
                                        {translate("hotelTvApp.apiKey.endpointDevice.deviceId")}:
                                        <span className="text-success"> {deviceId} </span>
                                        {translate("hotelTvApp.apiKey.endpointDevice.hotel")} <span className="text-success"> {idHotel} </span>
                                        {translate("hotelTvApp.apiKey.endpointDevice.requestConnect")}.
                                    </span>
                                    <span className="text-center mt-2">
                                        {translate("hotelTvApp.apiKey.endpointDevice.question")}?
                                    </span>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <Button onClick={onDeclined}>
                                        {translate("hotelTvApp.apiKey.endpointDevice.declined")}
                                    </Button>
                                    <Button onClick={openRegisDevice} color="primary">
                                        {translate("hotelTvApp.apiKey.endpointDevice.agree")}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="register-terminal-service">
                                <div className="d-flex align-items-center">
                                    <div
                                        className="position-absolute"
                                        style={{ left: "16px", cursor: "pointer" }}
                                        onClick={openRegisDevice}
                                    >{translate("hotelTvApp.apiKey.endpointDevice.goBack")}</div>
                                    <div className="title">{translate("hotelTvApp.apiKey.endpointDevice.requireLabel")}</div>
                                </div>
                                <ValidatedForm onSubmit={onSubmit} className="form-register">
                                    <ValidatedField
                                        type="text"
                                        name="room"
                                        placeholder="Room name"
                                        validate={{
                                            required: {
                                                value: true, message: translate("hotelTvApp.apiKey.endpointDevice.requireMessage")
                                            }
                                        }}
                                    />
                                    <div className="footer-register-terminal-service">
                                        <Button onClick={onDeclined}>
                                            {translate("hotelTvApp.apiKey.endpointDevice.declined")}
                                        </Button>
                                        <Button color="primary" type="submit">
                                            {translate("hotelTvApp.apiKey.endpointDevice.agree")}
                                        </Button>
                                    </div>
                                </ValidatedForm>
                            </div>
                        )
                    }
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalTerminalDevice;