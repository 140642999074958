import React, { useState, useEffect, useRef, Ref } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm, Storage } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Upload, Radio, Input, Form, Select } from 'antd';
import type { InputRef, RadioChangeEvent } from 'antd';
import { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { IRoom } from 'app/shared/model/room.model';
import { getEntities as getRooms } from 'app/entities/room/room.reducer';
import { Status } from 'app/shared/model/enumerations/status.model';
import { Type } from 'app/shared/model/enumerations/type.model';
import { getEntity, updateEntity, createEntity, reset } from './welcome-extend.reducer';
import { Button as ButtonAntd, Flex } from 'antd';
import './style.scss';
import MultipleUploadImage from '../../components/upload/MultipleUploadImage';
import UploadSingleFile from '../../components/upload/UploadSingleFile';
import { ETypeFile, ResponseS3MultipleImg, uploadMultipleFileS3, uploadSingleFileS3 } from '../s3-upload/s3.upload';
import { formatContentWelcomeByLanguage, validateMessage } from '../../shared/util/format-utils';
import { CustomInput, CustomInputRef } from '../../components/upload/custom-input/CustomInput';
import { AUTHORITIES } from 'app/config/constants';
import { CONST_CONVERSION, variables } from 'app/utils/constants/variables';
import { FormProvider, useForm } from 'react-hook-form';
import CommonInput from 'app/components/CommonInput';
import CommonSelect from 'app/components/CommonSelect';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

interface IContentLanguage {
  contentVi?: string;
  contentEn?: string;
  contentKo?: string;
  contentJa?: string;
}

export interface IResponsePayload {
  payload: { imgUrl: string };
}

export interface IResponsePayloadMultiple {
  payload: ResponseS3MultipleImg[];
}

export interface IMedia {
  image?: UploadFile;
  audio?: UploadFile;
  video?: UploadFile;
  all?: UploadFile;
}

export interface IMediaRequest {
  imageBackground?: string;
  audioUrl?: string;
  videoUrl?: string;
}

export interface IUploadFileExtend extends UploadFile<any>, ResponseS3MultipleImg { }

export const WelcomeExtendUpdate = () => {
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const { idWelcome } = useParams<'idWelcome'>();
  const isNew = role.includes(AUTHORITIES.ADMIN) ? idWelcome === undefined : id === undefined;

  const welcomeEntity = useAppSelector(state => state.welcome.entity);
  const loading = useAppSelector(state => state.welcome.loading);
  const updating = useAppSelector(state => state.welcome.updating);
  const updateSuccess = useAppSelector(state => state.welcome.updateSuccess);
  const statusValues = Object.keys(Status);
  const typeValues = Object.keys(Type);
  const [focusedInput, setFocusedInput] = useState<string | null>(null);
  const inputRefs = {
    contentVi: useRef<HTMLInputElement>(null),
    contentEn: useRef<HTMLInputElement>(null),
    contentKo: useRef<HTMLInputElement>(null),
    contentJa: useRef<HTMLInputElement>(null)
  };

  const [fileList, setFileList] = useState<UploadFile[] | ResponseS3MultipleImg[]>([]);
  const [fileList2, setFileList2] = useState<UploadFile[] | ResponseS3MultipleImg[]>([]);
  const [valueSelection, setValueSelection] = useState<string>();
  const [media, setMedia] = useState<IMedia>();
  const [option1, setOption1] = useState<number>(1);
  const [content, setContent] = useState<IContentLanguage>({} as IContentLanguage);
  const [images, setImages] = useState<IUploadFileExtend[]>([]);
  const [images2, setImages2] = useState<IUploadFileExtend[]>([]);
  const [errorMedia, setErrorMedia] = useState<{ image: Boolean; audio: Boolean; video: Boolean }>({ image: false, audio: false, video: false });
  const [imagesError, setImagesError] = useState([]);
  const [imagesError2, setImagesError2] = useState([]);
  const [urlImageBackground, setUrlImageBackground] = useState('');
  const [urlAudio, setUrlAudio] = useState('');
  const [urlVideo, setUrlVideo] = useState('');
  const methods = useForm({
    mode: 'onSubmit', criteriaMode: 'firstError', reValidateMode: 'onChange'
  });

  const onChange1 = (e: RadioChangeEvent) => {
    setOption1(e.target.value);
  };

  const addNameAtCursorPosition = (id: string, text: string) => {
    const currentInput = focusedInput ? (document.getElementById(id) as HTMLInputElement) : null;
    if (currentInput && focusedInput && content[focusedInput]) {
      const start = currentInput.selectionStart;
      const end = currentInput.selectionEnd;
      const textBefore = content[focusedInput as string].slice(0, start) ?? '';
      const textAfter = content[focusedInput as string].slice(end) ?? '';
      const textToInsert = text;

      setContent({
        ...content,
        [focusedInput as string]: textBefore + textToInsert + textAfter
      });

      // Update cursor position
      const newCursorPosition = (start as number) + textToInsert.length;
      setTimeout(() => {
        currentInput.selectionStart = currentInput.selectionEnd = newCursorPosition;
      }, 0);
    }
    if (currentInput && focusedInput && !content[focusedInput]) {
      setContent({
        ...content,
        [focusedInput as string]: text
      });
    }
  };

  const handleClose = () => {
    history.back();
  };

  const getContentByOption = (option: number) => {
    switch (option) {
      case 1:
        return { code: 'vi', content: content?.contentVi ?? '' };
      case 2:
        return { code: 'en', content: content?.contentEn ?? '' };
      case 3:
        return { code: 'kr', content: content?.contentKo ?? '' };
      case 4:
        return { code: 'ja', content: content?.contentKo ?? '' };
      default:
        return {};
    }
  };

  const handleSelection = (value: string, option: number) => {
    const json = JSON.parse(value);
    const contentInfo = getContentByOption(option);
    const getContent = formatContentWelcomeByLanguage(contentInfo.content as string, json?.gender, json?.name, contentInfo.code as string);
  };

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      role.includes(AUTHORITIES.ADMIN) ? dispatch(getEntity(idWelcome)) : dispatch(getEntity(id));
    }
    dispatch(getRooms({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (media) {
      if (option1 === 1) {
        setErrorMedia({
          image: (media.image?.size / CONST_CONVERSION.VALUE) > variables.IMAGE_SIZE,
          audio: (media.audio?.size / CONST_CONVERSION.VALUE) > variables.AUDIO_SIZE,
          video: false
        })
      } else if (option1 === 2) {
        setErrorMedia({
          image: false,
          audio: false,
          video: (media.video?.size / CONST_CONVERSION.VALUE) > variables.VIDEO_SIZE
        })
      } else {
        setErrorMedia({
          image: false,
          audio: (media.audio?.size / CONST_CONVERSION.VALUE) > variables.IMAGE_SIZE,
          video: false
        })
      }
    }
  }, [media]);

  useEffect(() => {
    const indexs = images?.filter(item => (item?.size / CONST_CONVERSION.VALUE) > variables.IMAGE_SIZE)
      .map((item, index) => index + 1);
    setImagesError(indexs);
  }, [images]);

  useEffect(() => {
    const indexs = images2?.filter(item => (item?.size / CONST_CONVERSION.VALUE) > variables.IMAGE_SIZE)
      .map((item, index) => index + 1);
    setImagesError2(indexs);
  }, [images2]);

  const getFileUrl = async () => {
    const mediaObj = {} as IMediaRequest;
    if (media?.image) {
      const resp1 = (await dispatch(
        uploadSingleFileS3({ file: media.image.originFileObj as File, type: ETypeFile.image })
      )) as IResponsePayload;
      if (resp1?.payload?.imgUrl) {
        mediaObj.imageBackground = resp1?.payload?.imgUrl;
      }
    }
    if (media?.audio) {
      const resp2 = (await dispatch(
        uploadSingleFileS3({ file: media.audio.originFileObj as File, type: ETypeFile.video })
      )) as IResponsePayload;
      if (resp2?.payload?.imgUrl) {
        mediaObj.audioUrl = resp2?.payload?.imgUrl;
      }
    }
    if (media?.video) {
      const resp3 = (await dispatch(
        uploadSingleFileS3({ file: media.video.originFileObj as File, type: ETypeFile.video })
      )) as IResponsePayload;
      if (resp3?.payload?.imgUrl) {
        mediaObj.videoUrl = resp3?.payload?.imgUrl;
      }
    }

    return mediaObj;
  };

  const getSlideShow = async () => {
    let mediaArr: ResponseS3MultipleImg[] = [];
    if (fileList.length > 0) {
      const resp3 = (await dispatch(uploadMultipleFileS3(fileList as UploadFile<any>[]))) as IResponsePayloadMultiple;
      if (resp3?.payload?.length > 0) {
        mediaArr = resp3?.payload;
      }
    }
    return mediaArr;
  };

  const getSlideSubSlide = async () => {
    let mediaArr: ResponseS3MultipleImg[] = [];
    if (fileList2.length > 0) {
      const resp3 = (await dispatch(uploadMultipleFileS3(fileList2 as UploadFile<any>[]))) as IResponsePayloadMultiple;
      if (resp3?.payload?.length > 0) {
        mediaArr = resp3?.payload;
      }
    }
    return mediaArr;
  };

  const updateSlideShow = async (currentSlide: ResponseS3MultipleImg[]) => {
    const newImages = await getSlideShow();

    return [...currentSlide, ...newImages];
  };

  const updateSlideSubSlide = async (currentSlide: ResponseS3MultipleImg[]) => {
    const newImages = await getSlideSubSlide();

    return [...currentSlide, ...newImages];
  };

  const updateMedia = async () => {
    const oldContent = {
      imageBackground: urlImageBackground,
      videoUrl: urlVideo,
      audioUrl: urlAudio,
    };
    const newMedia = await getFileUrl();
    return { ...oldContent, ...newMedia };
  };

  const updateContent = () => {
    const oldContent = {
      contentVi: welcomeEntity?.contentVi,
      contentEn: welcomeEntity?.contentEn,
      contentKo: welcomeEntity?.contentKo,
      contentJa: welcomeEntity?.contentJa
    };
    return { ...oldContent, ...content };
  };

  const handleChange = (inputKey: keyof typeof content, event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(prev => ({
      ...prev,
      [inputKey]: event.target.value
    }));
  };

  // eslint-disable-next-line complexity
  const saveEntity = async values => {
    dispatch(spinLoading());
    const checkError = Object.values(errorMedia).includes(true);
    if (!(imagesError.length > 0) && !(imagesError2.length > 0) && !checkError) {
      values.checkInAt = convertDateTimeToServer(values.checkInAt);
      values.checkOutAt = convertDateTimeToServer(values.checkOutAt);
      values.updatedAt = convertDateTimeToServer(values.updatedAt);
      values.createdAt = convertDateTimeToServer(values.createdAt);
      if (values.maxImageSlide !== undefined && typeof values.maxImageSlide !== 'number') {
        values.maxImageSlide = Number(values.maxImageSlide);
      }

      const { videoUrl, imageBackground, audioUrl } = isNew ? await getFileUrl() : await updateMedia();
      const slideShows = isNew ? await getSlideShow() : await updateSlideShow(images);
      const slideShowSmalls = isNew ? await getSlideSubSlide() : await updateSlideSubSlide(images2);
      const contentRequest = isNew ? content : updateContent();
      const entity = {
        ...welcomeEntity,
        ...values,
        ...contentRequest,
        status: values.status,
        videoUrl,
        imageBackground,
        audioUrl,
        slideShows,
        slideShowSmalls,
        ...(role.includes(AUTHORITIES.ADMIN) ? { hotelId: id } : {})
      };

      if (isNew) {
        await dispatch(createEntity(entity));
      } else {
        await dispatch(updateEntity(entity));
      }
    }
    dispatch(spinUnLoading());
  };

  useEffect(() => {
    isNew
      ? methods.reset({
        checkInAt: displayDefaultDateTime(),
        checkOutAt: displayDefaultDateTime(),
        updatedAt: displayDefaultDateTime(),
        createdAt: displayDefaultDateTime(),
        status: Status.DEACTIVATE
      })
      : methods.reset({
        ...welcomeEntity,
        checkInAt: convertDateTimeFromServer(welcomeEntity.checkInAt),
        checkOutAt: convertDateTimeFromServer(welcomeEntity.checkOutAt),
        updatedAt: convertDateTimeFromServer(welcomeEntity.updatedAt),
        createdAt: convertDateTimeFromServer(welcomeEntity.createdAt),
        room: welcomeEntity?.room?.id
      });

    if (!isNew && !loading) {
      if (welcomeEntity?.slideShows?.length > 0) {
        setImages(welcomeEntity.slideShows);
      }
      if (welcomeEntity?.slideShowSmalls?.length > 0) {
        setImages2(welcomeEntity.slideShowSmalls);
      }
      setContent({
        contentVi: welcomeEntity?.contentVi,
        contentEn: welcomeEntity?.contentEn,
        contentKo: welcomeEntity?.contentKo,
        contentJa: welcomeEntity?.contentJa
      });
      setUrlImageBackground(welcomeEntity?.imageBackground);
      setUrlAudio(welcomeEntity?.audioUrl);
      setUrlVideo(welcomeEntity?.videoUrl);
    }
  }, [welcomeEntity]);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hotelTvApp.welcome.home.createOrEditLabel" data-cy="WelcomeCreateUpdateHeading">
            {isNew ? (
              <Translate contentKey="hotelTvApp.welcome.home.createLabel">Create a new Channel</Translate>
            ) : (
              <Translate contentKey="hotelTvApp.welcome.home.editLabel">Edit a Channel</Translate>
            )}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(saveEntity)}>
                <CommonInput
                  name='title'
                  type='text'
                  label={
                    <p style={{ margin: 0 }}>
                      {`${translate('hotelTvApp.welcome.title')}`}
                      {<span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  placeholder={translate('hotelTvApp.welcome.title')}
                  rules={{
                    required: {
                      value: true,
                      message: translate("entity.validation.required")
                    }
                  }}
                />
                <CommonSelect
                  name='type'
                  options={typeValues.map((type) => (
                    { key: type, value: type, label: translate('hotelTvApp.Type.' + type) }
                  ))}
                  label={
                    <p style={{ margin: 0 }}>
                      {'Display Type'}
                      {<span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  placeholder={'Display Type'}
                  rules={{
                    required: {
                      value: true,
                      message: translate("entity.validation.required")
                    }
                  }}
                />
                <label className="mb-3">Set Up Media</label>
                <Radio.Group className="radio-selection-welcome mb-3" onChange={onChange1} value={option1}>
                  <Radio value={1}>Upload Image & Audio</Radio>
                  <Radio value={2}>Upload Video</Radio>
                  <Radio value={3}>Upload Slide & Audio</Radio>
                </Radio.Group>
                {option1 === 3 && (
                  <>
                    <div className="text-center mb-2">
                      {`${translate('entity.recomment.image')} ${translate('entity.recomment.dimension')}: 746*420`}
                    </div>
                    <MultipleUploadImage
                      fileList={fileList}
                      setFileList={setFileList}
                      slideShows={welcomeEntity.slideShows}
                      isNew={isNew}
                      images={images}
                      setImages={setImages}
                      maxFile={20}
                    />
                    {imagesError.length > 0 &&
                      <p style={{ color: "red", textAlign: "center" }}>
                        {translate('entity.recomment.image')} Error image: {imagesError.map((i) => (i + ", "))}
                      </p>
                    }
                  </>
                )}
                <div className={`text-center mb-2 ${option1 === 1 ? 'd-block' : 'd-none'}`}>
                  {`${translate('entity.recomment.image')} ${translate('entity.recomment.dimension')}: 746*420`}
                </div>
                <UploadSingleFile
                  accept="image/*"
                  url={urlImageBackground}
                  style={{ display: option1 === 1 ? 'block' : 'none' }}
                  isVideo={false}
                  isAudio={false}
                  media={media as IMedia}
                  isSingleImage={true}
                  setAudioOrVideoOrImage={setMedia}
                  isNew={isNew}
                  setUrl={setUrlImageBackground}
                />
                {errorMedia.image && <p style={{ color: "red", textAlign: "center" }}>{translate('entity.recomment.image')}</p>}
                <div className={`text-center mb-2 ${option1 === 2 ? 'd-block' : 'd-none'}`}>{`${translate('entity.recomment.video')}`}</div>
                <UploadSingleFile
                  accept="video/*"
                  url={urlVideo}
                  style={{ display: option1 === 2 ? 'block' : 'none' }}
                  media={media as IMedia}
                  isVideo={true}
                  isAudio={false}
                  isSingleImage={false}
                  setAudioOrVideoOrImage={setMedia}
                  isNew={isNew}
                  setUrl={setUrlVideo}
                />
                {errorMedia.video && <p style={{ color: "red", textAlign: "center" }}>{translate('entity.recomment.video')}</p>}
                <div className={`text-center mb-2 mt-5 ${option1 === 1 || option1 === 3 ? 'd-block' : 'd-none'}`}>{`${translate('entity.recomment.audio')}`}</div>
                <UploadSingleFile
                  accept="audio/*"
                  url={urlAudio}
                  style={{ display: option1 === 1 || option1 === 3 ? 'block' : 'none' }}
                  media={media as IMedia}
                  isVideo={false}
                  isAudio={true}
                  isSingleImage={false}
                  setAudioOrVideoOrImage={setMedia}
                  isNew={isNew}
                  setUrl={setUrlAudio}
                />
                {errorMedia.audio && <p style={{ color: "red", textAlign: "center" }}>{translate('entity.recomment.audio')}</p>}
                <label className="mb-3">Upload Subslide</label>
                <div className="text-center mb-2">{`${translate('entity.recomment.image')} ${translate('entity.recomment.dimension')}: 305*420`}</div>
                <MultipleUploadImage
                  fileList={fileList2}
                  setFileList={setFileList2}
                  slideShows={welcomeEntity.slideShowSmalls}
                  isNew={isNew}
                  images={images2}
                  setImages={setImages2}
                  maxFile={20}
                />
                {imagesError2.length > 0 &&
                  <p style={{ color: "red", textAlign: "center" }}>
                    {translate('entity.recomment.image')} Error image: {imagesError2.map((i) => (i + ", "))}
                  </p>
                }
                <CommonSelect
                  name='status'
                  label={translate('hotelTvApp.welcome.status')}
                  options={statusValues.map((status) => (
                    { key: status, value: status, label: translate('hotelTvApp.Status.' + status) }
                  ))}
                />
                <label className="mb-3">Set Up Content</label>
                <div style={{ display: 'flex', gap: '20px' }} className="mb-3">
                  <div style={{ flexBasis: '70%' }}>
                    <Form.Item label={'Tiếng Việt'} hasFeedback className="mb-0 custom-form-antd" rules={[{ validator: validateMessage }]}>
                      <CustomInput
                        ref={inputRefs.contentVi}
                        value={content?.contentVi as string}
                        onChange={e => handleChange('contentVi' as never, e)}
                        style={{ height: '38px' }}
                        onFocus={() => {
                          setFocusedInput('contentVi');
                          setValueSelection('welcome-contentVi');
                        }}
                        // onBlur={() => setFocusedInput(null)}
                        id="welcome-contentVi"
                        name="contentVi"
                        placeholder='Please compose the content according to the format "Chào mừng [gender] [name] tới khách sạn của chúng tôi"'
                        data-cy="contentVi"
                        type="text"
                      />
                    </Form.Item>
                    <Form.Item label={'English'} hasFeedback className="mb-0 custom-form-antd" rules={[{ validator: validateMessage }]}>
                      <CustomInput
                        ref={inputRefs.contentEn}
                        style={{ height: '38px' }}
                        value={content?.contentEn as string}
                        onChange={e => handleChange('contentEn' as never, e)}
                        id="welcome-contentEn"
                        name="contentEn"
                        placeholder='Please compose the content according to the format "Welcome [gender] [name] to our hotel"'
                        data-cy="contentEn"
                        type="text"
                        onFocus={() => {
                          setFocusedInput('contentEn');
                          setValueSelection('welcome-contentEn');
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={'Korean'} className="mb-0 custom-form-antd" rules={[{ validator: validateMessage }]}>
                      <CustomInput
                        ref={inputRefs.contentKo}
                        style={{ height: '38px' }}
                        value={content?.contentKo as string}
                        onChange={e => handleChange('contentKo' as never, e)}
                        id="welcome-contentKo"
                        name="contentKo"
                        placeholder={
                          'Please compose the content according to the format "「[name][gender], 저희 호텔에 오신 것을 환영합니다」"'
                        }
                        type="text"
                        onFocus={() => {
                          setFocusedInput('contentKo');
                          setValueSelection('welcome-contentKo');
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={'Japanese'} className="mb-0 custom-form-antd" rules={[{ validator: validateMessage }]}>
                      <CustomInput
                        ref={inputRefs.contentJa}
                        style={{ height: '38px' }}
                        value={content?.contentJa as string}
                        onChange={e => handleChange('contentJa' as never, e)}
                        id="welcome-contentJa"
                        name="contentJa"
                        placeholder='Please compose the content according to the format "「[name][gender]、私たちのホテルへようこそ」"'
                        type="text"
                        onFocus={() => {
                          setFocusedInput('contentJa');
                          setValueSelection('welcome-contentJa');
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ flexBasis: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', justifyContent: 'center' }}>
                      <div>
                        <div>{translate('hotelTvApp.welcome.customerName')}</div>
                        <ButtonAntd
                          onClick={() => {
                            valueSelection && addNameAtCursorPosition(valueSelection, '[name]');
                          }}
                          style={{ width: '100px' }}
                          type="primary"
                        >
                          Name
                        </ButtonAntd>
                      </div>
                      <div>
                        <div>{translate('hotelTvApp.welcome.customerGender')}</div>
                        <ButtonAntd
                          onClick={() => {
                            valueSelection && addNameAtCursorPosition(valueSelection, '[gender]');
                          }}
                          style={{ width: '100px' }}
                          type="primary"
                        >
                          Gender
                        </ButtonAntd>
                      </div>
                    </div>
                  </div>
                </div>
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => history.back()} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </form>
            </FormProvider>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default WelcomeExtendUpdate;
