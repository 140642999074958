import React from 'react';
import { Route } from 'react-router-dom';
import {Storage} from 'react-jhipster';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import ServiceHotelDeleteDialogExtend from './service-hotel-delete-dialog-extend';
import ServiceHotelDetailExtend from './service-hotel-detail-extend';
import ServiceHotelExtend from './service-hotel-extend';
import ServiceHotelUpdateExtend from './service-hotel-update-extend';
import { AUTHORITIES } from 'app/config/constants';

const ServiceHotelRoutes = () => {
  const role = Storage.session.get('role');

  return (
    <ErrorBoundaryRoutes>
      {role.includes(AUTHORITIES.ADMIN)
          ? <>
            <Route path='hotel/:id' element={<ServiceHotelExtend />} />
          </>
          : <Route index element={<ServiceHotelExtend />} />
        }
        {role.includes(AUTHORITIES.ADMIN)
          ? <Route path="hotel/:id/new" element={<ServiceHotelUpdateExtend />} />
          : <Route path="new" element={<ServiceHotelUpdateExtend />} />
        }
        {role.includes(AUTHORITIES.ADMIN)
          ?<Route path="hotel/:id/:idServiceHotel">
            <Route index element={<ServiceHotelDetailExtend />} />
            <Route path="edit" element={<ServiceHotelUpdateExtend />} />
            <Route path="delete" element={<ServiceHotelDeleteDialogExtend />} />
          </Route>
          :<Route path=":id">
            <Route index element={<ServiceHotelDetailExtend />} />
            <Route path="edit" element={<ServiceHotelUpdateExtend />} />
            <Route path="delete" element={<ServiceHotelDeleteDialogExtend />} />
          </Route>
        }
    </ErrorBoundaryRoutes>
  );
}

export default ServiceHotelRoutes;
