import React from 'react';
import { Route } from 'react-router-dom';
import { Storage } from 'react-jhipster';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import Device from './device';
import DeviceUpdate from './device-update';
import DeviceDetail from './device-detail';
import DeviceDeleteDialog from './device-delete-dialog';
import { AUTHORITIES } from 'app/config/constants';
import SelectHotelPage from 'app/components/select-hotel-page/SelectHotelPage';

const DeviceRoutes = () => {
    const role = Storage.session.get("role");

    return (
        <ErrorBoundaryRoutes>
            {role.includes(AUTHORITIES.ADMIN)
                ? <>
                    <Route index element={<SelectHotelPage />} />
                    <Route path='hotel/:id' element={<Device />} />
                </>
                : <Route index element={<Device />} />
            }
            <Route path="new" element={<DeviceUpdate />} />
            {role.includes(AUTHORITIES.ADMIN)
                ? <Route path="hotel/:id/:idDevice">
                    <Route index element={<DeviceDetail />} />
                    <Route path="edit" element={<DeviceUpdate />} />
                    <Route path="delete" element={<DeviceDeleteDialog />} />
                </Route>
                : <Route path=":id">
                    <Route index element={<DeviceDetail />} />
                    <Route path="edit" element={<DeviceUpdate />} />
                    <Route path="delete" element={<DeviceDeleteDialog />} />
                </Route>
            }
        </ErrorBoundaryRoutes>
    )
}

export default DeviceRoutes;
