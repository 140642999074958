import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IHotel, defaultValue } from 'app/shared/model/hotel.model';

type TypeMultiDelete = {
  listId: string[];
}

type TypeUpdateLogoHotel = {
  logoHotel: string;
}

const initialState: EntityState<IHotel> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

const apiUrl = 'api/hotels-extend';

// Actions

export const getEntities = createAsyncThunk(
  'hotelExtend/fetch_entity_list',
  async ({ page, size, sort, nameSearch }: IQueryParams & { nameSearch?: string }) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&nameSearch=${nameSearch}&` : '?'}cacheBuster=${new Date().getTime()}`;
    return axios.get<IHotel[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'hotelExtend/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IHotel>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesRemaining = createAsyncThunk(
  'hotelExtend/fetch_entity_remaining_list',
  async ({type}: {type?: "all" | null}) => {
    const requestUrl = `${apiUrl}/remaining${type ? `?Type=${type}` : ""}`;
    return axios.get<IHotel[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'hotelExtend/create_entity',
  async (entity: IHotel, thunkAPI) => {
    const result = await axios.post<IHotel>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'hotelExtend/update_entity',
  async (entity: IHotel, thunkAPI) => {
    const result = await axios.put<IHotel>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateLogoHotel = createAsyncThunk(
  'hotelExtend/update_logo_hotel',
  async ({logoHotel, hotelId }: TypeUpdateLogoHotel & {hotelId?: string}, thunkAPI) => {
    const result = await axios.put<IHotel>(`${apiUrl}/logo?${hotelId ? `hotelId=${hotelId}` : ''}`, {logoHotel: logoHotel});
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const partialUpdateEntity = createAsyncThunk(
  'hotelExtend/partial_update_entity',
  async (entity: IHotel, thunkAPI) => {
    const result = await axios.patch<IHotel>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'hotelExtend/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IHotel>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const multiDeleteHotel = createAsyncThunk(
  'hotelExtend/multi_delete_hotel_extend',
  async (params: TypeMultiDelete, thunkAPI) => {
    const requestUrl = `${apiUrl}/delete-many`;
    const result = await axios.post(requestUrl, params);
    return result;
  },
  { serializeError: serializeAxiosError }
)

// slice

export const HotelSliceExtend = createEntitySlice({
  name: 'hotelExtend',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(multiDeleteHotel.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getEntitiesRemaining), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          updateSuccess: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10)
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getEntitiesRemaining), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, multiDeleteHotel), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  }
});

export const { reset } = HotelSliceExtend.actions;

// Reducer
export default HotelSliceExtend.reducer;
