import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import React from 'react';
import { Storage } from 'react-jhipster';
import { Route } from 'react-router-dom';
import LogoHotel from './logo-hotel';
import { AUTHORITIES } from 'app/config/constants';
import SelectHotelPage from 'app/components/select-hotel-page/SelectHotelPage';

const LogoHotelRoutes = () => {
  const role = Storage.session.get('role');

  return (
    <ErrorBoundaryRoutes>
      {role == AUTHORITIES.ADMIN
        ? <>
          <Route index element={<SelectHotelPage />} />
          <Route path='hotel/:id' element={<LogoHotel />} />
        </>
        : <Route index element={<LogoHotel />} /> 
      }
    </ErrorBoundaryRoutes>
  );
};

export default LogoHotelRoutes;
