import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import axios from 'axios';
import { IChannelPackMaster, defaultValue } from 'app/shared/model/channel-pack-master.model';
import { EntityState, IQueryParams, createEntitySlice, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';

type TypeMultiDelete = {
  listId: string[];
};

const initialState: EntityState<IChannelPackMaster >  & { numbers: number[]}= {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  numbers: []
};

export enum TYPE_SERVICE_PACK {
  ALL = 'ALL',
  ACTIVATE = 'ACTIVATE'
}

type IQueryParamsExtend = IQueryParams & {
  type?: TYPE_SERVICE_PACK;
  hotelId?: string;
  packId?: string;
  language?: string;
  tvPage?: number;
  tvSize?: number;
  moviePage?: number;
  movieSize?: number;
};

const apiUrl = 'api/channel-pack-masters-extend';
const apiUrlViewChannel = 'api/channel-pack-masters-extend/view';
const apiUrlChannelHotel = 'api/channel-pack-masters-extend/hotel';

// Actions
//get all service pack by hotel
export const getEntities = createAsyncThunk(
  'channelPackMasterExtend/fetch_entity_list',
  async ({ page, size, sort, type, hotelId, nameSearch }: IQueryParamsExtend & { nameSearch?: string }) => {
    const requestUrl = `${apiUrlChannelHotel}${`?page=${page}&size=${size}&type=${type}${
      hotelId ? `&hotelId=${hotelId}` : ''
    }&sort=${sort}&nameSearch=${nameSearch}&`}cacheBuster=${new Date().getTime()}`;
    return axios.get<IChannelPackMaster[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'channelPackMasterExtend/fetch_entity',
  async ({ tvPage, tvSize, moviePage, sort, packId, language, movieSize }: IQueryParamsExtend) => {
    const requestUrl = `${apiUrlViewChannel}?packId=${packId}&language=${language}${tvPage ? `&tvPage=${tvPage}` : ''}${
      tvSize ? `&tvSize=${tvSize}` : ''
    }${moviePage ? `&moviePage=${moviePage}` : ''}${movieSize ? `&movieSize=${movieSize}` : ''}${sort ? `&sort=${sort}` : ''}`;
    return axios.get<IChannelPackMaster>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getOrderAvailable = createAsyncThunk(
  'channelPackMasterExtend/getOrderAvailable',
  async ({packId}: IQueryParamsExtend) => {
    const requestUrl = `${apiUrl}/order-available?packId=${packId}`;
    return axios.get<{ orders: number[] }>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);


export const getListMovieInPack = createAsyncThunk(
  'channelPackMasterExtend/getListMovieInPack',
  async ({ tvPage, tvSize, moviePage, sort, packId, language, movieSize }: IQueryParamsExtend) => {
    const requestUrl = `${apiUrlViewChannel}?packId=${packId}&language=${language}${tvPage ? `&tvPage=${tvPage}` : ''}${
      tvSize ? `&tvSize=${tvSize}` : ''
    }${moviePage ? `&moviePage=${moviePage}` : ''}${movieSize ? `&movieSize=${movieSize}` : ''}${sort ? `&sort=${sort}` : ''}`;
    const res = await axios.get<IChannelPackMaster>(requestUrl);

    return res.data;
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'channelPackMasterExtend/create_entity',
  async ({hotelId, ...entity}: IChannelPackMaster & {hotelId?: string}, thunkAPI) => {
    const requestUrl = `${apiUrl}?${hotelId ? `hotelId=${hotelId}` : ''}`
    const result = await axios.post<IChannelPackMaster>(requestUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'channelPackMasterExtend/update_entity',
  async (entity: IChannelPackMaster, thunkAPI) => {
    const result = await axios.put<IChannelPackMaster>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'channelPackMasterExtend/partial_update_entity',
  async (entity: IChannelPackMaster, thunkAPI) => {
    const result = await axios.patch<IChannelPackMaster>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'channelPackMasterExtend/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IChannelPackMaster>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const multiDeleteChannelPackMaster = createAsyncThunk(
  'channelPackMasterExtend/multi_delete_channel_pack_master_extend',
  async (params: TypeMultiDelete, thunkAPI) => {
    const requestUrl = `${apiUrl}/delete-many`;
    const result = await axios.post(requestUrl, params);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ChannelPackMasterSliceExtend = createEntitySlice({
  name: 'channelPackMasterExtend',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getOrderAvailable.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(multiDeleteChannelPackMaster.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getOrderAvailable), (state, action) => {
        state.orders = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          updateSuccess: false,
          totalItems: parseInt(headers['x-total-count'], 10)
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, multiDeleteChannelPackMaster), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  }
});

export const { reset } = ChannelPackMasterSliceExtend.actions;

// Reducer
export default ChannelPackMasterSliceExtend.reducer;

