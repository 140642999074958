import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import ChannelDetail from './channel-detail';
import ChannelUpdate from './channel-update';
import ChannelDeleteDialog from './channel-delete-dialog';
import ChannelExtend from 'app/entities/channel/channel-extend';
import ChannelUpdateExtend from 'app/entities/channel/channel-update-extend';
import ChannelDetailExtend from './channel-detail-extend';
import ChannelDeleteDialogExtend from './channel-delete-dialog-extend';

const ChannelRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ChannelExtend />} />
    <Route path="new" element={<ChannelUpdateExtend />} />
    <Route path=":id">
      <Route index element={<ChannelDetailExtend />} />
      <Route path="edit" element={<ChannelUpdateExtend />} />
      <Route path="delete" element={<ChannelDeleteDialogExtend />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ChannelRoutes;
