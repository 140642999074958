import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount, Storage, translate, ValidatedField, ValidatedInput } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IApiKey } from 'app/shared/model/api-key.model';
import { getEntities, multiDeleteDevice } from './device.reducer';
import ModalConfirm from 'app/components/modal-confirm/ModalConfirm';
import { Status } from 'app/shared/model/enumerations/status.model';
import { EPath } from 'app/utils/constants/EPath';
import InputSearch from 'app/components/input-search';
import { useDebounce } from 'app/utils/customHooks/useDebounce';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';
import { toast } from 'react-toastify';

export const Device = () => {
  const role = Storage.session.get("role");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const deviceList = useAppSelector(state => state.device.entities);
  const loading = useAppSelector(state => state.device.loading);
  const totalItems = useAppSelector(state => state.device.totalItems);
  const paramsEndpointDevice = useAppSelector(state => state.confirmDevice.data);

  const [textSearch, setTextSearch] = useState("");
  const debouncedValue = useDebounce<string>(textSearch, 500)
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const openConfirmDelete = () => {
    setOpenConfirm(!openConfirm);
  };

  const onSubmitConfirmDelete = async () => {
    dispatch(spinLoading());
    try {
      const params = {
        listId: selectedRows
      };
      await dispatch(multiDeleteDevice(params));
      setSelectedRows([]);
      toast.success("Success");
    } catch (err) {
      toast.error(err.errorFields[0].errors[0]);
    } finally {
      openConfirmDelete();
      dispatch(spinUnLoading());
      sortEntities();
    }
  };

  const selectRow = (id: string): void => {
    setSelectedRows([...selectedRows, id]);
  };

  const deleteRow = (id: string): void => {
    setSelectedRows(selectedRows.filter((i) => i !== id));
  };

  useEffect(() => {
    (selectedRows.length > 0 && selectedRows.length === deviceList.length) ? setSelectAll(true) : setSelectAll(false);
  }, [selectedRows]);

  useEffect(() => {
    selectAll ? setSelectedRows(deviceList.map(device => device.uniqueKey)) : null;
    if (!selectAll && selectedRows.length === deviceList.length) {
      setSelectedRows([]);
    }
  }, [selectAll]);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        ...(role.includes(AUTHORITIES.ADMIN) ? { hotelId: id } : {}),
        roomName: textSearch
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    // const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    const endURL = `?page=${paginationState.activePage}&sort=createdAt,desc`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, debouncedValue]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="api-key-heading" data-cy="ApiKeyHeading">
        <div className="d-flex gap-3 align-items-center">
          {role.includes(AUTHORITIES.ADMIN)
            ? <FontAwesomeIcon icon="arrow-left" size="sm" onClick={() => navigate("/device")} />
            : null
          }
          <Translate contentKey="hotelTvApp.apiKey.home.title">Api Keys</Translate>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="w-25">
            <InputSearch
              name={'textSearch'}
              onChange={(e) => setTextSearch(e.target.value)}
            />
          </div>
          <div>
            <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              <Translate contentKey="hotelTvApp.apiKey.home.refreshListLabel">Refresh List</Translate>
            </Button>
          </div>
        </div>
      </h2>
      <div className="table-responsive">
        <div className="position-absolute mt-2">
          {selectedRows.length > 0
            ? <div className="d-flex align-items-center fs-6 gap-2">
              <div style={{ minWidth: "120px" }}>
                {translate("hotelTvApp.userInformation.home.titleSelected")}: {selectedRows.length}
              </div>
              <Button size="sm" color="danger" onClick={openConfirmDelete} hidden={role.includes(AUTHORITIES.MONITOR)}>
                <FontAwesomeIcon icon="trash" />{' '}
              </Button>
            </div>
            : null
          }
        </div>
        {deviceList && deviceList.length > 0 ? (
          <Table responsive className="table table-hover mt-5">
            <thead>
              <tr>
                <th className="hand position-sticky start-0 bg-white">
                  <ValidatedField
                    className="checkbox-select-table-row"
                    type="checkbox"
                    name={"checkboxAllRow"}
                    checked={selectAll}
                    onChange={(e) => {
                      const { checked } = e.target;
                      checked ? setSelectAll(true) : setSelectAll(false);
                    }}
                  />
                </th>
                <th className="hand" onClick={sort('stt')}>
                  <Translate contentKey="hotelTvApp.apiKey.stt">STT</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hotelTvApp.apiKey.room">Room</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hotelTvApp.apiKey.deviceId">Device ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand text-center" onClick={sort('isActive')}>
                  <Translate contentKey="hotelTvApp.apiKey.isActive">Is Active Device</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hotelTvApp.apiKey.channelPackMaster">Channel Pack Master</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand text-center" onClick={sort('status')}>
                  <Translate contentKey="hotelTvApp.apiKey.status">Status Channel Pack Master</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hotelTvApp.apiKey.activationDate">Activation Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hotelTvApp.apiKey.expirationDate">Expiration Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updateBy')}>
                  <Translate contentKey="hotelTvApp.apiKey.updatedBy">Updated By</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand text-center position-sticky end-0 bg-white">
                  <Translate contentKey="hotelTvApp.apiKey.actions">Actions</Translate>
                </th>
              </tr>
            </thead>
            <tbody>
              {deviceList.map((device, i) => (
                <tr
                  key={`entity-${i}`}
                  data-cy="entityTable"
                  onClick={() => navigate(role.includes(AUTHORITIES.ADMIN)
                    ? `${EPath.DetailDeviceADMIN}/${id}/${device.id}`
                    : `${EPath.Device}/${device.id}`
                  )}
                  className="cursor-pointer"
                >
                  <td className="position-sticky start-0 bg-white">
                    <div onClick={e => e.stopPropagation()}>
                      <ValidatedField
                        className="checkbox-select-table-row"
                        type="checkbox"
                        name={`device${i}`}
                        checked={selectedRows.includes(device.uniqueKey)}
                        onChange={(e) => {
                          const { checked } = e.target;
                          if (checked) {
                            !selectedRows.includes(device.uniqueKey) ? selectRow(device.uniqueKey) : null
                          } else {
                            deleteRow(device.uniqueKey);
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td className='stt'>{(paginationState.activePage) * paginationState.itemsPerPage + i + 1 - paginationState.itemsPerPage}</td>
                  <td className='data-row-sm'>{device.room?.name}</td>
                  <td className='data-row-sm'>{device.uniqueKey}</td>
                  <td className='data-row-lg text-center'>{device.isActive
                    ? <Badge color="primary">{translate("global.form.active")}</Badge>
                    : <Badge color="danger">{translate("global.form.deactive")}</Badge>}
                  </td>
                  <td className='data-row-lg'>
                    {device.channelPackMaster ? (
                      device.channelPackMaster.name
                    ) : (
                      ''
                    )}
                  </td>
                  <td className='data-row-lg text-center'>{device.status
                    ? device.status === Status.ACTIVATE
                      ? <Badge color="primary">{translate("global.form.active")}</Badge>
                      : <Badge color="danger">{translate("global.form.deactive")}</Badge>
                    : null
                  }
                  </td>
                  <td className='data-row-sm'>{device.activationDate ? <TextFormat type="date" value={device.activationDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td className='data-row-sm'>{device.expiryDate ? <TextFormat type="date" value={device.expiryDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td className='data-row-lg'>{device.author}</td>
                  <td className="text-end position-sticky end-0 bg-white">
                    <div className="btn-group flex-btn-group-container" onClick={e => e.stopPropagation()}>
                      <Button tag={Link}
                        to={role.includes(AUTHORITIES.ADMIN)
                          ? `${EPath.DetailDeviceADMIN}/${id}/${device.id}`
                          : `${EPath.Device}/${device.id}`
                        }
                        color="info" size="sm" data-cy="entityDetailsButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        hidden={role.includes(AUTHORITIES.MONITOR)}
                        tag={Link}
                        to={role.includes(AUTHORITIES.ADMIN)
                          ? `${EPath.DetailDeviceADMIN}/${id}/${device.id}/edit`
                          : `${EPath.Device}/${device.id}/edit`
                        }
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        hidden={role.includes(AUTHORITIES.MONITOR)}
                        tag={Link}
                        to={role.includes(AUTHORITIES.ADMIN)
                          ? `${EPath.DetailDeviceADMIN}/${id}/${device.id}/delete`
                          : `${EPath.Device}/${device.id}/delete`
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hotelTvApp.apiKey.home.notFound">No Api Keys found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={deviceList && deviceList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : null}
      <ModalConfirm
        isOpen={openConfirm}
        toggle={openConfirmDelete}
        title={translate("hotelTvApp.userInformation.confirm.titleConfirm")}
        onSubmit={onSubmitConfirmDelete}
      >
        {`
          ${translate("hotelTvApp.userInformation.confirm.contentConfirm")} 
          ${selectedRows.length} 
          ${translate("hotelTvApp.userInformation.confirm.dataConfirm")}?
        `}
      </ModalConfirm>
    </div>
  );
};

export default Device;
