import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { serializeAxiosError } from "app/shared/reducers/reducer.utils";

const apiUrl = 'api/export-excel';

export const exportExcelPackSChanelUsageHistoryAsync = createAsyncThunk(
    'exportExcel/export_excel',
    async (params: { startTime?: string; endTime?: string; idPack?: string; statusDevice?: boolean; nameSearch?: string; hotelId?: string, isDeleted?: boolean }) => {
        const apiUrlRequest = `${apiUrl}/service-usage-history` +
            `?startTime=${params.startTime ?? ''}` +
            `&endTime=${params.endTime ?? ''}` +
            `&idPack=${params.idPack ?? ''}` +
            `&statusDevice=${params.statusDevice ?? ''}` +
            `&nameSearch=${params.nameSearch ?? ''}` +
            `&hotelId=${params.hotelId ?? ''}` +
            `${params.isDeleted !== null ? `&isDeleted=${params.isDeleted}` : ''}`;

        const response = await axios.get(apiUrlRequest, { responseType: 'blob' });
        return response.data;
    },
    { serializeError: serializeAxiosError }
);

const exportExcelSlice = createSlice({
    name: 'exportExcel',
    initialState: {
        loading: false,
        error: null,
        fileBlob: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(exportExcelPackSChanelUsageHistoryAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.fileBlob = null;
            })
            .addCase(exportExcelPackSChanelUsageHistoryAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.fileBlob = action.payload;
            })
            .addCase(exportExcelPackSChanelUsageHistoryAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    }
});

export default exportExcelSlice.reducer;
