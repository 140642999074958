import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ISeriesMovie, defaultValue } from 'app/shared/model/series-movie.model';

type TypeMultiDelete = {
  listId: string[];
};

const initialState: EntityState<ISeriesMovie> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

type seriesMovieQueryParams = IQueryParams & {
  menuId?: string;
  language?: string;
  nameSearch?: string;
};

const apiUrl = 'api/series-movies-extend';

// Actions

export const getEntitiesExtend = createAsyncThunk(
  'seriesMovie/fetch_entity_list',
  async ({ page, size, sort, menuId, language, nameSearch }: seriesMovieQueryParams) => {
    const requestUrl = `${apiUrl}${
      sort ? `?page=${page}&menuId=${menuId}&language=${language}&nameSearch=${nameSearch}&size=${size}&sort=${sort}&` : '?'
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<ISeriesMovie[]>(requestUrl);
  }
);

export const getAllMovies = createAsyncThunk(
  'seriesMovie/fetch_entity_list',
  async ({ page, size, sort, menuId, language }: seriesMovieQueryParams) => {
    const requestUrl = `${apiUrl}/status${`?page=${page}&menuId=${menuId}&status=ACTIVATE&language=${language}&size=${size}&sort=${sort}&`}cacheBuster=${new Date().getTime()}`;
    return axios.get<ISeriesMovie[]>(requestUrl);
  }
);

export const getTotalMovieByLanguage = createAsyncThunk(
  'seriesMovie/getTotalMovieByLanguage',
  async ({ page, size, sort, menuId, language }: seriesMovieQueryParams) => {
    const requestUrl = `${apiUrl}/status${`?page=${page}&menuId=${menuId}&status=ACTIVATE&language=${language}&size=${size}&sort=${sort}&`}cacheBuster=${new Date().getTime()}`;
    return await axios.get<ISeriesMovie[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'seriesMovie/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ISeriesMovie>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntityExtend = createAsyncThunk(
  'seriesMovie/create_entity',
  async (entity: ISeriesMovie, thunkAPI) => {
    const result = await axios.post<ISeriesMovie>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntityExtend = createAsyncThunk(
  'seriesMovie/update_entity',
  async (entity: ISeriesMovie, thunkAPI) => {
    const result = await axios.put<ISeriesMovie>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntityExtend = createAsyncThunk(
  'seriesMovie/partial_update_entity',
  async (entity: ISeriesMovie, thunkAPI) => {
    const result = await axios.patch<ISeriesMovie>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntityExtend = createAsyncThunk(
  'seriesMovie/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ISeriesMovie>(requestUrl);
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const multiDeleteSeriesMovie = createAsyncThunk(
  'seriesMovieExtend/multi_delete_series_movies_extend',
  async (params: TypeMultiDelete, thunkAPI) => {
    const requestUrl = `${apiUrl}/delete-many`;
    const result = await axios.post(requestUrl, params);
    // thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const SeriesMovieSliceExtend = createEntitySlice({
  name: 'seriesMovieExtend',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntityExtend.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(multiDeleteSeriesMovie.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntitiesExtend), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          updateSuccess: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10)
        };
      })
      .addMatcher(isFulfilled(getAllMovies), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10)
        };
      })
      .addMatcher(isFulfilled(createEntityExtend, updateEntityExtend, partialUpdateEntityExtend), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntitiesExtend, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntityExtend, updateEntityExtend, partialUpdateEntityExtend, deleteEntityExtend), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  }
});

export const { reset } = SeriesMovieSliceExtend.actions;

// Reducer
export default SeriesMovieSliceExtend.reducer;
