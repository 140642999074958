import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import IntroHotelDeleteDialogExtend from './intro-hotel-delete-dialog-extend';
import IntroHotelDetailExtend from './intro-hotel-detail-extend';
import IntroHotelExtend from './intro-hotel-extend';
import IntroHotelUpdateExtend from './intro-hotel-update-extend';
import { Storage } from 'react-jhipster';
import { AUTHORITIES } from 'app/config/constants';
import SelectHotelPage from 'app/components/select-hotel-page/SelectHotelPage';

const IntroHotelRoutes = () => {
  const role = Storage.session.get('role');

  return (
    <ErrorBoundaryRoutes>
      {role.includes(AUTHORITIES.ADMIN)
        ? <>
          <Route index element={<SelectHotelPage />} />
          <Route path='hotel/:id' element={<IntroHotelExtend />} />
        </>
        : <Route index element={<IntroHotelExtend />} />
      }
      {role.includes(AUTHORITIES.ADMIN)
        ? <Route path="hotel/:id/new" element={<IntroHotelUpdateExtend />} />
        : <Route path="new" element={<IntroHotelUpdateExtend />} />
      }
      {role.includes(AUTHORITIES.ADMIN)
        ? <Route path="hotel/:id/:idIntroHotel">
            <Route index element={<IntroHotelDetailExtend />} />
            <Route path="edit" element={<IntroHotelUpdateExtend />} />
            <Route path="delete" element={<IntroHotelDeleteDialogExtend />} />
        </Route>
        : <Route path=":id">
            <Route index element={<IntroHotelDetailExtend />} />
            <Route path="edit" element={<IntroHotelUpdateExtend />} />
            <Route path="delete" element={<IntroHotelDeleteDialogExtend />} />
        </Route>
      }
    </ErrorBoundaryRoutes>
  );
}

export default IntroHotelRoutes;
