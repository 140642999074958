import dayjs from 'dayjs';
import { IUserInformation } from 'app/shared/model/user-information.model';
import { ISlideShow } from 'app/shared/model/slide-show.model';
import { IContract } from 'app/shared/model/contract.model';
import { IIntroHotel } from 'app/shared/model/intro-hotel.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IHotel {
  id?: string;
  status?: Status;
  name?: string | null;
  companyName?: string | null;
  idHotel?: string | null;
  hotelAddress?: string | null;
  legalAddress?: string | null;
  taxCode?: string | null;
  website?: string | null;
  description?: string | null;
  phoneNumber?: string | null;
  position?: string | null;
  contact?: string | null;
  email?: string | null;
  author?: string | null;
  pinCode?: string | null;
  logoHotel?: string | null;
  lat?: string | null;
  lon?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isDeleted?: boolean | null;
  userInformations?: IUserInformation[] | null;
  imageHotels?: ISlideShow[] | null;
  contracts?: IContract[] | null;
  introHotels?: IIntroHotel[] | null;
}

export const defaultValue: Readonly<IHotel> = {
  isDeleted: false,
};
