import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { IPaginationBaseState, JhiItemCount, Storage, JhiPagination, Translate, getSortState, ValidatedField, translate, TextFormat } from 'react-jhipster';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { getEntitiesExtend, multiDeleteServiceHotel } from './service-hotel-extend.reducer';
import { convertMoney, convertTypeServiceHotel } from 'app/utils/helpers/func';
import { useDebounce } from 'app/utils/customHooks/useDebounce';
import { APP_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { EPath } from 'app/utils/constants/EPath';
import InputSearch from 'app/components/input-search';
import ModalConfirm from 'app/components/modal-confirm/ModalConfirm';
import "./style.scss";
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';
import { toast } from 'react-toastify';

export interface IPaginationStateExtend {
  itemsPerPage: number;
  sort: string;
  order: string;
  activePage: number;
  menuId?: string;
  language?: string;
}
export const overridePaginationStateWithQueryParamsExtend = (
  paginationBaseState: IPaginationBaseState,
  locationSearch: string
): IPaginationStateExtend => {
  const params = new URLSearchParams(locationSearch);

  const page = params.get('page');
  const sort = params.get('sort');
  const menuId = params.get('menuId');

  if (page && sort) {
    const sortSplit = sort.split(',');
    paginationBaseState.activePage = +page;
    paginationBaseState.sort = sortSplit[0];
    paginationBaseState.order = sortSplit[1];
  }
  return menuId
    ? {
      ...paginationBaseState,
      menuId
    }
    : paginationBaseState;
};

export const ServiceHotelExtend = () => {
  const role = Storage.session.get('role');
  const { id } = useParams<'id'>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const page = params.get('page');
  const menuId = params.get('menuId');
  const language = params.get('language');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParamsExtend(getSortState(location, ITEMS_PER_PAGE, 'createdAt', 'desc'), location.search)
  );

  const serviceHotelList = useAppSelector(state => state.serviceHotelExtend.entities);
  const loading = useAppSelector(state => state.serviceHotelExtend.loading);
  const totalItems = useAppSelector(state => state.serviceHotelExtend.totalItems);

  const [textSearch, setTextSearch] = useState('');
  const debouncedValue = useDebounce<string>(textSearch, 500);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const openConfirmDelete = () => {
    setOpenConfirm(!openConfirm);
  };

  const onSubmitConfirmDelete = async () => {
    dispatch(spinLoading());
    try {
      const params = {
        listId: selectedRows
      };
      await dispatch(multiDeleteServiceHotel(params));
      setSelectedRows([]);
      toast.success("Success");
    } catch (err) {
      toast.error(err.errorFields[0].errors[0]);
    } finally {
      openConfirmDelete();
      dispatch(spinUnLoading());
      sortEntities();
    }
  };

  const selectRow = (id: string): void => {
    setSelectedRows([...selectedRows, id]);
  };

  const deleteRow = (id: string): void => {
    setSelectedRows(selectedRows.filter(i => i !== id));
  };

  useEffect(() => {
    selectedRows.length > 0 && selectedRows.length === serviceHotelList.length ? setSelectAll(true) : setSelectAll(false);
  }, [selectedRows]);

  useEffect(() => {
    selectAll ? setSelectedRows(serviceHotelList.map(serviceHotel => serviceHotel.id)) : null;
    if (!selectAll && selectedRows.length === serviceHotelList.length) {
      setSelectedRows([]);
    }
  }, [selectAll]);

  const getAllEntities = () => {
    dispatch(
      getEntitiesExtend({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        menuId: paginationState.menuId,
        language: paginationState.language,
        sort: `${paginationState.sort},${paginationState.order}`,
        nameSearch: textSearch
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&menuId=${paginationState.menuId}&language=${language}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, paginationState.menuId, language, debouncedValue]);

  useEffect(() => {
    const sort = params.get(SORT);
    let data = {} as IPaginationStateExtend;
    if (page && sort) {
      const sortSplit = sort.split(',');
      data.activePage = +page;
      data.sort = sortSplit[0];
      data.order = sortSplit[1];
    }
    if (menuId) {
      data.menuId = menuId;
    }
    setPaginationState({
      ...paginationState,
      ...data
    });
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="service-hotel-heading" data-cy="ServiceHotelHeading">
        <div className="d-flex gap-3 align-items-center">
          <FontAwesomeIcon
            icon="arrow-left"
            onClick={() => navigate(role.includes(AUTHORITIES.ADMIN) ? `${EPath.MenuServiceADMIN}/${id}` : EPath.MenuService)}
          />
          <Translate contentKey="hotelTvApp.serviceHotel.home.title">Service Hotels</Translate>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="w-25">
            <InputSearch name={'textSearch'} onChange={e => setTextSearch(e.target.value)} />
          </div>
          <div>
            <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              <Translate contentKey="hotelTvApp.serviceHotel.home.refreshListLabel">Refresh List</Translate>
            </Button>
            {!role.includes(AUTHORITIES.MONITOR)
              ? (<Link
                to={role.includes(AUTHORITIES.ADMIN)
                  ? `${EPath.ServiceHotelADMIN}/${id}/new?menuId=${paginationState.menuId}&language=${language}`
                  : `${EPath.ServiceHotel}/new?menuId=${paginationState.menuId}&language=${language}`
                }
                className="btn btn-primary jh-create-entity"
                id="jh-create-entity"
                data-cy="entityCreateButton"
              >
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="hotelTvApp.serviceHotel.home.createLabel">Create new Service Hotel</Translate>
              </Link>)
              : null
            }
          </div>
        </div>
      </h2>
      <div className="table-responsive">
        <div className="position-absolute mt-2">
          {selectedRows.length > 0 ? (
            <div className="d-flex align-items-center fs-6 gap-2">
              <div style={{ minWidth: '120px' }}>
                {translate('global.multiDelete.titleSelected')}: {selectedRows.length}
              </div>
              {!role.includes(AUTHORITIES.MONITOR)
                ? (<Button size="sm" color="danger" onClick={openConfirmDelete}>
                  <FontAwesomeIcon icon="trash" />{' '}
                </Button>)
                : null
              }
            </div>
          ) : null}
        </div>
        {serviceHotelList && serviceHotelList.length > 0 ? (
          <Table responsive className="table table-hover mt-5">
            <thead>
              <tr>
                <th className="hand position-sticky start-0 bg-white">
                  <ValidatedField
                    className="checkbox-select-table-row"
                    type="checkbox"
                    name={'checkboxAllRow'}
                    checked={selectAll}
                    onChange={e => {
                      const { checked } = e.target;
                      checked ? setSelectAll(true) : setSelectAll(false);
                    }}
                  />
                </th>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hotelTvApp.serviceHotel.stt">STT</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="hotelTvApp.serviceHotel.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="hotelTvApp.serviceHotel.description">Description</Translate>
                </th>
                <th className="hand">
                  <Translate contentKey="hotelTvApp.serviceHotel.image">Image</Translate>
                </th>
                <th className="hand" onClick={sort('cost')}>
                  <Translate contentKey="hotelTvApp.serviceHotel.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('costSale')}>
                  <Translate contentKey="hotelTvApp.serviceHotel.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unit')}>
                  <Translate contentKey="hotelTvApp.serviceHotel.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unit')}>
                  <Translate contentKey="hotelTvApp.serviceHotel.author">Author</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {serviceHotelList.map((serviceHotel, i) => (
                <tr
                  key={`entity-${i}`}
                  data-cy="entityTable"
                  className="cursor-pointer"
                  onClick={() => navigate(role.includes(AUTHORITIES.ADMIN)
                    ? `${EPath.ServiceHotelADMIN}/${id}/${serviceHotel.id}`
                    : `${EPath.ServiceHotel}/${serviceHotel.id}`
                  )}
                >
                  <td className="position-sticky start-0 bg-white">
                    <div onClick={e => e.stopPropagation()}>
                      <ValidatedField
                        className="checkbox-select-table-row"
                        type="checkbox"
                        name={`entity${i}`}
                        checked={selectedRows.includes(serviceHotel.id)}
                        onChange={e => {
                          const { checked } = e.target;
                          if (checked) {
                            !selectedRows.includes(serviceHotel.id) ? selectRow(serviceHotel.id) : null;
                          } else {
                            deleteRow(serviceHotel.id);
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td className='stt'>{(paginationState.activePage - 1) * paginationState.itemsPerPage + i + 1}</td>
                  <td className='data-row-lg'>{serviceHotel.name}</td>
                  <td className='data-row-xl'>{serviceHotel.description}</td>
                  <td className='data-row-lg'>
                    {serviceHotel.imageUrl && <img src={serviceHotel.imageUrl} width={100} height={50} style={{ objectFit: 'cover' }} />}
                  </td>
                  <td className='data-row-lg'>{convertTypeServiceHotel(serviceHotel.type)}</td>
                  <td className='data-row-lg'><TextFormat type="date" value={serviceHotel.createdAt} format={APP_DATE_FORMAT} /></td>
                  <td className='data-row-lg'><TextFormat type="date" value={serviceHotel.updatedAt} format={APP_DATE_FORMAT} /></td>
                  <td className='data-row-lg'>{serviceHotel.author}</td>
                  <td className="text-end position-sticky end-0 bg-white">
                    <div className="btn-group flex-btn-group-container" onClick={e => e.stopPropagation()}>
                      <Button
                        tag={Link}
                        to={role.includes(AUTHORITIES.ADMIN)
                          ? `${EPath.ServiceHotelADMIN}/${id}/${serviceHotel.id}` + location.search
                          : `${EPath.ServiceHotel}/${serviceHotel.id}` + location.search
                        }
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        hidden={role.includes(AUTHORITIES.MONITOR)}
                        tag={Link}
                        to={role.includes(AUTHORITIES.ADMIN)
                          ? `${EPath.ServiceHotelADMIN}/${id}/${serviceHotel.id}/edit?language=${language}`
                          : `${EPath.ServiceHotel}/${serviceHotel.id}/edit?language=${language}`
                        }
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        hidden={role.includes(AUTHORITIES.MONITOR)}
                        tag={Link}
                        to={role.includes(AUTHORITIES.ADMIN)
                          ? `${EPath.ServiceHotelADMIN}/${id}/${serviceHotel.id}/delete?menuId=${paginationState.menuId}`
                          : `${EPath.ServiceHotel}/${serviceHotel.id}/delete?menuId=${paginationState.menuId}`
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hotelTvApp.serviceHotel.home.notFound">No Service Hotels found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={serviceHotelList && serviceHotelList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <ModalConfirm
        isOpen={openConfirm}
        toggle={openConfirmDelete}
        title={translate('global.multiDelete.confirm.titleConfirm')}
        onSubmit={onSubmitConfirmDelete}
      >
        {`
          ${translate('global.multiDelete.confirm.contentConfirm')} 
          ${selectedRows.length} 
          ${translate('global.multiDelete.confirm.dataConfirm')}?
        `}
      </ModalConfirm>
    </div>
  );
};

export default ServiceHotelExtend;
