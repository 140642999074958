import { IUser } from 'app/shared/model/user.model';
import { IHotel } from 'app/shared/model/hotel.model';

export interface IUserInformation {
  id?: string;
  position?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
  indexUser?: number | null;
  role?: string | null;
  user?: IUser | null;
  hotel?: IHotel | null;
}

export const defaultValue: Readonly<IUserInformation> = {};
