import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat, Storage } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './menu-service.reducer';
import { EPath } from 'app/utils/constants/EPath';
import { TMenuService } from '.';
import { Inform } from 'app/shared/model/enumerations/inform.model';

export const MenuServiceDetailExtend = (props: TMenuService) => {
  const { type } = props;
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const { idMenuService } = useParams<'idMenuService'>();

  useEffect(() => {
    role.includes(AUTHORITIES.ADMIN)
      ? (type === Inform.ABOUT_HOTEL ? dispatch(getEntity(idMenuService)) : dispatch(getEntity(id)))
      : dispatch(getEntity(id))
  }, []);

  const menuServiceEntity = useAppSelector(state => state.menuService.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="menuServiceDetailsHeading">
          <Translate contentKey="hotelTvApp.menuService.detail.title">MenuService</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">
              <Translate contentKey="hotelTvApp.menuService.name">Name</Translate>
            </span>
          </dt>
          <dd>{menuServiceEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="hotelTvApp.menuService.description">Description</Translate>
            </span>
          </dt>
          <dd>{menuServiceEntity.description}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="hotelTvApp.menuService.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {menuServiceEntity.createdAt ? <TextFormat value={menuServiceEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="hotelTvApp.menuService.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {menuServiceEntity.updatedAt ? <TextFormat value={menuServiceEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="hotelTvApp.menuService.author">Author</Translate>
          </dt>
          <dd>{menuServiceEntity.hotel ? menuServiceEntity.author : ''}</dd>
        </dl>
        <Button id="cancel-save"
          data-cy="entityCreateCancelButton"
          onClick={() => history.back()}
          color="info"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          hidden={role.includes(AUTHORITIES.MONITOR)}
          tag={Link}
          to={role.includes(AUTHORITIES.ADMIN)
            ? `${EPath.MenuServiceADMIN}/${id}/${menuServiceEntity.id}/edit`
            : `${EPath.MenuService}/${menuServiceEntity.id}/edit`
          }
          replace color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default MenuServiceDetailExtend;
