import dayjs from 'dayjs';
import { ISetMedia } from 'app/shared/model/set-media.model';
import { IHotel } from 'app/shared/model/hotel.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface IIntroHotel {
  id?: string;
  title?: string | null;
  status?: Status | null;
  author?: string | null;
  language?: Language | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isDeleted?: boolean | null;
  setMedias?: ISetMedia[] | null;
  hotel?: IHotel | null;
}

export const defaultValue: Readonly<IIntroHotel> = {
  isDeleted: false,
};
