import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntityExtend } from './service-hotel-extend.reducer';
import { getEntity } from './service-hotel.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

export const ServiceHotelDeleteDialogExtend = () => {
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const { idServiceHotel } = useParams<'idServiceHotel'>();
  const params = new URLSearchParams(location.search);
  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    role.includes(AUTHORITIES.ADMIN) ? dispatch(getEntity(idServiceHotel)) : dispatch(getEntity(id))
    setLoadModal(true);
  }, []);

  const serviceHotelEntity = useAppSelector(state => state.serviceHotel.entity);
  const updateSuccessExtend = useAppSelector(state => state.serviceHotelExtend.updateSuccess);

  const menuId = params.get('menuId');
  const language = params.get('language');

  const handleClose = () => {
    history.back();
  };

  useEffect(() => {
    if (updateSuccessExtend && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccessExtend]);

  const confirmDelete = async () => {
    dispatch(spinLoading());
    await dispatch(deleteEntityExtend(serviceHotelEntity.id));
    dispatch(spinUnLoading());
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="serviceHotelDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="hotelTvApp.serviceHotel.delete.question">
        <Translate contentKey="hotelTvApp.serviceHotel.delete.question" interpolate={{ id: serviceHotelEntity.name }}>
          Are you sure you want to delete this ServiceHotel?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-serviceHotel" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ServiceHotelDeleteDialogExtend;
