import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import React from 'react';
import { Route } from 'react-router-dom';
import { Storage } from 'react-jhipster';
import ChannelPackMasterDetailExtend from './channel-pack-master-detail-extend';
import ChannelPackMasterExtend from './channel-pack-master-extend';
import ChannelPackMasterUpdateExtend from './channel-pack-master-update-extend';
import ChannelPackMasterDeleteDialogExtend from './channel-pack-master-delete-dialog-extend';
import { AUTHORITIES } from 'app/config/constants';
import SelectHotelPage from 'app/components/select-hotel-page/SelectHotelPage';

const ChannelPackMasterRoutes = () => {
  const role = Storage.session.get('role');

  return (
    <ErrorBoundaryRoutes>
      {role.includes(AUTHORITIES.ADMIN)
        ? <>
          <Route index element={<SelectHotelPage />} />
          <Route path='hotel/:id' element={<ChannelPackMasterExtend />} />
        </>
        : <Route index element={<ChannelPackMasterExtend />} />
      }
      {role.includes(AUTHORITIES.ADMIN) && <Route path="hotel/:id/new" element={<ChannelPackMasterUpdateExtend />} />}
      {role.includes(AUTHORITIES.ADMIN)
        ? <Route path="hotel/:id/:idChannelPackMaster">
          <Route index element={<ChannelPackMasterDetailExtend />} />
          <Route path="edit" element={<ChannelPackMasterUpdateExtend />} />
          <Route path="delete" element={<ChannelPackMasterDeleteDialogExtend />} />
        </Route>
        : <Route path=":id">
          <Route index element={<ChannelPackMasterDetailExtend />} />
          <Route path="edit" element={<ChannelPackMasterUpdateExtend />} />
        </Route>
      }
    </ErrorBoundaryRoutes>
  );
}

export default ChannelPackMasterRoutes;
