import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import MenuServiceDetailExtend from './menu-service-detail-extend';
import MenuServiceExtend from './menu-service-extend';
import MenuServiceUpdateExtend from './menu-service-update-extend';
import MenuServiceDeleteDialogExtend from './menu-service-delete-dialog-extend';
import { Storage } from 'react-jhipster'
import { AUTHORITIES } from 'app/config/constants';
import SelectHotelPage from 'app/components/select-hotel-page/SelectHotelPage';
import { Inform } from 'app/shared/model/enumerations/inform.model';

export type TMenuService = {
  type: 'ABOUT_HOTEL' | 'MOVIE';
}

const MenuServiceRoutes = (props: TMenuService) => {
  const { type } = props;
  const role = Storage.session.get('role');

  return (
    <ErrorBoundaryRoutes>
      {role.includes(AUTHORITIES.ADMIN)
        ? (
          type === 'ABOUT_HOTEL'
            ? <>
              <Route index element={<SelectHotelPage />} />
              <Route path='hotel/:id' element={<MenuServiceExtend type={type} />} />
            </>
            : <Route index element={<MenuServiceExtend type={type} />} />
        )
        : <Route index element={<MenuServiceExtend type={type} />} />
      }
      {role.includes(AUTHORITIES.ADMIN)
        ? (
          type === Inform.ABOUT_HOTEL
            ? <Route path="hotel/:id/new" element={<MenuServiceUpdateExtend type={type} />} />
            : <Route path="new" element={<MenuServiceUpdateExtend type={type} />} />
        )
        : <Route path="new" element={<MenuServiceUpdateExtend type={type} />} />
      }
      {role.includes(AUTHORITIES.ADMIN)
        ? (
          type === Inform.ABOUT_HOTEL
            ? <Route path="hotel/:id/:idMenuService">
              <Route index element={<MenuServiceDetailExtend type={type} />} />
              <Route path="edit" element={<MenuServiceUpdateExtend type={type} />} />
              <Route path="delete" element={<MenuServiceDeleteDialogExtend type={type}/>} />
            </Route>
            : <Route path=":id">
              <Route index element={<MenuServiceDetailExtend type={type} />} />
              <Route path="edit" element={<MenuServiceUpdateExtend type={type} />} />
              <Route path="delete" element={<MenuServiceDeleteDialogExtend type={type} />} />
            </Route>
        )
        : <Route path=":id">
          <Route index element={<MenuServiceDetailExtend type={type} />} />
          <Route path="edit" element={<MenuServiceUpdateExtend type={type} />} />
          <Route path="delete" element={<MenuServiceDeleteDialogExtend type={type} />} />
        </Route>
      }
    </ErrorBoundaryRoutes>
  );
}

export default MenuServiceRoutes;
