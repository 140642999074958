import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isEmail, isNumber, Storage, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getHotels, getEntitiesRemaining as getHotelsRemaining } from 'app/entities/hotel/hotel-extend.reducer';
import { IUserInformation } from 'app/shared/model/user-information.model';
import { getEntity, updateEntity, createEntity, reset, changePassword } from './user-information-extend.reducer';
import { EPath } from 'app/utils/constants/EPath';
import { AUTHORITIES } from 'app/config/constants';
import "./style.scss";
import { FormProvider, useForm } from 'react-hook-form';
import CommonInput from 'app/components/CommonInput';
import CommonSelect from 'app/components/CommonSelect';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

export const UserInformationUpdateExtend = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const role = Storage.session.get('role');
  const hotels = useAppSelector(state => state.hotelExtend.entities);
  const userInformationEntity = useAppSelector(state => state.userInformationExtend.entity);
  const loading = useAppSelector(state => state.userInformationExtend.loading);
  const updating = useAppSelector(state => state.userInformationExtend.updating);
  const updateSuccess = useAppSelector(state => state.userInformationExtend.updateSuccess);
  const methods = useForm({
    mode: 'onSubmit', criteriaMode: 'firstError', reValidateMode: 'onChange'
  });

  const handleClose = () => {
    history.back();
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      dispatch(getHotelsRemaining({}));
    } else {
      dispatch(getEntity(id));
      dispatch(getHotels({}));
    }
  }, []);

  useEffect(() => {
    isNew
      ? methods.reset({})
      : methods.reset({
        ...userInformationEntity,
        hotel: userInformationEntity?.hotel?.id,
        login: userInformationEntity?.userDTO?.login,
        position: userInformationEntity?.position,
        email: userInformationEntity?.userDTO?.email,
        phoneNumber: userInformationEntity?.phoneNumber,
        role: userInformationEntity?.userDTO?.authorities[0],
        status: userInformationEntity?.userDTO?.activated ? '1' : '0'
      });
  }, [userInformationEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    dispatch(spinLoading());
    let entity = {
      ...userInformationEntity,
      position: values.position,
      name: values.name,
      phoneNumber: values.phoneNumber,
      role: values.role,
      hotel: isNew ? hotels.find(it => it.id.toString() === values.hotel.toString()) : userInformationEntity.hotel,
      userDTO: {
        ...userInformationEntity.userDTO,
        login: values.login,
        email: values.email,
        activated: values.status === '1'
      },
      password: values.password
    };

    if (isNew) {
      await dispatch(createEntity(entity));
    } else {
      if (entity.password) {
        const paramResetPassword = {
          login: entity.userDTO.login,
          newPassword: entity.password
        }
        await dispatch(changePassword(paramResetPassword));
      }
      delete entity['password'];
      await dispatch(updateEntity(entity));
    }
    dispatch(spinUnLoading());
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hotelTvApp.userInformation.home.createOrEditLabel" data-cy="UserInformationCreateUpdateHeading">
            {isNew ? (
              <Translate contentKey="hotelTvApp.userInformation.home.createLabel">Create a UserInformation</Translate>
            ) : (
              <Translate contentKey="hotelTvApp.userInformation.home.editLabel">Edit a UserInformation</Translate>
            )}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(saveEntity)}>
                <CommonSelect
                  name='hotel'
                  label={translate('hotelTvApp.userInformation.hotel')}
                  disabled={isNew ? false : true}
                  placeholder={translate('hotelTvApp.userInformation.hotel')}
                  options={hotels
                    ? hotels.map(otherEntity => (
                      { key: otherEntity.id, value: otherEntity.id, label: otherEntity.name }
                    ))
                    : null
                  }
                />
                <CommonSelect
                  name='role'
                  label={translate('hotelTvApp.userInformation.role')}
                  defaultValue={'ROLE_ADMIN_HOTEL'}
                  disabled
                  options={[
                    { value: 'ROLE_ADMIN_HOTEL', label: translate('global.form.ROLE_ADMIN_HOTEL') },
                    { value: 'ROLE_USER', label: translate('global.form.role_user') }
                  ]}
                />
                <CommonSelect
                  name='status'
                  label={translate('hotelTvApp.userInformation.status')}
                  placeholder={translate('hotelTvApp.userInformation.status')}
                  options={[
                    { value: '1', label: translate('global.form.active') },
                    { value: '0', label: translate('global.form.deactive') }
                  ]}
                />
                <CommonInput
                  name="login"
                  label={
                    <p style={{ margin: 0 }}>
                      {`${translate('hotelTvApp.userInformation.login')}`}
                      {<span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  type="text"
                  placeholder="Username"
                  rules={{
                    required: {
                      value: true,
                      message: translate('register.messages.validate.login.required')
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$/,
                      message: translate('register.messages.validate.login.pattern')
                    },
                    maxLength: {
                      value: 50,
                      message: translate('register.messages.validate.login.maxlength')
                    }
                  }}
                />
                <CommonInput
                  name="email"
                  label={
                    <p style={{ margin: 0 }}>
                      {`${translate('global.form.email.label')}`}
                      {<span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  type="text"
                  disabled={!isNew}
                  placeholder={translate('global.form.email.placeholder')}
                  rules={{
                    required: {
                      value: true,
                      message: translate('global.messages.validate.email.required')
                    },
                    minLength: {
                      value: 5,
                      message: translate('global.messages.validate.email.minlength')
                    },
                    maxLength: {
                      value: 254,
                      message: translate('global.messages.validate.email.maxlength')
                    },
                    validate: v => isEmail(v) || translate('global.messages.validate.email.invalid')
                  }}
                />
                <CommonInput
                  name="name"
                  label={translate('hotelTvApp.userInformation.name')}
                  type="text"
                  placeholder="Name"
                />
                <CommonInput
                  name="phoneNumber"
                  label={translate('hotelTvApp.userInformation.phoneNumber')}
                  type="text"
                  placeholder="Phone Number"
                  rules={{
                    pattern: {
                      value: /^(?:\+?84|0)(?:\d){9,10}$/,
                      message: translate('global.messages.validate.phoneNumber.pattern')
                    }
                  }}
                />
                <CommonInput
                  name="position"
                  label={translate('hotelTvApp.userInformation.position')}
                  type="text"
                  placeholder="Position"
                />
                <CommonInput
                  name="password"
                  label={
                    <p style={{ margin: 0 }}>
                      {`${translate('global.form.password.label')}`}
                      {isNew && <span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  type="password"
                  placeholder={translate('global.form.password.placeholder')}
                  autoComplete="none"
                  rules={{
                    required: {
                      value: isNew,
                      message: translate('global.messages.validate.newpassword.required')
                    },
                    minLength: {
                      value: 4,
                      message: translate('global.messages.validate.newpassword.minlength')
                    },
                    pattern: {
                      value: /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=\S+$).{8,}$/,
                      message: translate('global.messages.validate.newpassword.pattern')
                    }
                  }}
                />
                <CommonInput
                  name="confirmPassword"
                  label={
                    <p style={{ margin: 0 }}>
                      {`${translate('global.form.confirmpassword.label')}`}
                      {isNew && <span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  type="password"
                  placeholder={translate('global.form.confirmpassword.placeholder')}
                  autoComplete="none"
                  rules={{
                    required: {
                      value: isNew,
                      message: translate('global.messages.validate.confirmpassword.required')
                    },
                    validate: (value) => {
                      return methods.watch('password') ? value === methods.watch('password') || translate('global.messages.validate.confirmpassword.confirm') : null;
                    }
                  }}
                />
                <Button
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  onClick={() => history.back()}
                  color="info"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button
                  color="primary"
                  id="save-entity"
                  data-cy="entityCreateSaveButton"
                  type="submit"
                  disabled={updating}
                >
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </form>
            </FormProvider>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UserInformationUpdateExtend;
