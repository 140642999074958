import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextArea from 'antd/es/input/TextArea';
import { UploadFile } from 'antd/es/upload/interface';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getHotels } from 'app/entities/hotel/hotel-extend.reducer';
import { Status } from 'app/shared/model/enumerations/status.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate, Storage } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ETypeFile, uploadSingleFileS3 } from '../s3-upload/s3.upload';
import { IResponsePayload } from '../welcome/welcome-extend-update';
import { createEntity, getEntity, reset, updateEntity } from './intro-hotel-extend.reducer';
import './style.scss';
import { EPath } from 'app/utils/constants/EPath';
import { AUTHORITIES } from 'app/config/constants';
import { cloneDeep } from 'lodash';
import { CONST_CONVERSION, variables } from 'app/utils/constants/variables';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

export type TImageSlide = {
  image?: UploadFile;
};
export const IntroHotelUpdate = () => {
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const { idIntroHotel } = useParams<'idIntroHotel'>();
  const isNew = role.includes(AUTHORITIES.ADMIN) ? idIntroHotel === undefined : id === undefined;
  const introHotelEntity = useAppSelector(state => state.introHotelExtend.entity);
  const loading = useAppSelector(state => state.introHotelExtend.loading);
  const updating = useAppSelector(state => state.introHotelExtend.updating);
  const updateSuccess = useAppSelector(state => state.introHotelExtend.updateSuccess);
  const statusValues = Object.keys(Status);
  const defaultData = [{ imgUrl: null, textMedia: '' }];
  const [data, setData] = useState(defaultData);
  const params = new URLSearchParams(location.search);
  const languageParam = params.get('language');
  const [errorContent, setErrorContent] = useState<{ image: Boolean; content: Boolean }[]>([{ image: false, content: false }]);

  useEffect(() => {
    if (introHotelEntity.setMedias && introHotelEntity.setMedias.length > 0) {
      setData([...introHotelEntity.setMedias]);
    }
  }, [introHotelEntity.setMedias, isNew]);

  useEffect(() => {
    if (isNew) {
      setData(defaultData);
    }
  }, [isNew]);

  const handleImageChange = async (index, file) => {
    const newData = cloneDeep(data);
    if ((file.size / CONST_CONVERSION.VALUE) > variables.IMAGE_SIZE) {
      let erros = errorContent;
      erros[index].image = true;
      setErrorContent(erros);
    } else {
      const newImge =
        file &&
        ((await dispatch(
          uploadSingleFileS3({
            file: file,
            type: ETypeFile.image
          })
        )) as IResponsePayload);

      newData[index].imgUrl = newImge.payload?.imgUrl;
      setData(newData);
    }
  };

  const handleTextChange = (index, text) => {
    const newData = cloneDeep(data);
    newData[index].textMedia = text;
    setData(newData);
    let erros = errorContent;
    erros[index].content = text.length > 1000;
    setErrorContent(erros);
  };

  const addRow = () => {
    setData([...data, { imgUrl: null, textMedia: '' }]);
    setErrorContent([...errorContent, { image: false, content: false }]);
  };

  const handleDelete = index => {
    const newData = [...data];
    newData.splice(index, 1); // Remove the item at the specified index
    setData(newData);
    const errors = [...errorContent];
    errors.splice(index, 1);
    setErrorContent(errors);
  };

  const handleClose = () => {
    history.back();
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      role.includes(AUTHORITIES.ADMIN) ? dispatch(getEntity(idIntroHotel)) : dispatch(getEntity(id))
    }

    dispatch(getHotels({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    dispatch(spinLoading());
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...introHotelEntity,
      ...values,
      setMedias: data,
      language: languageParam,
      ...(role.includes(AUTHORITIES.ADMIN) ? { hotelId: id } : {}),
    };

    const checkError = errorContent.some(item => {
      return Object.values(item).includes(true);
    })

    if (!checkError) {
      if (isNew) {
        await dispatch(createEntity(entity));
      } else {
        await dispatch(updateEntity(entity));
      }
    }
    dispatch(spinUnLoading());
  };

  const defaultValues = () =>
    isNew
      ? {
        createdAt: displayDefaultDateTime(),
        updatedAt: displayDefaultDateTime()
      }
      : {
        status: 'ACTIVATE',
        ...introHotelEntity,
        createdAt: convertDateTimeFromServer(introHotelEntity.createdAt),
        updatedAt: convertDateTimeFromServer(introHotelEntity.updatedAt)
      };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hotelTvApp.introHotel.home.createOrEditLabel" data-cy="IntroHotelCreateUpdateHeading">
            {isNew ? (
              <Translate contentKey="hotelTvApp.introHotel.home.createLabel">Create a IntroHotel</Translate>
            ) : (
              <Translate contentKey="hotelTvApp.introHotel.home.editLabel">Edit a IntroHotel</Translate>
            )}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label={translate('hotelTvApp.introHotel.title')}
                id="intro-hotel-title"
                name="title"
                data-cy="title"
                type="text"
                placeholder={translate('hotelTvApp.introHotel.title')}
              />
              <ValidatedField
                label={translate('hotelTvApp.introHotel.status')}
                id="intro-hotel-status"
                name="status"
                data-cy="status"
                type="select"
              >
                {statusValues.map(status => (
                  <option value={status} key={status}>
                    {translate('hotelTvApp.Status.' + status)}
                  </option>
                ))}
              </ValidatedField>
              {/* Setup Media */}
              <div>
                <div>{`${translate('entity.recomment.image')} ${translate('entity.recomment.dimension')}: 872*490`}</div>
                {data.map((item, index) => (
                  <div key={index} className="d-flex gap-3 justify-content-between align-items-center mb-3">
                    <div>{index + 1}.</div>
                    <div className="d-flex flex-column gap-3">
                      <input type="file" accept="image/*" onChange={e => handleImageChange(index, e.target.files[0])} />
                      {item.imgUrl && (
                        <img src={item.imgUrl} alt={`Preview ${index + 1}`} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                      )}
                      {errorContent[index]?.image && <p style={{ color: "red" }}>{translate('entity.recomment.image')}</p>}
                    </div>
                    <div className="flex-fill">
                      <p className="mb-1">{translate('hotelTvApp.introHotel.content')}</p>
                      <TextArea
                        value={item.textMedia}
                        autoSize={{ maxRows: 5, minRows: 3 }}
                        onChange={e => handleTextChange(index, e.target.value)}
                        placeholder={translate('hotelTvApp.introHotel.content')}
                        status={errorContent[index]?.content && 'error'}
                      />
                      {errorContent[index]?.content && <p style={{ color: "red" }}>{translate('entity.recomment.content')}</p>}
                    </div>
                    <Button
                      color="danger"
                      size="sm"
                      data-cy="entityDeleteButton"
                      style={{ height: 'fit-content', textAlign: 'center' }}
                      onClick={() => handleDelete(index)}
                    >
                      <FontAwesomeIcon icon="trash" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                      </span>
                    </Button>
                  </div>
                ))}
                <div className="mb-3 d-flex gap-3">
                  <Button onClick={addRow}>
                    <FontAwesomeIcon icon="add" /> <Translate contentKey="hotelTvApp.introHotel.add">Add</Translate>
                  </Button>
                </div>
              </div>
              <Button
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                onClick={() => history.back()}
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default IntroHotelUpdate;
