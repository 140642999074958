import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './pack.reducer';

export const PackDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const packEntity = useAppSelector(state => state.pack.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="packDetailsHeading">
          <Translate contentKey="hotelTvApp.pack.detail.title">Pack</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{packEntity.id}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="hotelTvApp.pack.status">Status</Translate>
            </span>
          </dt>
          <dd>{packEntity.status}</dd>
          <dt>
            <span id="effectiveDate">
              <Translate contentKey="hotelTvApp.pack.effectiveDate">Effective Date</Translate>
            </span>
          </dt>
          <dd>{packEntity.effectiveDate ? <TextFormat value={packEntity.effectiveDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="expiryDate">
              <Translate contentKey="hotelTvApp.pack.expiryDate">Expiry Date</Translate>
            </span>
          </dt>
          <dd>{packEntity.expiryDate ? <TextFormat value={packEntity.expiryDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="packageDuration">
              <Translate contentKey="hotelTvApp.pack.packageDuration">Package Duration</Translate>
            </span>
          </dt>
          <dd>{packEntity.packageDuration}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="hotelTvApp.pack.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{packEntity.createdAt ? <TextFormat value={packEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="hotelTvApp.pack.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{packEntity.updatedAt ? <TextFormat value={packEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="isDeleted">
              <Translate contentKey="hotelTvApp.pack.isDeleted">Is Deleted</Translate>
            </span>
          </dt>
          <dd>{packEntity.isDeleted ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="hotelTvApp.pack.hotelPack">Hotel Pack</Translate>
          </dt>
          <dd>{packEntity.hotelPack ? packEntity.hotelPack.id : ''}</dd>
          <dt>
            <Translate contentKey="hotelTvApp.pack.channelPackMaster">Channel Pack Master</Translate>
          </dt>
          <dd>{packEntity.channelPackMaster ? packEntity.channelPackMaster.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/pack" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/pack/${packEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PackDetail;
