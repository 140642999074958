import { useAppDispatch, useAppSelector } from "app/config/store";
import React, { useEffect, useState } from "react";
import { JhiItemCount, JhiPagination, Translate, getSortState, translate } from "react-jhipster";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { overridePaginationStateWithQueryParams } from "app/shared/util/entity-utils";
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from "app/shared/util/pagination.constants";
import { getEntities } from "app/entities/hotel/hotel-extend.reducer";
import { useDebounce } from "app/utils/customHooks/useDebounce";
import InputSearch from "../input-search";

const SelectHotelPage = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const hotelList = useAppSelector(state => state.hotelExtend.entities);
    const loading = useAppSelector(state => state.hotelExtend.loading);
    const totalItems = useAppSelector(state => state.hotelExtend.totalItems);
    const [textSearch, setTextSearch] = useState("");
    const debouncedValue = useDebounce<string>(textSearch, 500)
    const [paginationState, setPaginationState] = useState(
        overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
    );

    const getAllEntities = () => {
        dispatch(
            getEntities({
                page: paginationState.activePage - 1,
                size: paginationState.itemsPerPage,
                sort: `${paginationState.sort},${paginationState.order}`,
                nameSearch: textSearch
            })
        );
    };

    const sortEntities = () => {
        getAllEntities();
        const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
        if (location.search !== endURL) {
            navigate(`${location.pathname}${endURL}`);
        }
    };

    useEffect(() => {
        sortEntities();
    }, [paginationState.activePage, paginationState.order, paginationState.sort, debouncedValue]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const page = params.get('page');
        const sort = params.get(SORT);
        if (page && sort) {
            const sortSplit = sort.split(',');
            setPaginationState({
                ...paginationState,
                activePage: +page,
                sort: sortSplit[0],
                order: sortSplit[1]
            });
        }
    }, [location.search]);

    const sort = p => () => {
        setPaginationState({
            ...paginationState,
            order: paginationState.order === ASC ? DESC : ASC,
            sort: p
        });
    };

    const handlePagination = currentPage =>
        setPaginationState({
            ...paginationState,
            activePage: currentPage
        });

    const handleSyncList = () => {
        sortEntities();
    };

    return (
        <div>
            <h2>
                <Translate contentKey="global.menu.selectHotel.title">Select Hotel</Translate>
                <div className="d-flex justify-content-between align-items-center my-3">
                    <div className="w-25">
                        <InputSearch
                            name={'textSearch'}
                            onChange={(e) => setTextSearch(e.target.value)}
                        />
                    </div>
                    <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
                        <FontAwesomeIcon icon="sync" spin={loading} />{' '}
                        <Translate contentKey="hotelTvApp.apiKey.home.refreshListLabel">Refresh List</Translate>
                    </Button>
                </div>
            </h2>
            <div>
                <div className="mb-4">{translate("global.menu.selectHotel.description")}</div>
                {hotelList && hotelList.length > 0 ? (
                    <Table responsive className="table table-hover">
                        <thead>
                            <tr>
                                <th className="hand" onClick={sort("stt")}>
                                    <Translate contentKey="hotelTvApp.apiKey.stt">STT</Translate> <FontAwesomeIcon icon="sort" />
                                </th>
                                <th className="hand" onClick={sort("name")}>
                                    <Translate contentKey="hotelTvApp.hotel.name">Hotel</Translate> <FontAwesomeIcon icon="sort" />
                                </th>
                                <th className="hand" onClick={sort("idHotel")}>
                                    <Translate contentKey={"hotelTvApp.hotel.idHotel"}>ID Hotel</Translate> <FontAwesomeIcon icon="sort" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {hotelList.map((hotel, i) => (
                                <tr
                                    key={`entity-${i}`}
                                    data-cy="entityTable"
                                    onClick={() => navigate(`hotel/${hotel.id}`)}
                                    className="cursor-pointer"
                                >
                                    <td>{paginationState.itemsPerPage * (paginationState.activePage - 1) + i + 1}</td>
                                    <td>{hotel.name}</td>
                                    <td>{hotel.idHotel}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    !loading && (
                        <div className="alert alert-warning">
                            <Translate contentKey="hotelTvApp.hotel.home.notFound">No Hotels found</Translate>
                        </div>
                    )
                )}
            </div>
            {totalItems ? (
                <div className={hotelList && hotelList.length > 0 ? '' : 'd-none'}>
                    <div className="justify-content-center d-flex">
                        <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
                    </div>
                    <div className="justify-content-center d-flex">
                        <JhiPagination
                            activePage={paginationState.activePage}
                            onSelect={handlePagination}
                            maxButtons={5}
                            itemsPerPage={paginationState.itemsPerPage}
                            totalItems={totalItems}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default SelectHotelPage;