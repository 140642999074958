import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IUserInformation, defaultValue } from 'app/shared/model/user-information.model';

type TypeMultiDelete = {
  listId: string[];
}

const initialState: EntityState<IUserInformation> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrlExtend = 'api/user-informations-extend';

// Actions

export const getEntities = createAsyncThunk(
  'userInformationExtend/fetch_entity_list',
  async ({ page, size, sort, nameSearch }: IQueryParams & {nameSearch?: string}) => {
    const requestUrl = `${apiUrlExtend}${sort ? `?page=${page}&size=${size}&sort=${sort}&nameSearch=${nameSearch}&` : '?'}cacheBuster=${new Date().getTime()}`;
    return axios.get<IUserInformation[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'userInformationExtend/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrlExtend}/${id}`;
    return axios.get<IUserInformation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getInformationEntity = createAsyncThunk(
  'userInformationExtend/fetch_information_entity',
  async () => {
    const requestUrl = `${apiUrlExtend}/information`;
    const result = axios.get(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
)

export const createEntity = createAsyncThunk(
  'userInformationExtend/create_entity',
  async (entity: IUserInformation, thunkAPI) => {
    const result = await axios.post<IUserInformation>(apiUrlExtend, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'userInformationExtend/update_entity',
  async (entity: IUserInformation, thunkAPI) => {
    const result = await axios.put<IUserInformation>(`${apiUrlExtend}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'userInformationExtend/partial_update_entity',
  async (entity: IUserInformation, thunkAPI) => {
    const result = await axios.patch<IUserInformation>(`${apiUrlExtend}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'userInformationExtend/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrlExtend}/${id}`;
    const result = await axios.delete<IUserInformation>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const multiDeleteUserInformation = createAsyncThunk(
  'userInformationExtend/multi_delete_user_information',
  async (params: TypeMultiDelete, thunkAPI) => {
    const requestUrl = `${apiUrlExtend}/delete-many`;
    const result = await axios.post<IUserInformation>(requestUrl, params);
    return result;
  },
  { serializeError: serializeAxiosError }
)

export const changePassword = createAsyncThunk(
  'userInformationExtend/change_password',
  async (params: {login: string, newPassword: string}, thunkAPI) => {
    const requestUrl = `${apiUrlExtend}/reset-password`;
    const result = await axios.post<IUserInformation>(requestUrl, params);
    return result;
  },
  { serializeError: serializeAxiosError }
)

// slice

export const UserInformationExtendSlice = createEntitySlice({
  name: 'userInformationExtend',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getInformationEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(multiDeleteUserInformation.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, changePassword, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getInformationEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, changePassword, partialUpdateEntity, deleteEntity, multiDeleteUserInformation), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = UserInformationExtendSlice.actions;

// Reducer
export default UserInformationExtendSlice.reducer;
