import { Attribute } from 'app/shared/model/enumerations/attribute.model';
import { Language } from 'app/shared/model/enumerations/language.model';
import { translate } from 'react-jhipster';
import { v4 as uuidv4 } from 'uuid';

export const convertMoney = (unit: Language) => {
  switch (unit) {
    case Language.EN:
      return 'USD';
    case Language.VN:
      return 'VND';
    case Language.JA:
      return 'JPY';
    default:
      return 'KRW';
  }
};

export const convertTypeServiceHotel = (type: Attribute) => {
  switch (type) {
    case Attribute.Enough:
      return translate('hotelTvApp.Attribute.Enough');
    default:
      return translate('hotelTvApp.Attribute.Not_Enough');
  }
};

/**
 *
 * @returns Three digit code
 */
export function generateUniqueThreeDigitCode() {
  const randomString = uuidv4().substring(0, 3);
  return randomString;
}

export function getFileName(url: string) {
  const nameFile = url.split('/');

  return nameFile[nameFile.length - 1];
}

export function handleDownloadFile(url: string | null, fileName: string) {
  if (!url) throw new Error('No url found');

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName as string;

  document.body.appendChild(link);

  link.click();

  link.parentNode?.removeChild(link);
  URL.revokeObjectURL(url);
}

export const convertMoneyInput = (numberString: string | null) => {
  if (numberString === null) {
    return null;
  }
  
  const valueWithoutCommas = numberString?.replace(/,/g, '');
  const formattedValue = valueWithoutCommas?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return formattedValue;
}

export const stringToNumberMoney = (numberString: string | null) => {
  if (numberString && numberString.length > 0) {
    return Number(numberString?.replace(/,/g, ''));
  }
  return numberString;
}