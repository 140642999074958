import { RuleObject } from 'antd/es/form';

const getHonorifics = (code: string) => {
  switch (code) {
    case 'vi':
      return { male: 'quý khách hàng', female: 'quý khách hàng' };
    case 'en':
      return { male: 'Sir', female: 'Madam' };
    case 'kr':
      return { male: '님', female: '님' };
    case 'ja':
      return { male: '様', female: '様' };
    default:
      return {};
  }
};

export const formatContentWelcomeByLanguage = (content: string, gender: number, name: string, code: string) => {
  const genderByLanguage = getHonorifics(code);

  return content.replace('[gender]', gender === 0 ? genderByLanguage.male as string : genderByLanguage.female as string).replace('[name]', name);
};

export const validateMessage = async (rule: RuleObject, value: string) => {
  const regex = /(\[gender\]).*(\[name\])|(\[name\]).*(\[gender\])/;
  if (!value || regex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Chuỗi phải chứa cả [gender] và [name]'));
};
