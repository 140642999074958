import dayjs from 'dayjs';
import { IApiKey } from 'app/shared/model/api-key.model';

export interface INotificationApiKey {
  id?: string;
  key?: string | null;
  uniqueKey?: string | null;
  author?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isUpdated?: boolean | null;
  isDeleted?: boolean | null;
  apikey?: IApiKey | null;
}

export const defaultValue: Readonly<INotificationApiKey> = {
  isUpdated: false,
  isDeleted: false,
};
