import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import Room from './room';
import UserInformation from './user-information';
import Welcome from './welcome';
import Contract from './contract';
import Pack from './pack';
import ChannelPackMaster from './channel-pack-master';
import Channel from './channel';
import ServiceHotel from './service-hotel';
import ApiKey from './api-key';
import MenuService from './menu-service';
import SeriesMovie from './series-movie';
import Hotel from './hotel';
import HotelPack from './hotel-pack';
import Device from './device';
import IntroHotel from './intro-hotel';
import LogoHotel from './logo-hotel';
import { EPath } from 'app/utils/constants/EPath';
import { Inform } from 'app/shared/model/enumerations/inform.model';
import NotificationApiKey from './notification-api-key';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="room/*" element={<Room />} />
        <Route path="user-information/*" element={<UserInformation />} />
        <Route path="welcome/*" element={<Welcome />} />
        <Route path="contract/*" element={<Contract />} />
        <Route path="pack/*" element={<Pack />} />
        <Route path={`${EPath.ChannelPackMaster}/*`} element={<ChannelPackMaster />} />
        <Route path="channel/*" element={<Channel />} />
        <Route path="service-hotel/*" element={<ServiceHotel />} />
        <Route path="api-key/*" element={<ApiKey />} />
        <Route path="device/*" element={<Device />} />
        <Route path={`${EPath.MenuService}/*`} element={<MenuService type="ABOUT_HOTEL"/>} />
        <Route path={`${EPath.ListCategoryMovie}/*`} element={<MenuService type={Inform.MOVIE}/>} />
        <Route path="series-movie/*" element={<SeriesMovie />} />
        <Route path="hotel/*" element={<Hotel />} />
        <Route path="hotel-pack/*" element={<HotelPack />} />
        <Route path="intro-hotel/*" element={<IntroHotel />} />
        <Route path="logo-hotel/*" element={<LogoHotel />} />
        <Route path={`${EPath.Noti}/*`} element={<NotificationApiKey />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
