import { Radio, RadioChangeEvent } from 'antd';
import { Language } from 'app/shared/model/enumerations/language.model';
import React from 'react';
import { Translate } from 'react-jhipster';
type TProps = {
  language: string;
  onChangeLanguage: (e: RadioChangeEvent) => void;
};
const ChooseLanguage = ({ language, onChangeLanguage }: TProps) => {
  return (
    <div className="d-flex align-items-center gap-3">
      <p className="mb-0">Choose language:</p>
      <Radio.Group value={language} onChange={onChangeLanguage} className="my-3">
        <Radio.Button value={Language.VN}>
          <Translate contentKey={`hotelTvApp.menuService.${Language.VN}`}>Vietnamese</Translate>
        </Radio.Button>
        <Radio.Button value={Language.EN}>
          <Translate contentKey={`hotelTvApp.menuService.${Language.EN}`}>English</Translate>
        </Radio.Button>
        <Radio.Button value={Language.JA}>
          <Translate contentKey={`hotelTvApp.menuService.${Language.JA}`}>Japanese</Translate>
        </Radio.Button>
        <Radio.Button value={Language.KO}>
          <Translate contentKey={`hotelTvApp.menuService.${Language.KO}`}>Korean</Translate>
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};
export default ChooseLanguage;
