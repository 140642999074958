import dayjs from 'dayjs';
import { IPrice } from 'app/shared/model/price.model';
import { IMenuService } from 'app/shared/model/menu-service.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { Attribute } from 'app/shared/model/enumerations/attribute.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface IServiceHotel {
  id?: string;
  name?: string | null;
  description?: string | null;
  status?: Status | null;
  type?: Attribute | null;
  amount?: number | null;
  priceSale?: number | null;
  price?: number | null;
  author?: string | null;
  unit?: Language | null;
  attributeNames?: string | null;
  videoUrl?: string | null;
  imageUrl?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  attributeDetails?: IPrice[] | null;
  menuService?: IMenuService | null;
}

export const defaultValue: Readonly<IServiceHotel> = {};
