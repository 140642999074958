import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Storage, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity } from './device.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

export const DeviceDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const { idDevice } = useParams<'idDevice'>();
  const role = Storage.session.get('role');
  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    role.includes(AUTHORITIES.ADMIN) ? dispatch(getEntity(idDevice)) : dispatch(getEntity(id))
    setLoadModal(true);
  }, []);

  const deviceEntity = useAppSelector(state => state.device.entity);
  const updateSuccess = useAppSelector(state => state.device.updateSuccess);

  const handleClose = () => {
    history.back();
  };

  useEffect(() => {
    if (updateSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccess]);

  const confirmDelete = async () => {
    dispatch(spinLoading());
    await dispatch(deleteEntity(deviceEntity.uniqueKey));
    dispatch(spinUnLoading());
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="apiKeyDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="hotelTvApp.apiKey.delete.question">
        <Translate contentKey="hotelTvApp.apiKey.delete.question" interpolate={{ id: deviceEntity.deviceId }}>
          Are you sure you want to delete this Device?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-apiKey" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeviceDeleteDialog;
