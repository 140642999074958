import { DeleteFilled, FileImageTwoTone } from '@ant-design/icons';
import { Upload, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IUploadFileExtend } from '../../entities/welcome/welcome-extend-update';

const MultipleUploadImage = ({ images, setImages, fileList, setFileList, slideShows, isNew, maxFile }) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [hoveredIndexFileList, setHoveredIndexFileList] = useState(-1);
  const labelStyle = {
    paddingBottom: '10px',
    color: '#374151',
    fontWeight: '600'
  };

  useEffect(() => {
    const addMore = [] as IUploadFileExtend[];
    if (isNew) {
      setImages(fileList as IUploadFileExtend[]);
    }
  }, [fileList]);

  const convertToImgUrl = (file: RcFile) => {
    return URL.createObjectURL(file as Blob);
  };
  const handleMouseEnter = index => {
    setHoveredIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };
  const handleMouseEnterFileList = index => {
    setHoveredIndexFileList(index);
  };
  const handleMouseLeaveFileList = () => {
    setHoveredIndexFileList(-1);
  };
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (newFileList && newFileList[0]?.type?.includes('image/')) {
      setFileList(newFileList);
    } else {
      toast.error('you must use image to update or create this slides');
    }
  };

  const handleRemoveImage = (event, index: number) => {
    event.preventDefault();
    const updatedItems = [...images];
    updatedItems.splice(index, 1);
    setImages(updatedItems);
    setFileList(updatedItems);
  };

  const getImgUrl = (data: IUploadFileExtend) => {
    if (isNew) {
      return convertToImgUrl(data.originFileObj as RcFile);
    } else {
      return data?.imgUrl;
    }
  };

  const deleteImage = (e, index: number) => {
    e.preventDefault();
    const updateFileList = [...fileList];
    updateFileList.splice(index, 1);
    setFileList(updateFileList);
  };

  return (
    <div>
      <div style={labelStyle}>
        <Upload.Dragger
          showUploadList={false}
          fileList={fileList}
          maxCount={images && images.length ? maxFile - images.length : maxFile}
          onChange={handleChange}
          listType="picture-circle"
          // customRequest={handleFileUpload}
          accept=".jpg, .jpeg, .png, .gif, .bmp, .webp, .tif, .tiff"
          multiple
        >
          <p className="ant-upload-drag-icon">
            <FileImageTwoTone  />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint" style={{ marginBottom: '5px' }}>
            Maximum upload file size: 10MB
          </p>
          <p className="ant-upload-hint">Supported media formats: jpeg, png, jpg, gif, svg</p>
          <p className="text-danger">Upload a maximum of {maxFile} files.</p>
        </Upload.Dragger>
      </div>
      <div className="wrapper_file">
        <div className="img-container">
          {images?.length > 0 && (
            <>
              <div className="list_container">
                <div className="wrapper_imgs">
                  <div className="list_img">
                    {images?.map((e: any, k: number) => (
                      <div key={k} onMouseEnter={() => handleMouseEnter(k)} onMouseLeave={handleMouseLeave} className="img_container">
                        <span className="index_image">{k + 1}</span>
                        <img key={k} src={getImgUrl(images[k])} alt="Preview" className="img_item" />
                        <span className="img_name">{images[k]?.name?.length <= 10 ? images[k]?.name : images[k]?.name?.slice(0, 10)}</span>
                        {hoveredIndex === k && (
                          <DeleteFilled
                            className="trash_icon"
                            onClick={(event: React.MouseEvent) => handleRemoveImage(event, k)}
                            
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {!isNew && (
          <div className="img-container">
            {fileList?.length > 0 && (
              <>
                <div className="list_container">
                  <div className="wrapper_imgs">
                    <div className="list_img">
                      {fileList?.map((e: any, k: number) => (
                        <div
                          onMouseEnter={() => handleMouseEnterFileList(k)}
                          onMouseLeave={handleMouseLeaveFileList}
                          className="img_container"
                          key={k}
                        >
                          <span className="index_image">{k + images?.length + 1}</span>
                          <img key={k} src={convertToImgUrl(e?.originFileObj)} alt="Preview" className="img_item" />
                          <span className="img_name">{e?.imgUrl?.slice(-10)}</span>
                          {hoveredIndexFileList === k && (
                            <DeleteFilled
                              className="trash_icon"
                              onClick={(event: React.MouseEvent) => deleteImage(event, k)}
                              
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(MultipleUploadImage);
