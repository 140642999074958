import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate, Storage } from 'react-jhipster';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { DeleteOutlined } from '@ant-design/icons';
import { Form, Input, Select, Space } from 'antd';
import UploadSingleFile from 'app/components/upload/UploadSingleFile';
import { AUTHORITIES, LANGUAGES_UNIT } from 'app/config/constants';
import { Attribute } from 'app/shared/model/enumerations/attribute.model';
import { getEntitiesExtend } from '../menu-service/menu-service-extend.reducer';
import { ETypeFile, uploadSingleFileS3 } from '../s3-upload/s3.upload';
import { IMedia, IMediaRequest, IResponsePayload } from '../welcome/welcome-extend-update';
import { createEntityExtend, updateEntityExtend } from './service-hotel-extend.reducer';
import { getEntity, reset } from './service-hotel.reducer';
import { CONST_CONVERSION, variables } from 'app/utils/constants/variables';
import { convertMoneyInput, stringToNumberMoney } from 'app/utils/helpers/func';
import { NumberFormatBase, NumericFormat } from 'react-number-format';
import "./style.scss";
import { FormProvider, useForm } from 'react-hook-form';
import CommonInput from 'app/components/CommonInput';
import CommonSelect from 'app/components/CommonSelect';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';

export const ServiceHotelUpdateExtend = () => {
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const [selectedType, setSelectedType] = useState('Not_Enough');
  const [url, setUrl] = useState('');
  const [media, setMedia] = useState<IMedia>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams<'id'>();
  const { idServiceHotel } = useParams<'idServiceHotel'>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const menuId = params.get('menuId');
  const language = params.get('language');
  const isNew = role.includes(AUTHORITIES.ADMIN) ? idServiceHotel === undefined : id === undefined;

  const menuServices = useAppSelector(state => state.menuServiceExtend.entities);
  const serviceHotelEntity = useAppSelector(state => state.serviceHotel.entity);
  const loading = useAppSelector(state => state.serviceHotelExtend.loading);
  const updating = useAppSelector(state => state.serviceHotelExtend.updating);
  const updateSuccess = useAppSelector(state => state.serviceHotel.updateSuccess);
  const updateSuccessExtend = useAppSelector(state => state.serviceHotelExtend.updateSuccess);
  const attributeValues = Object.keys(Attribute);
  const [errorImage, setErrorImage] = useState(false);

  const methods = useForm({
    mode: 'onSubmit', criteriaMode: 'firstError', reValidateMode: 'onChange'
  });

  const handleClose = () => {
    history.back();
  };

  const handleTypeChange = event => {
    setSelectedType(event);
    form.setFieldsValue({
      attributeDetails: defaultAttributeDetails
    });
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      role.includes(AUTHORITIES.ADMIN) ? dispatch(getEntity(idServiceHotel)) : dispatch(getEntity(id))
    }
    dispatch(getEntitiesExtend({ language: language, hotelId: id }))
  }, [isNew, id]);

  useEffect(() => {
    if (updateSuccess || updateSuccessExtend) {
      handleClose();
    }
  }, [updateSuccess, updateSuccessExtend]);

  useEffect(() => {
    if (media) {
      setErrorImage((media.image?.size / CONST_CONVERSION.VALUE) > variables.IMAGE_SIZE);
    }
  }, [media]);

  const getFileUrl = async () => {
    const mediaObj = {} as IMediaRequest;
    const resp1 = (await dispatch(
      uploadSingleFileS3({ file: media.image.originFileObj as File, type: ETypeFile.image })
    )) as IResponsePayload;
    if (resp1?.payload?.imgUrl) {
      mediaObj.imageBackground = resp1?.payload?.imgUrl;
    }
    return mediaObj;
  };

  const saveEntity = async values => {
    dispatch(spinLoading());
    try {
      if (!errorImage) {
        await form.validateFields();
        const newMedia = media ? await getFileUrl() : '';
        const entity = isNew
          ? {
            ...serviceHotelEntity,
            ...values,
            unit: values.unit ? values.unit : null,
            language: language,
            menuService: menuServices.find(
              it => it.id.toString() === (serviceHotelEntity?.menuService?.id ? serviceHotelEntity.menuService.id : menuId)
            ),
            imageUrl: newMedia ? newMedia.imageBackground : url,
            price: stringToNumberMoney(values.price),
            priceSale: values.priceSale ? stringToNumberMoney(values.priceSale) : null,
          }
          : {
            ...serviceHotelEntity,
            ...values,
            language: language,
            imageUrl: newMedia ? newMedia.imageBackground : url,
            unit: values.unit ? values.unit : null,
            menuService: serviceHotelEntity?.menuService,
            price: stringToNumberMoney(values.price),
            priceSale: values.priceSale && stringToNumberMoney(values.priceSale),
          };
        const newAttributeDetails = form.getFieldsValue().attributeDetails?.map((item) => (
          {
            ...item,
            attributePrice: stringToNumberMoney(item.attributePrice),
            attributePriceSale: stringToNumberMoney(item.attributePriceSale),
            attributeUnit: item.attributeUnit
          }
        ))
        const newEntity = { ...entity, attributeDetails: newAttributeDetails };
        if (isNew) {
          await dispatch(createEntityExtend(newEntity));
        } else {
          await dispatch(updateEntityExtend(newEntity));
        }
        dispatch(spinUnLoading());
      }
    } catch (err) {
      if (err.errorFields) {
        toast.error(err.errorFields[0].errors[0]);
      }
    } finally {
      dispatch(spinUnLoading());
    }
  };

  useEffect(() => {
    isNew
      ? methods.reset({
        ...serviceHotelEntity,
        type: selectedType,
        unit: LANGUAGES_UNIT[0].value
      })
      : methods.reset({
        sizeItem: 'M',
        ...serviceHotelEntity,
        type: serviceHotelEntity.type,
        imageUrl: serviceHotelEntity?.imageUrl,
        menuService: serviceHotelEntity?.menuService,
        price: convertMoneyInput(serviceHotelEntity?.price?.toString()),
        priceSale: convertMoneyInput(serviceHotelEntity?.priceSale?.toString()),
      });

    setUrl(serviceHotelEntity.imageUrl);
    setSelectedType(serviceHotelEntity.type);
    if (serviceHotelEntity?.attributeDetails) {
      const fieldsValue = serviceHotelEntity?.attributeDetails?.map(i => ({
        attributeName: i?.attributeName,
        attributePrice: convertMoneyInput(i?.attributePrice.toString()),
        attributePriceSale: i?.attributePriceSale,
        attributeUnit: i?.attributeUnit
      }));
      form.setFieldValue('attributeDetails', fieldsValue);
    } else {
      form.resetFields(['attributeDetails']);
    }
  }, [serviceHotelEntity]);

  const defaultAttributeDetails = [{ attributeName: null, attributePrice: null, attributePriceSale: null, attributeUnit: 'VN' }];

  useEffect(() => {
    if (isNew && selectedType === "Enough") {
      form.setFieldsValue({
        attributeDetails: defaultAttributeDetails
      });
    }
  }, [form, selectedType]);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hotelTvApp.serviceHotel.home.createOrEditLabel" data-cy="ServiceHotelCreateUpdateHeading">
            {isNew
              ? <Translate contentKey="hotelTvApp.serviceHotel.home.createLabel">Create a ServiceHotel</Translate>
              : <Translate contentKey="hotelTvApp.serviceHotel.home.editLabel">Edit a ServiceHotel</Translate>
            }
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(saveEntity)}>
                <CommonInput
                  name='name'
                  placeholder={translate('hotelTvApp.serviceHotel.name')}
                  type='text'
                  label={
                    <p style={{ margin: 0 }}>
                      {`${translate('hotelTvApp.serviceHotel.name')}`}
                      {<span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  rules={{
                    required: {
                      value: true,
                      message: translate('hotelTvApp.serviceHotel.message.missName')
                    }
                  }}
                />
                <CommonInput
                  name='description'
                  label={translate('hotelTvApp.serviceHotel.description')}
                  placeholder={translate('hotelTvApp.serviceHotel.description')}
                  type="textarea"
                  rows={5}
                />
                <div className='text-center mb-2'>{`${translate('entity.recomment.image')} ${translate('entity.recomment.dimension')}: 206*275`}</div>
                <UploadSingleFile
                  accept="image/*"
                  url={url}
                  isVideo={false}
                  isAudio={false}
                  media={media as IMedia}
                  isSingleImage={true}
                  setAudioOrVideoOrImage={setMedia}
                  isNew={isNew}
                  setUrl={setUrl}
                />
                {errorImage && <p style={{ color: "red", textAlign: "center" }}>{translate('entity.recomment.image')}</p>}
                <CommonSelect
                  name='type'
                  label={translate('hotelTvApp.serviceHotel.type')}
                  placeholder={translate('hotelTvApp.serviceHotel.type')}
                  options={attributeValues.map((attribute) => (
                    { key: attribute, value: attribute, label: translate('hotelTvApp.serviceHotel.' + attribute) }
                  ))}
                  onChange={handleTypeChange}
                />
                {selectedType === 'Enough' &&
                  <CommonInput
                    name='attributeNames'
                    type="text"
                    label={
                      <p style={{ margin: 0 }}>
                        {`${translate('hotelTvApp.serviceHotel.attributes')}`}
                        {<span style={{ color: '#FF7851' }}>*</span>}
                      </p>
                    }
                    placeholder={translate('hotelTvApp.serviceHotel.attributes')}
                    rules={{
                      required: {
                        value: selectedType === 'Enough',
                        message: translate('hotelTvApp.serviceHotel.message.missAttributeName')
                      },
                      maxLength: {
                        value: 30,
                        message: translate('hotelTvApp.serviceHotel.message.maxLengthAttribute')
                      }
                    }}
                  />
                }
                {selectedType === 'Enough' && (
                  <Form
                    name="dynamic_form_nest_item"
                    className={`${selectedType === 'Enough' ? '' : 'hidden'} w -100 form_hotel_service`}
                    form={form}
                    autoComplete="off"
                  >
                    <Form.List name="attributeDetails">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <Space
                              key={key}
                              style={{ display: 'flex', marginBottom: 8, justifyContent: 'space-between' }}
                              align="baseline"
                              className="service-hotel"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, 'attributeName']}
                                required
                                validateTrigger="onBlur"
                                rules={[
                                  { required: true, message: translate('hotelTvApp.serviceHotel.message.missName') },
                                  { max: 15, message: translate('hotelTvApp.serviceHotel.message.maxLength') }
                                ]}
                              >
                                <Input placeholder="Name" className="w-100" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'attributePrice']}
                                required
                                validateTrigger="onBlur"
                                rules={[
                                  { required: true, message: translate('hotelTvApp.serviceHotel.message.missPrice') },
                                  { pattern: /^[0-9.,]+$/, message: translate("entity.validation.patternPrice") }
                                ]}
                                className='custom-css-antd-input'
                              >
                                <Input
                                  placeholder='Price'
                                  onChange={(e) => {
                                    const convertData = convertMoneyInput(e.target.value);
                                    const newAttributeDetails = form.getFieldsValue().attributeDetails?.map((item, indexIn) => {
                                      if (index === indexIn) {
                                        return {
                                          ...item,
                                          attributePrice: convertData
                                        }
                                      } else {
                                        return {
                                          ...item
                                        }
                                      }
                                    })
                                    form.setFieldsValue({
                                      attributeDetails: newAttributeDetails
                                    });
                                  }}
                                />
                                {/* <NumericFormat
                                  thousandSeparator=","
                                  className={`ant-input ant-input-outlined w-100 ${errorPrice && 'ant-input-status-error'}`}
                                  placeholder="Price"
                                  aria-required="true"
                                  onBlur={(e) => { setErrorPrice(e.target.value.length === 0) }}
                                /> */}
                              </Form.Item>
                              <Form.Item {...restField} name={[name, 'attributePriceSale']} className='custom-css-antd-input'>
                                <NumericFormat
                                  thousandSeparator=","
                                  className="ant-input ant-input-outlined w-100"
                                  placeholder="Sale Price"
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'attributeUnit']}
                                rules={[{ required: true, message: translate('entity.validation.required') }]}
                              >
                                <Select
                                  style={{ width: 120 }}
                                  className="w-100"
                                  options={LANGUAGES_UNIT}
                                  placeholder="Unit"
                                />
                              </Form.Item>

                              {fields && fields.length > 1 && (
                                <DeleteOutlined
                                  style={{ color: 'red' }}
                                  onClick={() => remove(name)}
                                />
                              )}
                            </Space>
                          ))}
                          <Form.Item className='mb-3'>
                            <Button onClick={() => add()}>{translate('hotelTvApp.serviceHotel.addLine')}</Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form>
                )}
                {selectedType !== 'Enough' &&
                  <CommonInput
                    name="price"
                    type="text"
                    onChange={(e) => methods.setValue('price', convertMoneyInput(e))}
                    label={
                      <p style={{ margin: 0 }}>
                        {`${translate('hotelTvApp.serviceHotel.price')}`}
                        {<span style={{ color: '#FF7851' }}>*</span>}
                      </p>
                    }
                    placeholder={translate('hotelTvApp.serviceHotel.price')}
                    rules={{
                      required: {
                        value: true,
                        message: translate('hotelTvApp.serviceHotel.message.missPrice')
                      },
                      pattern: {
                        value: /^[0-9.,]+$/,
                        message: translate("entity.validation.patternPrice")
                      }
                    }}
                  />
                }
                {selectedType !== 'Enough' && <>
                  <CommonInput
                    name='priceSale'
                    type="text"
                    label={translate('hotelTvApp.serviceHotel.priceSale')}
                    placeholder={translate('hotelTvApp.serviceHotel.priceSale')}
                    onChange={(e) => methods.setValue('priceSale', convertMoneyInput(e))}
                    rules={{
                      pattern: {
                        value: /^[0-9.,]+$/,
                        message: translate("entity.validation.patternPrice")
                      }
                    }}
                  />
                  <CommonSelect
                    name='unit'
                    options={LANGUAGES_UNIT.map((l) => (
                      { key: l.value, value: l.value, label: l.label }
                    ))}
                    label={translate('hotelTvApp.serviceHotel.unit')}
                    placeholder={translate('hotelTvApp.serviceHotel.unit')}
                  />
                </>}
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => history.back()} replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </form>
            </FormProvider>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ServiceHotelUpdateExtend;
