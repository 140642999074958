import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadSingleFile from 'app/components/upload/UploadSingleFile';
import { LANGUAGES_UNIT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Status } from 'app/shared/model/enumerations/status.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ETypeFile, uploadSingleFileS3 } from '../s3-upload/s3.upload';
import { IMedia, IResponsePayload } from '../welcome/welcome-extend-update';
import { createEntity, getEntity, reset, updateEntity } from './contract-extend.reducer';
import { CONST_CONVERSION, variables } from 'app/utils/constants/variables';
import { getEntitiesRemaining as getHotelsRemaining } from '../hotel/hotel-extend.reducer';
import { cloneDeep } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import CommonInput from 'app/components/CommonInput';
import CommonSelect from 'app/components/CommonSelect';
import CommonDatePicker from 'app/components/CommonDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

export const ContractUpdateExtend = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const hotels = useAppSelector(state => state.hotelExtend.entities);
  const contractEntity = useAppSelector(state => state.contractExtend.entity);
  const loading = useAppSelector(state => state.contractExtend.loading);
  const updating = useAppSelector(state => state.contractExtend.updating);
  const updateSuccess = useAppSelector(state => state.contractExtend.updateSuccess);
  const [errorFile, setErrorFile] = useState(false);
  const [signDateCurrent, setSignDateCurrent] = useState<string | undefined>();
  const [activeDateCurrent, setActiveDateCurrent] = useState<string | undefined>();
  const [expireDateCurrent, setExpireDateCurrent] = useState<string | undefined>();
  const statusValues = Object.keys(Status);

  const methods = useForm({
    mode: 'onSubmit', criteriaMode: 'firstError', reValidateMode: 'onChange'
  });

  const [media, setMedia] = useState<IMedia>();
  const [fileUrl, setFileUrl] = useState('');

  const handleClose = () => {
    navigate('/contract' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id))
    }
    dispatch(getHotelsRemaining({ type: "all" }));
  }, []);

  useEffect(() => {
    setErrorFile((media?.all?.size / CONST_CONVERSION.VALUE) > variables.IMAGE_SIZE);
    !media?.all && setFileUrl("");
  }, [media])

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const getFileUrl = async () => {
    if (media?.all) {
      const resp1 = (await dispatch(
        uploadSingleFileS3({ file: media?.all?.originFileObj as File, type: ETypeFile.ALL })
      )) as IResponsePayload;

      return resp1?.payload?.imgUrl ?? '';
    }
  };

  const saveEntity = async values => {
    dispatch(spinLoading());
    if (!errorFile) {
      const newMedia = media?.all ? await getFileUrl() : '';

      values.signDate = signDateCurrent;
      values.activationDate = activeDateCurrent;
      values.expire = expireDateCurrent;

      const entity = {
        ...contractEntity,
        ...values,
        fileUpload: newMedia ? newMedia : fileUrl,
        hotel: hotels.find(it => it.id.toString() === values.hotel.toString())
      };

      if (isNew) {
        await dispatch(createEntity(entity));
      } else {
        await dispatch(updateEntity(entity));
      }
    }
    dispatch(spinUnLoading());
  };

  useEffect(() => {
    if (contractEntity?.fileUpload && !isNew) {
      setFileUrl(contractEntity?.fileUpload);
    } else {
      setFileUrl("")
    }
  }, [contractEntity?.fileUpload, isNew]);

  useEffect(() => {
    if (isNew) {
      methods.reset({
        unit: 'VN',
      })
    } else {
      methods.reset({
        ...contractEntity,
        signDate: dayjs(contractEntity.signDate),
        activationDate: dayjs(contractEntity.activationDate),
        expire: dayjs(contractEntity.expire),
        hotel: contractEntity.hotel?.id
      });
      setSignDateCurrent(contractEntity.signDate);
      setActiveDateCurrent(contractEntity.signDate);
      setExpireDateCurrent(contractEntity.expire);
    }
  }, [contractEntity]);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hotelTvApp.contract.home.createOrEditLabel" data-cy="ContractCreateUpdateHeading">
            <Translate contentKey="hotelTvApp.contract.home.createOrEditLabel">Create or edit a Contract</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(saveEntity)}>
                <CommonSelect
                  name='hotel'
                  label={
                    <p style={{ margin: 0 }}>
                      {`${translate('hotelTvApp.contract.hotel')}`}
                      {<span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  placeholder={translate('hotelTvApp.contract.hotel')}
                  options={
                    hotels
                      ? hotels.map(otherEntity => (
                        { key: otherEntity.id, value: otherEntity.id, label: `${otherEntity.name} (ID hotel: ${otherEntity.idHotel})` }
                      ))
                      : null
                  }
                  rules={{
                    required: {
                      value: true,
                      message: translate("hotelTvApp.contract.form.hotel.required")
                    }
                  }}
                />
                <CommonInput
                  name='contractName'
                  label={translate('hotelTvApp.contract.contractName')}
                  type="text"
                  placeholder={translate('hotelTvApp.contract.contractName')}
                />
                {!isNew && (
                  <CommonInput
                    name='contractCode'
                    label={translate('hotelTvApp.contract.contractCode')}
                    type="text"
                    placeholder={translate('hotelTvApp.contract.contractCode')}
                    disabled
                  />
                )}
                <CommonInput
                  name='contractNumber'
                  label={
                    <p style={{ margin: 0 }}>
                      {`${translate('hotelTvApp.contract.contractNumber')}`}
                      {<span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  type="text"
                  placeholder={translate('hotelTvApp.contract.contractNumber')}
                  rules={{
                    required: {
                      value: true,
                      message: translate('hotelTvApp.contract.message.missNumber')
                    }
                  }}
                />
                <CommonDatePicker
                  name='signDate'
                  label={translate('hotelTvApp.contract.signDate')}
                  placeholder={translate('hotelTvApp.contract.signDate')}
                  setEndTime={setSignDateCurrent}
                />
                <CommonDatePicker
                  name='activationDate'
                  label={translate('hotelTvApp.contract.activationDate')}
                  placeholder={translate('hotelTvApp.contract.activationDate')}
                  setEndTime={setActiveDateCurrent}
                />
                <CommonDatePicker
                  name='expire'
                  label={translate('hotelTvApp.contract.expire')}
                  placeholder={translate('hotelTvApp.contract.expire')}
                  minDate={dayjs(activeDateCurrent)}
                  setEndTime={setExpireDateCurrent}
                />
                <CommonInput
                  name='pretaxValue'
                  label={translate('hotelTvApp.contract.pretaxValue')}
                  type="number"
                  placeholder={translate('hotelTvApp.contract.pretaxValue')}
                />
                <CommonInput
                  name='tax'
                  label={translate('hotelTvApp.contract.tax')}
                  type="number"
                  placeholder={translate('hotelTvApp.contract.tax')}
                />
                <CommonInput
                  name='totalValue'
                  label={translate('hotelTvApp.contract.totalValue')}
                  type="number"
                  placeholder={translate('hotelTvApp.contract.totalValue')}
                />
                <CommonSelect
                  name='unit'
                  label={translate('hotelTvApp.contract.unit')}
                  placeholder={translate('hotelTvApp.contract.unit')}
                  options={LANGUAGES_UNIT.map(i => (
                    { key: i.value, value: i.value, label: i.label }
                  ))}
                />
                {!isNew &&
                  <CommonSelect
                    name='status'
                    label={translate('hotelTvApp.contract.status')}
                    placeholder={translate('hotelTvApp.contract.status')}
                    disabled
                    value={isNew
                      ? Status.ACTIVATE
                      : dayjs(displayDefaultDateTime()).isBefore(dayjs(methods.watch("expire")))
                        ? Status.ACTIVATE : Status.DEACTIVATE
                    }
                    options={statusValues.map(status => (
                      { key: status, value: status, label: translate('hotelTvApp.Status.' + status) }
                    ))}
                  />
                }
                <CommonInput
                  name='description'
                  label={translate('hotelTvApp.contract.description')}
                  type="textarea"
                  placeholder={translate('hotelTvApp.contract.description')}
                  rows={5}
                />
                <div className="text-center mb-2">
                  {`${translate('entity.recomment.image')}`}
                </div>
                <UploadSingleFile
                  accept="*"
                  url={fileUrl}
                  isVideo={false}
                  isAudio={false}
                  media={media as IMedia}
                  isFile={true}
                  isSingleImage={false}
                  setAudioOrVideoOrImage={setMedia}
                  isNew={isNew}
                />
                {errorFile && <p style={{ color: "red", textAlign: "center" }}>{translate('entity.recomment.image')}</p>}
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/contract" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </form>
            </FormProvider>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ContractUpdateExtend;
