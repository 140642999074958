import dayjs from 'dayjs';
import { IApiKey } from 'app/shared/model/api-key.model';
import { IHotelPack } from 'app/shared/model/hotel-pack.model';
import { IChannelPackMaster } from 'app/shared/model/channel-pack-master.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IPack {
  id?: string;
  status?: Status | null;
  effectiveDate?: string | null;
  expiryDate?: string | null;
  packageDuration?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isDeleted?: boolean | null;
  apiKeys?: IApiKey[] | null;
  hotelPack?: IHotelPack | null;
  channelPackMaster?: IChannelPackMaster | null;
}

export const defaultValue: Readonly<IPack> = {
  isDeleted: false,
};
