import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from 'antd';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { getEntity } from './hotel.reducer';

export const HotelDetailExtend = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();
  const hotelEntity = useAppSelector(state => state.hotel.entity);
  useEffect(() => {
    dispatch(getEntity(id as string));
  }, []);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="hotelDetailsHeading">
          <Translate contentKey="hotelTvApp.hotel.detail.title">Hotel</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">
              <Translate contentKey="hotelTvApp.hotel.name">Name</Translate>
            </span>
          </dt>
          <dd>{hotelEntity.name}</dd>
          <dt>
            <span id="companyName">
              <Translate contentKey="hotelTvApp.hotel.companyName">Company Name</Translate>
            </span>
          </dt>
          <dd>{hotelEntity.companyName}</dd>
          <dt>
            <span id="idHotel">
              <Translate contentKey="hotelTvApp.hotel.idHotel">Id Hotel</Translate>
            </span>
          </dt>
          <dd>{hotelEntity.idHotel}</dd>
          <dt>
            <span id="hotelAddress">
              <Translate contentKey="hotelTvApp.hotel.hotelAddress">Address</Translate>
            </span>
          </dt>
          <dd>{hotelEntity.hotelAddress}</dd>
          <dt>
            <span id="taxCode">
              <Translate contentKey="hotelTvApp.hotel.taxCode">Tax Code</Translate>
            </span>
          </dt>
          <dd>{hotelEntity.taxCode}</dd>
          <dt>
            <span id="website">
              <Translate contentKey="hotelTvApp.hotel.website">Website</Translate>
            </span>
          </dt>
          <dd>{hotelEntity.website}</dd>
          <dt>
            <span id="note">
              <Translate contentKey="hotelTvApp.hotel.note">Note</Translate>
            </span>
          </dt>
          <dd>{hotelEntity.description}</dd>
          <dt>
            <span id="phoneNumber">
              <Translate contentKey="hotelTvApp.hotel.phoneNumber">Phone Number</Translate>
            </span>
          </dt>
          <dd>{hotelEntity.phoneNumber}</dd>
          <dt>
            <span id="representative">
              <Translate contentKey="hotelTvApp.hotel.representative">Legal representative</Translate>
            </span>
          </dt>
          <dd>{hotelEntity.contact}</dd>
          <dt>
            <span id="position">
              <Translate contentKey="hotelTvApp.hotel.position">Position</Translate>
            </span>
          </dt>
          <dd>{hotelEntity.position}</dd>
          <dt>
            <span id="imageUrl">
              <Translate contentKey="hotelTvApp.hotel.imageHotel">Image</Translate>
            </span>
          </dt>
          <Image.PreviewGroup>
            <div className="d-flex gap-3 flex-wrap">
              {hotelEntity?.imageHotels?.map((img, i) => (
                <Image key={i} width={100} height={100} src={img?.imgUrl} />
              ))}
            </div>
          </Image.PreviewGroup>

          <dt>
            <span id="createdAt">
              <Translate contentKey="hotelTvApp.hotel.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{hotelEntity.createdAt ? <TextFormat value={hotelEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="hotelTvApp.hotel.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{hotelEntity.updatedAt ? <TextFormat value={hotelEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button tag={Link} to="/hotel" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/hotel/${hotelEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default HotelDetailExtend;
