import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serializeAxiosError } from "app/shared/reducers/reducer.utils";
import axios from "axios";

export type dataAuthority = {
    name: string;
}
const initialState = {
    data: [] as dataAuthority[]
}

const apiUrl = 'api/authority'

// Actions

export const getAuthoritiesHotelAsync = createAsyncThunk(
    'authorities/get',
    async () => {
        const response = await axios.get<any>(apiUrl);
        return response;
    },
    { serializeError: serializeAxiosError }
)

export const StatisticalSlice = createSlice({
    name: "authorities",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAuthoritiesHotelAsync.fulfilled, (state, action) => {
                state.data = action.payload.data;
            })
    },
});

export default StatisticalSlice.reducer;