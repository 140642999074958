import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import React from 'react';
import { Route } from 'react-router-dom';
import SeriesMovieDeleteDialogExtend from './series-movie-delete-dialog-extend';
import SeriesMovieDetailExtend from './series-movie-detail-extend';
import SeriesMovieExtend from './series-movie-extend';
import SeriesMovieUpdateExtend from './series-movie-update-extend';

const SeriesMovieRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SeriesMovieExtend />} />
    <Route path="new" element={<SeriesMovieUpdateExtend />} />
    <Route path=":id">
      <Route index element={<SeriesMovieDetailExtend />} />
      <Route path="edit" element={<SeriesMovieUpdateExtend />} />
      <Route path="delete" element={<SeriesMovieDeleteDialogExtend />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SeriesMovieRoutes;
