import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import UserInformationExtend from './user-information-extend';
import UserInformationUpdateExtend from './user-information-update-extend';
import { Storage } from 'react-jhipster';
import EmployeeHotelUpdate from './employee-hotel-update';
import UserInformationDetailExtend from './user-information-detail-extend';
import UserInformationDeleteDialogExtend from './user-information-delete-dialog-extend';
import { AUTHORITIES } from 'app/config/constants';
import SelectHotelPage from 'app/components/select-hotel-page/SelectHotelPage';

const UserInformationRoutes = () => {
  const role = Storage.session.get('role');

  return (
    <ErrorBoundaryRoutes>
      <Route index element={<UserInformationExtend />} />
      {role.includes(AUTHORITIES.ADMIN)
        ? <>
          <Route path="new" element={<UserInformationUpdateExtend />} />
        </>
        : <>
          <Route path="new" element={<EmployeeHotelUpdate />} />
        </>
      }
      <Route path=":id">
        <Route index element={<UserInformationDetailExtend />} />
        {role.includes(AUTHORITIES.ADMIN)
          ? <>
            <Route path="edit" element={<UserInformationUpdateExtend />} />
          </>
          : <>
            <Route path="edit" element={<EmployeeHotelUpdate />} />
          </>
        }
        <Route path="delete" element={<UserInformationDeleteDialogExtend />} />
      </Route>
    </ErrorBoundaryRoutes>
  )
}

export default UserInformationRoutes;
