import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Badge } from 'reactstrap';
import { Translate, TextFormat, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './channel.reducer';
import { Status } from 'app/shared/model/enumerations/status.model';

export const ChannelDetailExtend = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const channelEntity = useAppSelector(state => state.channel.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="channelDetailsHeading">
          <Translate contentKey="hotelTvApp.channel.detail.title">Channel</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">
              <Translate contentKey="hotelTvApp.channel.name">Name</Translate>
            </span>
          </dt>
          <dd>{channelEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="hotelTvApp.channel.description">Description</Translate>
            </span>
          </dt>
          <dd>{channelEntity.description}</dd>
          <dt>
            <span id="thumbnailUrl">
              <Translate contentKey="hotelTvApp.channel.thumbnailUrl">Thumbnail Url</Translate>
            </span>
          </dt>
          <dd>
            {channelEntity.thumbnailUrl && (
              <img src={channelEntity.thumbnailUrl} width={300} height={200} alt="image-channel" style={{ objectFit: 'cover' }} />
            )}
          </dd>
          <dt>
            <span id="status">
              <Translate contentKey="hotelTvApp.channel.status">Status</Translate>
            </span>
          </dt>
          <dd>{channelEntity.status
            ? channelEntity.status === Status.ACTIVATE
              ? <Badge color="primary">{translate("global.form.active")}</Badge>
              : <Badge color="danger">{translate("global.form.deactive")}</Badge>
            : null
          }
          </dd>
          <dt>
            <span id="videoUrl">
              <Translate contentKey="hotelTvApp.channel.videoUrl">Video Url</Translate>
            </span>
          </dt>
          <dd>{channelEntity.videoUrl}</dd>
          <dt>
            <span id="author">
              <Translate contentKey="hotelTvApp.channel.author">Author</Translate>
            </span>
          </dt>
          <dd>{channelEntity.author}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="hotelTvApp.channel.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{channelEntity.createdAt ? <TextFormat value={channelEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="hotelTvApp.channel.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{channelEntity.updatedAt ? <TextFormat value={channelEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button tag={Link} to="/channel" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/channel/${channelEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ChannelDetailExtend;
