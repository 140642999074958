import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import ContractDeleteDialogExtend from './contract-delete-dialog-extend';
import ContractDetailExtend from './contract-detail-extend';
import ContractExtend from './contract-extend';
import ContractUpdateExtend from './contract-update-extend';

const ContractRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ContractExtend />} />
    <Route path="new" element={<ContractUpdateExtend />} />
    <Route path=":id">
      <Route index element={<ContractDetailExtend />} />
      <Route path="edit" element={<ContractUpdateExtend />} />
      <Route path="delete" element={<ContractDeleteDialogExtend />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ContractRoutes;
