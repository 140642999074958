import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './service-hotel.reducer';
import { convertMoney, convertMoneyInput } from 'app/utils/helpers/func';
import { AUTHORITIES } from 'app/config/constants';
import { EPath } from 'app/utils/constants/EPath';

export const ServiceHotelDetailExtend = () => {
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id } = useParams<'id'>();
  const { idServiceHotel } = useParams<'idServiceHotel'>();

  useEffect(() => {
    role.includes(AUTHORITIES.ADMIN) ? dispatch(getEntity(idServiceHotel)) : dispatch(getEntity(id))
  }, []);

  const serviceHotelEntity = useAppSelector(state => state.serviceHotel.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="serviceHotelDetailsHeading">
          <Translate contentKey="hotelTvApp.serviceHotel.detail.title">ServiceHotel</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">
              <Translate contentKey="hotelTvApp.serviceHotel.name">Name</Translate>
            </span>
          </dt>
          <dd>{serviceHotelEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="hotelTvApp.serviceHotel.description">Description</Translate>
            </span>
          </dt>
          <dd>{serviceHotelEntity.description}</dd>
          <div className={serviceHotelEntity?.type === 'Enough' ? 'hidden' : ''}>
            <dt>
              <span id="price">
                <Translate contentKey="hotelTvApp.serviceHotel.price">Price</Translate>
              </span>
            </dt>
            <dd>{convertMoneyInput(serviceHotelEntity?.price?.toString())} {convertMoney(serviceHotelEntity.unit)}</dd>
            <dt>
              <span id="salePrice">
                <Translate contentKey="hotelTvApp.serviceHotel.priceSale">Sale Price</Translate>
              </span>
            </dt>
            <dd>{convertMoneyInput(serviceHotelEntity?.priceSale?.toString())} {convertMoney(serviceHotelEntity.unit)}</dd>
          </div>

          {serviceHotelEntity.imageUrl && (
            <>
              <dt>
                <span id="imageUrl">
                  <Translate contentKey="hotelTvApp.serviceHotel.imageUrl">Image Url</Translate>
                </span>
              </dt>
              <img
                src={serviceHotelEntity.imageUrl}
                alt="img"
                width={300}
                height={200}
                style={{
                  objectFit: 'cover'
                }}
              />
            </>
          )}

          <div className={serviceHotelEntity?.type === 'Enough' ? '' : 'hidden'}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{serviceHotelEntity?.attributeNames}</th>
                  <th scope="col">
                    <Translate contentKey="hotelTvApp.serviceHotel.price">Price</Translate>
                  </th>
                  <th scope="col">
                    <Translate contentKey="hotelTvApp.serviceHotel.priceSale">Sale Price</Translate>
                  </th>
                  <th scope="col">
                    <Translate contentKey="hotelTvApp.serviceHotel.unit">Unit</Translate>
                  </th>
                </tr>
              </thead>
              <tbody>
                {serviceHotelEntity?.attributeDetails?.map((items, index) => (
                  <tr key={index}>
                    <td>{items?.attributeName}</td>
                    <td>{items?.attributePrice}</td>
                    <td>{items?.attributePriceSale}</td>
                    <td>{convertMoney(items?.attributeUnit)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <dt>
            <span id="author">
              <Translate contentKey="hotelTvApp.serviceHotel.author">Author</Translate>
            </span>
          </dt>
          <dd>{serviceHotelEntity.author}</dd>
        </dl>
        <Button onClick={() => history.back()} replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          hidden={role.includes(AUTHORITIES.MONITOR)}
          tag={Link}
          to={role.includes(AUTHORITIES.ADMIN)
            ? `${EPath.ServiceHotelADMIN}/${id}/${serviceHotelEntity.id}/edit?language=${serviceHotelEntity?.menuService?.language}`
            : `${EPath.ServiceHotel}/${serviceHotelEntity.id}/edit`
          }
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ServiceHotelDetailExtend;
