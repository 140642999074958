import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate, Storage } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity } from './menu-service.reducer';
import { deleteEntityExtend } from './menu-service-extend.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { TMenuService } from '.';
import { Inform } from 'app/shared/model/enumerations/inform.model';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

export const MenuServiceDeleteDialogExtend = (props: TMenuService) => {
  const { type } = props;
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const { idMenuService } = useParams<'idMenuService'>();

  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    role.includes(AUTHORITIES.ADMIN)
      ? (type === Inform.ABOUT_HOTEL ? dispatch(getEntity(idMenuService)) : dispatch(getEntity(id))) 
      : dispatch(getEntity(id))
    setLoadModal(true);
  }, []);

  const menuServiceEntity = useAppSelector(state => state.menuService.entity);
  const updateSuccess = useAppSelector(state => state.menuServiceExtend.updateSuccess);

  const handleClose = () => {
    history.back();
  };
  useEffect(() => {
    if (updateSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccess]);

  const confirmDelete = async () => {
    dispatch(spinLoading());
    await dispatch(deleteEntityExtend(menuServiceEntity.id));
    dispatch(spinUnLoading());
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="menuServiceDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="hotelTvApp.menuService.delete.question">
        <Translate contentKey="hotelTvApp.menuService.delete.question" interpolate={{ id: menuServiceEntity.name }}>
          Are you sure you want to delete this MenuService?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-menuService" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MenuServiceDeleteDialogExtend;
