import { languages } from 'app/config/translation';
import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IMenuService, defaultValue } from 'app/shared/model/menu-service.model';
import { Language } from '../../shared/model/enumerations/language.model';

type TypeMultiDelete = {
  listId: string[];
}

const initialState: EntityState<IMenuService> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/menu-services-extend';

type TMenuServiceQueryParams = IQueryParams & {
  language?: string;
};
// Actions

export const getEntitiesExtend = createAsyncThunk(
  'menuServiceExtend/fetch_entity_list',
  async ({ page, size, sort, language, hotelId, nameSearch }: TMenuServiceQueryParams & {hotelId?: string; nameSearch?: string}) => {
    const requestUrl = `${apiUrl}${
      sort || language ? `?page=${page}&size=${size}&language=${language}&sort=${sort}${hotelId ? `&hotelId=${hotelId}`: ''}&nameSearch=${nameSearch}&` : '?'
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<IMenuService[]>(requestUrl);
  }
);

export const getEntityExtend = createAsyncThunk(
  'menuServiceExtend/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IMenuService>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntityExtend = createAsyncThunk(
  'menuServiceExtend/create_entity',
  async ({hotelId, ...entity}: IMenuService & {hotelId?: string}, thunkAPI) => {
    const requestUrl = `${apiUrl}?${hotelId ? `hotelId=${hotelId}` : ''}`
    const result = await axios.post<IMenuService>(requestUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntityExtend = createAsyncThunk(
  'menuServiceExtend/update_entity',
  async (entity: IMenuService, thunkAPI) => {
    const result = await axios.put<IMenuService>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntityExtend = createAsyncThunk(
  'menuServiceExtend/partial_update_entity',
  async (entity: IMenuService, thunkAPI) => {
    const result = await axios.patch<IMenuService>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntityExtend = createAsyncThunk(
  'menuServiceExtend/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IMenuService>(requestUrl);
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const multiDeleteMenuService = createAsyncThunk(
  'menuServiceExtend/multi_delete_menu_service_extend',
  async (params: TypeMultiDelete, thunkAPI) => {
    const requestUrl = `${apiUrl}/delete-many`;
    const result = await axios.post(requestUrl, params);
    return result;
  },
  { serializeError: serializeAxiosError }
)

// slice

export const MenuServiceSliceExtend = createEntitySlice({
  name: 'menuServiceExtend',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntityExtend.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntityExtend.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(multiDeleteMenuService.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntitiesExtend), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          updateSuccess: false,
          totalItems: parseInt(headers['x-total-count'], 10)
        };
      })
      .addMatcher(isFulfilled(createEntityExtend, updateEntityExtend, partialUpdateEntityExtend), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntitiesExtend, getEntityExtend), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntityExtend, updateEntityExtend, partialUpdateEntityExtend, deleteEntityExtend, multiDeleteMenuService), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  }
});

export const { reset } = MenuServiceSliceExtend.actions;

// Reducer
export default MenuServiceSliceExtend.reducer;
