import './home.scss';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { translate } from 'react-jhipster';
import { getInformationEntity } from 'app/entities/user-information/user-information-extend.reducer';

export const Home = () => {
  const dispatch = useAppDispatch();
  const userLogin = useAppSelector(state => state.userInformationExtend.entity);

  useEffect(() => {
    dispatch(getInformationEntity());
  }, []);

  return (
    <h2 className="text-center mt-5"
    >{translate("home.welcome")}, {userLogin.userDTO?.login}</h2>
  );
};

export default Home;
