import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { JhiItemCount, JhiPagination, TextFormat, Translate, ValidatedField, getSortState, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, Table } from 'reactstrap';
import { getEntities, multiDeleteWelcome } from './welcome-extend.reducer';
import ModalConfirm from 'app/components/modal-confirm/ModalConfirm';
import { useDebounce } from 'app/utils/customHooks/useDebounce';
import InputSearch from 'app/components/input-search';
import { Status } from 'app/shared/model/enumerations/status.model';
import { EPath } from 'app/utils/constants/EPath';
import { Storage } from 'react-jhipster';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';
import { toast } from 'react-toastify';

export const WelcomeExtend = () => {
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(pageLocation, ITEMS_PER_PAGE, 'createdAt', 'desc'), pageLocation.search)
  );

  const welcomeList = useAppSelector(state => state.welcome.entities);
  const loading = useAppSelector(state => state.welcome.loading);
  const totalItems = useAppSelector(state => state.welcome.totalItems);

  const [textSearch, setTextSearch] = useState("");
  const debouncedValue = useDebounce<string>(textSearch, 500)
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const openConfirmDelete = () => {
    setOpenConfirm(!openConfirm);
  };

  const onSubmitConfirmDelete = async () => {
    dispatch(spinLoading());
    try {
      const params = {
        listId: selectedRows
      };
      await dispatch(multiDeleteWelcome(params));
      setSelectedRows([]);
      toast.success("Success");
    } catch (err) {
      toast.error(err.errorFields[0].errors[0]);
    } finally {
      openConfirmDelete();
      dispatch(spinUnLoading());
      sortEntities();
    }
  };

  const selectRow = (id: string): void => {
    setSelectedRows([...selectedRows, id]);
  };

  const deleteRow = (id: string): void => {
    setSelectedRows(selectedRows.filter((i) => i !== id));
  };

  useEffect(() => {
    (selectedRows.length > 0 && selectedRows.length === welcomeList.length) ? setSelectAll(true) : setSelectAll(false);
  }, [selectedRows]);

  useEffect(() => {
    selectAll ? setSelectedRows(welcomeList.map(welcome => welcome.id)) : null;
    if (!selectAll && selectedRows.length === welcomeList.length) {
      setSelectedRows([]);
    }
  }, [selectAll]);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        ...(role.includes(AUTHORITIES.ADMIN) ? { hotelId: id } : {}),
        nameSearch: textSearch
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=createdAt,desc`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, debouncedValue]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="welcome-heading" data-cy="WelcomeHeading">
        <div className="d-flex gap-3 align-items-center">
          {role.includes(AUTHORITIES.ADMIN)
            ? <FontAwesomeIcon icon="arrow-left" onClick={() => navigate(EPath.Welcome)} />
            : null
          }
          <Translate contentKey="hotelTvApp.welcome.home.title">Welcomes</Translate>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="w-25">
            <InputSearch
              name={'textSearch'}
              onChange={(e) => setTextSearch(e.target.value)}
            />
          </div>
          <div>
            <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              <Translate contentKey="hotelTvApp.welcome.home.refreshListLabel">Refresh List</Translate>
            </Button>
            {!role.includes(AUTHORITIES.MONITOR)
              ? (<Link
                to={role.includes(AUTHORITIES.ADMIN)
                  ? `${EPath.WelcomeADMIN}/${id}/new`
                  : `${EPath.Welcome}/new`
                }
                className="btn btn-primary jh-create-entity"
                id="jh-create-entity"
                data-cy="entityCreateButton"
              >
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="hotelTvApp.welcome.home.createLabel">Create new Welcome</Translate>
              </Link>)
              : null
            }
          </div>
        </div>
      </h2>
      <div className="table-responsive">
        <div className="position-absolute mt-2">
          {selectedRows.length > 0
            ? <div className="d-flex align-items-center fs-6 gap-2">
              <div style={{ minWidth: "120px" }}>
                {translate("global.multiDelete.titleSelected")}: {selectedRows.length}
              </div>
              {!role.includes(AUTHORITIES.MONITOR)
                ? (<Button size="sm" color="danger" onClick={openConfirmDelete}>
                  <FontAwesomeIcon icon="trash" />{' '}
                </Button>)
                : null
              }
            </div>
            : null
          }
        </div>
        {welcomeList && welcomeList.length > 0 ? (
          <Table responsive className="table table-hover mt-5">
            <thead>
              <tr className="border-b-1">
                <th className="hand position-sticky start-0 bg-white">
                  <ValidatedField
                    className="checkbox-select-table-row"
                    type="checkbox"
                    name={"checkboxAllRow"}
                    checked={selectAll}
                    onChange={(e) => {
                      const { checked } = e.target;
                      checked ? setSelectAll(true) : setSelectAll(false);
                    }}
                  />
                </th>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hotelTvApp.welcome.stt">STT</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('title')}>
                  <Translate contentKey="hotelTvApp.welcome.title">Title</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('title')} />
                </th>
                <th className="hand" onClick={sort('type')}>
                  <Translate contentKey="hotelTvApp.welcome.typeDisplay">Type Display</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('type')} />
                </th>
                <th className="hand text-center" onClick={sort('status')}>
                  <Translate contentKey="hotelTvApp.welcome.status">Status</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('status')} />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="hotelTvApp.welcome.createdAt">Created At</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('createdAt')} />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="hotelTvApp.welcome.updatedAt">Updated At</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('updatedAt')} />
                </th>
                <th className="hand" onClick={sort('author')}>
                  <Translate contentKey="hotelTvApp.welcome.author">Author</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('author')} />
                </th>
                <th className="hand text-center position-sticky end-0 bg-white">
                  <Translate contentKey="hotelTvApp.welcome.actions">Actions</Translate>
                </th>
              </tr>
            </thead>
            <tbody>
              {welcomeList.map((welcome, i) => (
                <tr
                  key={`entity-${i}`}
                  data-cy="entityTable"
                  className="cursor-pointer"
                  onClick={() => navigate(role.includes(AUTHORITIES.ADMIN)
                    ? `${EPath.WelcomeADMIN}/${id}/${welcome.id}`
                    : `${EPath.Welcome}/${welcome.id}`
                  )}
                >
                  <td className="position-sticky start-0 bg-white">
                    <div onClick={e => e.stopPropagation()}>
                      <ValidatedField
                        className="checkbox-select-table-row"
                        type="checkbox"
                        name={`welcome${i}`}
                        checked={selectedRows.includes(welcome.id)}
                        onChange={(e) => {
                          const { checked } = e.target;
                          if (checked) {
                            !selectedRows.includes(welcome.id) ? selectRow(welcome.id) : null
                          } else {
                            deleteRow(welcome.id);
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td className='stt'>{(paginationState.activePage) * paginationState.itemsPerPage + i + 1 - paginationState.itemsPerPage}</td>
                  <td className="custom-row-format-title">
                    <p className="custom-format-title">{welcome.title}</p>
                  </td>
                  <td className='data-row-sm'>
                    <Translate contentKey={`hotelTvApp.Type.${welcome.type}`} />
                  </td>
                  <td className='data-row-sm text-center'>{welcome.status === Status.ACTIVATE
                    ? <Badge color="primary">{translate("global.form.active")}</Badge>
                    : <Badge color="danger">{translate("global.form.deactive")}</Badge>}
                  </td>
                  <td className='data-row-sm'>{welcome.createdAt ? <TextFormat type="date" value={welcome.createdAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td className='data-row-sm'>{welcome.updatedAt ? <TextFormat type="date" value={welcome.updatedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td className='data-row-lg'>{welcome.author}</td>
                  <td className="text-end position-sticky end-0 bg-white">
                    <div className="btn-group flex-btn-group-container" onClick={e => e.stopPropagation()}>
                      <Button
                        tag={Link}
                        to={role.includes(AUTHORITIES.ADMIN)
                          ? `${EPath.WelcomeADMIN}/${id}/${welcome.id}`
                          : `${EPath.Welcome}/${welcome.id}`
                        }
                        color="info" size="sm"
                        data-cy="entityDetailsButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        hidden={role.includes(AUTHORITIES.MONITOR)}
                        tag={Link}
                        to={role.includes(AUTHORITIES.ADMIN)
                          ? `${EPath.WelcomeADMIN}/${id}/${welcome.id}/edit`
                          : `${EPath.Welcome}/${welcome.id}/edit`
                        }
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        hidden={role.includes(AUTHORITIES.MONITOR)}
                        tag={Link}
                        to={role.includes(AUTHORITIES.ADMIN)
                          ? `${EPath.WelcomeADMIN}/${id}/${welcome.id}/delete`
                          : `${EPath.Welcome}/${welcome.id}/delete`
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hotelTvApp.welcome.home.notFound">No Welcomes found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={welcomeList && welcomeList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <ModalConfirm
        isOpen={openConfirm}
        toggle={openConfirmDelete}
        title={translate("global.multiDelete.confirm.titleConfirm")}
        onSubmit={onSubmitConfirmDelete}
      >
        {`
          ${translate("global.multiDelete.confirm.contentConfirm")} 
          ${selectedRows.length} 
          ${translate("global.multiDelete.confirm.dataConfirm")}?
        `}
      </ModalConfirm>
    </div>
  );
};

export default WelcomeExtend;
