export const EPath = {
  ChannelPackMaster: '/service-pack',
  ChannelPackMasterADMIN: '/service-pack/hotel',
  Pack: '/pack',
  Room: '/room',
  UserInformation: '/user-information',
  UserInformationADMIN: '/user-information/hotel',
  Welcome: '/welcome',
  WelcomeADMIN: '/welcome/hotel',
  Contract: '/contract',
  Channel: '/channel',
  Hotel: '/hotel',
  ServiceHotel: '/service-hotel',
  ServiceHotelADMIN: '/service-hotel/hotel',
  APIKey: '/api-key',
  MenuService: '/menu-service',
  MenuServiceADMIN: '/menu-service/hotel',
  ListCategoryMovie: '/movie-genres',
  SeriesMovie: '/series-movie',
  UserManagement: '/admin/user-management',
  Tracker: '/admin/tracker',
  Metrics: '/admin/metrics',
  Health: '/admin/health',
  Configuration: '/admin/configuration',
  Logs: '/admin/logs',
  Docs: '/admin/docs',
  Device: '/device',
  DetailDeviceADMIN: '/device/hotel',
  IntroHotel: '/intro-hotel',
  IntroHotelADMIN: '/intro-hotel/hotel',
  LogoHotel: '/logo-hotel',
  Dashboard: '/dashboard',
  Noti: '/notification',
};
