import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { JhiItemCount, JhiPagination, TextFormat, Translate, ValidatedField, Storage, getSortState, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { APP_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { Radio, RadioChangeEvent } from 'antd';
import { Inform } from 'app/shared/model/enumerations/inform.model';
import { Language } from 'app/shared/model/enumerations/language.model';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getEntitiesExtend, multiDeleteMenuService } from 'app/entities/menu-service/menu-service-extend.reducer';
import ModalConfirm from 'app/components/modal-confirm/ModalConfirm';
import { EPath } from 'app/utils/constants/EPath';
import { useDebounce } from 'app/utils/customHooks/useDebounce';
import InputSearch from 'app/components/input-search';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';
import { toast } from 'react-toastify';

export type TMenuServiceCommon = {
  type: string;
};

export const MenuServiceCommon = (props: TMenuServiceCommon) => {
  const { type } = props;
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const languageParam = params.get('language');
  const { id } = useParams<'id'>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'createdAt', 'desc'), location.search)
  );

  const [language, setLanguage] = useState(languageParam ?? Language.VN);

  const menuServiceList = useAppSelector(state => state.menuServiceExtend.entities);
  const loading = useAppSelector(state => state.menuServiceExtend.loading);
  const totalItems = useAppSelector(state => state.menuServiceExtend.totalItems);

  const [textSearch, setTextSearch] = useState('');
  const debouncedValue = useDebounce<string>(textSearch, 500);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const openConfirmDelete = () => {
    setOpenConfirm(!openConfirm);
  };

  const onSubmitConfirmDelete = async () => {
    dispatch(spinLoading());
    try {
      const params = {
        listId: selectedRows
      };
      await dispatch(multiDeleteMenuService(params));
      setSelectedRows([]);
      toast.success("Success");
    } catch (err) {
      toast.error(err.errorFields[0].errors[0]);
    } finally {
      openConfirmDelete();
      dispatch(spinUnLoading());
      sortEntities();
    }
  };

  const selectRow = (id: string): void => {
    setSelectedRows([...selectedRows, id]);
  };

  const deleteRow = (id: string): void => {
    setSelectedRows(selectedRows.filter(i => i !== id));
  };

  useEffect(() => {
    selectedRows.length > 0 && selectedRows.length === menuServiceList.length ? setSelectAll(true) : setSelectAll(false);
  }, [selectedRows]);

  useEffect(() => {
    selectAll ? setSelectedRows(menuServiceList.map(menuService => menuService.id)) : null;
    if (!selectAll && selectedRows.length === menuServiceList.length) {
      setSelectedRows([]);
    }
  }, [selectAll]);

  const getAllEntities = () => {
    dispatch(
      getEntitiesExtend({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order ?? 'desc'}`,
        ...(type === Inform.ABOUT_HOTEL && role.includes(AUTHORITIES.ADMIN) ? { hotelId: id } : {}),
        language: language,
        nameSearch: textSearch
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&language=${language}&sort=${paginationState.sort},${paginationState.order ?? 'createdAt,desc'
      }`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, location, language, debouncedValue]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1]
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleNextToDetail = (menuId: number) => {
    if (type === Inform.ABOUT_HOTEL) {
      navigate(
        role.includes(AUTHORITIES.ADMIN)
          ? `${EPath.ServiceHotelADMIN}/${id}?page=1&menuId=${menuId}&language=${language}`
          : `${EPath.ServiceHotel}?page=1&menuId=${menuId}&language=${language}`
      );
    } else if (type === Inform.MOVIE) {
      navigate(`${EPath.SeriesMovie}?page=1&menuId=${menuId}&language=${language}`);
    } else {
      // TODO: I will update the router later
      navigate('');
    }
  };

  const onChange = (e: RadioChangeEvent) => {
    setLanguage(e.target.value);
  };

  return (
    <div>
      <div id="menu-service-heading" data-cy="MenuServiceHeading">
        <h2>
          <div className="d-flex gap-3 align-items-center">
            {type === Inform.ABOUT_HOTEL ? (
              <>
                {role.includes(AUTHORITIES.ADMIN) ? (
                  <FontAwesomeIcon icon="arrow-left" className="cursor-pointer" onClick={() => navigate(EPath.MenuService)} />
                ) : null}
                <Translate contentKey="hotelTvApp.menuService.home.title">Menu Services</Translate>
              </>
            ) : (
              <Translate contentKey="hotelTvApp.menuService.home.titleMovies">Movies</Translate>
            )}
          </div>
        </h2>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="w-25">
            <InputSearch name={'textSearch'} onChange={e => setTextSearch(e.target.value)} />
          </div>
          <div>
            <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />
              <Translate contentKey="hotelTvApp.menuService.home.refreshListLabel">Refresh List</Translate>
            </Button>
            {!role.includes(AUTHORITIES.MONITOR)
              ? (<Link
                to={
                  type == Inform.ABOUT_HOTEL
                    ? role.includes(AUTHORITIES.ADMIN)
                      ? `${EPath.MenuServiceADMIN}/${id}/new?language=${language}`
                      : `${EPath.MenuService}/new?language=${language}`
                    : `${EPath.ListCategoryMovie}/new?language=${language}`
                }
                className="btn btn-primary jh-create-entity"
                id="jh-create-entity"
                data-cy="entityCreateButton"
              >
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="hotelTvApp.menuService.home.createLabel">Create new Menu Service</Translate>
              </Link>)
              : null
            }
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <p className="mb-0">Choose language:</p>
            <Radio.Group value={language} onChange={onChange} className="my-3">
              <Radio.Button value={Language.VN}>
                <Translate contentKey={`hotelTvApp.menuService.${Language.VN}`}>Vietnamese</Translate>
              </Radio.Button>
              <Radio.Button value={Language.EN}>
                <Translate contentKey={`hotelTvApp.menuService.${Language.EN}`}>English</Translate>
              </Radio.Button>
              <Radio.Button value={Language.JA}>
                <Translate contentKey={`hotelTvApp.menuService.${Language.JA}`}>Japanese</Translate>
              </Radio.Button>
              <Radio.Button value={Language.KO}>
                <Translate contentKey={`hotelTvApp.menuService.${Language.KO}`}>Korean</Translate>
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <div className="position-absolute mt-2">
          {selectedRows.length > 0 ? (
            <div className="d-flex align-items-center fs-6 gap-2">
              <div style={{ minWidth: '120px' }}>
                {translate('global.multiDelete.titleSelected')}: {selectedRows.length}
              </div>
              {!role.includes(AUTHORITIES.MONITOR)
                ? (<Button size="sm" color="danger" onClick={openConfirmDelete}>
                  <FontAwesomeIcon icon="trash" />{' '}
                </Button>)
                : null
              }
            </div>
          ) : null}
        </div>
        {menuServiceList && menuServiceList.length > 0 ? (
          <Table responsive className="table table-hover mt-5">
            <thead>
              <tr>
                <th className="hand position-sticky start-0 bg-white">
                  <ValidatedField
                    className="checkbox-select-table-row"
                    type="checkbox"
                    name={'checkboxAllRow'}
                    checked={selectAll}
                    onChange={e => {
                      const { checked } = e.target;
                      checked ? setSelectAll(true) : setSelectAll(false);
                    }}
                  />
                </th>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hotelTvApp.menuService.stt">STT</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="hotelTvApp.menuService.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="hotelTvApp.menuService.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="hotelTvApp.menuService.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="hotelTvApp.menuService.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hotelTvApp.menuService.author">Author</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand text-center position-sticky end-0 bg-white">
                  <Translate contentKey="hotelTvApp.menuService.actions">Actions</Translate>
                </th>
              </tr>
            </thead>
            <tbody>
              {menuServiceList.map((menuService, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable" onClick={() => handleNextToDetail(menuService.id)} className="cursor-pointer">
                  <td className="position-sticky start-0 bg-white">
                    <div onClick={e => e.stopPropagation()}>
                      <ValidatedField
                        className="checkbox-select-table-row"
                        type="checkbox"
                        name={`menu${i}`}
                        checked={selectedRows.includes(menuService.id)}
                        onChange={e => {
                          const { checked } = e.target;
                          if (checked) {
                            !selectedRows.includes(menuService.id) ? selectRow(menuService.id) : null;
                          } else {
                            deleteRow(menuService.id);
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td className='stt'>{(paginationState.activePage) * paginationState.itemsPerPage + i + 1 - paginationState.itemsPerPage}</td>
                  <td className='data-row-lg'>{menuService.name}</td>
                  <td className='data-row-xl'>{menuService.description}</td>
                  <td className='data-row-sm'>
                    {menuService.createdAt ? <TextFormat type="date" value={menuService.createdAt} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td className='data-row-sm'>
                    {menuService.updatedAt ? <TextFormat type="date" value={menuService.updatedAt} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td className='data-row-lg'>{menuService.author ? menuService.author : ''}</td>
                  <td className="text-end position-sticky end-0 bg-white">
                    <div className="btn-group flex-btn-group-container" onClick={e => e.stopPropagation()}>
                      <Button
                        tag={Link}
                        to={
                          type == Inform.ABOUT_HOTEL
                            ? role.includes(AUTHORITIES.ADMIN)
                              ? `${EPath.MenuServiceADMIN}/${id}/${menuService.id}`
                              : `${EPath.MenuService}/${menuService.id}`
                            : `${EPath.ListCategoryMovie}/${menuService.id}`
                        }
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="eye" />
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        hidden={role.includes(AUTHORITIES.MONITOR)}
                        tag={Link}
                        to={
                          type == Inform.ABOUT_HOTEL
                            ? role.includes(AUTHORITIES.ADMIN)
                              ? `${EPath.MenuServiceADMIN}/${id}/${menuService.id}/edit?language=${language}`
                              : `${EPath.MenuService}/${menuService.id}/edit?language=${language}`
                            : `${EPath.ListCategoryMovie}/${menuService.id}/edit?language=${language}`
                        }
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="pencil-alt" />
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        hidden={role.includes(AUTHORITIES.MONITOR)}
                        tag={Link}
                        to={
                          type == Inform.ABOUT_HOTEL
                            ? role.includes(AUTHORITIES.ADMIN)
                              ? `${EPath.MenuServiceADMIN}/${id}/${menuService.id}/delete`
                              : `${EPath.MenuService}/${menuService.id}/delete`
                            : `${EPath.ListCategoryMovie}/${menuService.id}/delete`
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                        className='btn-action-table'
                      >
                        <FontAwesomeIcon icon="trash" />
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hotelTvApp.menuService.home.notFound">No Menu Services found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={menuServiceList && menuServiceList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <ModalConfirm
        isOpen={openConfirm}
        toggle={openConfirmDelete}
        title={translate('global.multiDelete.confirm.titleConfirm')}
        onSubmit={onSubmitConfirmDelete}
      >
        {`
          ${translate('global.multiDelete.confirm.contentConfirm')} 
          ${selectedRows.length} 
          ${translate('global.multiDelete.confirm.dataConfirm')}?
        `}
      </ModalConfirm>
    </div>
  );
};

export default MenuServiceCommon;
