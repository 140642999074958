import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Storage, Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';

import { Language } from 'app/shared/model/enumerations/language.model';
import { TYPE_SERVICE_PACK, getEntities } from '../channel-pack-master/channel-pack-master-extend.reducer';
import { getEntity, updateEntity } from './device.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { Status } from 'app/shared/model/enumerations/status.model';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

export const DeviceUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const { idDevice } = useParams<'idDevice'>();
  const channelPackMasters = useAppSelector(state => state.channelPackMasterExtend.entities);
  const deviceEntity = useAppSelector(state => state.device.entity);
  const loading = useAppSelector(state => state.device.loading);
  const updating = useAppSelector(state => state.device.updating);
  const updateSuccess = useAppSelector(state => state.device.updateSuccess);
  const languageValues = Object.keys(Language);
  const role = Storage.session.get('role');

  const handleClose = () => {
    history.back();
  };

  useEffect(() => {
    if (role.includes(AUTHORITIES.ADMIN)) {
      dispatch(getEntity(idDevice));
      dispatch(getEntities({ type: TYPE_SERVICE_PACK.ACTIVATE, hotelId: id }));
    } else {
      dispatch(getEntity(id));
      dispatch(getEntities({ type: TYPE_SERVICE_PACK.ACTIVATE }));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    dispatch(spinLoading());
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);
    values.activationDate = convertDateTimeToServer(values.activationDate);
    values.expiryDate = convertDateTimeToServer(values.expiryDate);

    const entity = {
      ...deviceEntity,
      ...values,
      room: {
        ...deviceEntity.room,
        name: values.room,
      },
      channelPackMaster: channelPackMasters.find(it => it.id.toString() === values.channelPackMaster.toString()),
      isActive: values.isActive === "1",
    };
    await dispatch(updateEntity(entity));
    dispatch(spinUnLoading());
  };

  const defaultValues = {
    language: 'JA',
    ...deviceEntity,
    room: deviceEntity?.room?.name,
    channelPackMaster: deviceEntity?.channelPackMaster?.id,
    isActive: deviceEntity.isActive ? "1" : "0",
    activationDate: convertDateTimeFromServer(deviceEntity.activationDate),
    expiryDate: convertDateTimeFromServer(deviceEntity.expiryDate),
  }

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hotelTvApp.apiKey.home.createOrEditLabel" data-cy="ApiKeyCreateUpdateHeading">
            <Translate contentKey="hotelTvApp.apiKey.home.editLabel">Edit a ApiKey</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues} onSubmit={saveEntity}>
              <ValidatedField
                label={translate('hotelTvApp.apiKey.room')}
                id="device-room"
                name="room"
                data-cy="room"
                type="text"
              />
              <ValidatedField
                label={translate('hotelTvApp.apiKey.deviceId')}
                id="device-uniqueKey"
                name="uniqueKey"
                data-cy="uniqueKey"
                type="text"
                disabled
              />
              <ValidatedField
                label={translate('hotelTvApp.apiKey.isActive')}
                id="device-author"
                name="isActive"
                data-cy="author"
                type="select"
              >
                <option value={"1"}>{translate('global.form.active')}</option>
                <option value={"0"}>{translate('global.form.deactive')}</option>
              </ValidatedField>
              <ValidatedField
                id="device-channelPackMaster"
                name="channelPackMaster"
                data-cy="channelPackMaster"
                label={translate('hotelTvApp.apiKey.channelPackMaster')}
                type="select"
              >
                <option value="" key="0">{translate('hotelTvApp.apiKey.noChannel')}</option>
                {channelPackMasters
                  ? channelPackMasters.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>{
                      `${otherEntity.name} - (${otherEntity.packageDuration ? otherEntity.packageDuration : translate('hotelTvApp.apiKey.noDuration')} ${translate('hotelTvApp.apiKey.day')})`
                    }</option>
                  ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('hotelTvApp.apiKey.status')}
                id="device-status-channel"
                name="status"
                data-cy="status"
                type="select"
              >
                <option value=""></option>
                <option value={Status.ACTIVATE}>{translate('global.form.active')}</option>
                <option value={Status.DEACTIVATE}>{translate('global.form.deactive')}</option>
              </ValidatedField>
              <ValidatedField
                label={translate('hotelTvApp.apiKey.activationDate')}
                id="device-activationDate"
                name="activationDate"
                data-cy="activationDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                disabled
              />
              <ValidatedField
                label={translate('hotelTvApp.apiKey.expirationDate')}
                id="device-expiryDate"
                name="expiryDate"
                data-cy="expiryDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                disabled
              />
              <ValidatedField
                label={translate('hotelTvApp.apiKey.updatedBy')}
                id="device-author"
                name="author"
                data-cy="author"
                type="text"
                disabled
              />
              <Button
                id="cancel-save"
                data-cy="entityCreateCancelButton" color="info"
                onClick={() => history.back()}
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DeviceUpdate;
