import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Badge, Button, Col, Row } from 'reactstrap';
import { APP_DATE_FORMAT, LANGUAGES_UNIT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './series-movie.reducer';
import { TypeMovie } from 'app/shared/model/enumerations/type-movie.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { convertMoneyInput } from 'app/utils/helpers/func';

export const SeriesMovieDetailExtend = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const seriesMovieEntity = useAppSelector(state => state.seriesMovie.entity);

  return seriesMovieEntity ? (
    <Row>
      <Col md="8">
        <h2 data-cy="seriesMovieDetailsHeading">
          <Translate contentKey="hotelTvApp.seriesMovie.detail.title">SeriesMovie</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="status">
              <Translate contentKey="hotelTvApp.seriesMovie.status">Status</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity.status === Status.ACTIVATE
            ? <Badge color="primary">{translate("global.form.active")}</Badge>
            : <Badge color="danger">{translate("global.form.deactive")}</Badge>}
          </dd>
          <dt>
            <span id="type">
              <Translate contentKey="hotelTvApp.seriesMovie.type">Type</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity.type && <Translate contentKey={`hotelTvApp.TypeMovie.${seriesMovieEntity.type}`} />}</dd>
          <dt>
            <span id="category">
              <Translate contentKey="hotelTvApp.seriesMovie.category">Category</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity?.menuService?.name}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="hotelTvApp.seriesMovie.name">Name</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity.name}</dd>
          <dt>
            <span id="thumbnailUrl">
              <Translate contentKey="hotelTvApp.seriesMovie.thumbnailUrl">Thumbnail Url</Translate>
            </span>
          </dt>
          {seriesMovieEntity.thumbnailUrl && (
            <dd>
              {
                <img
                  src={seriesMovieEntity.thumbnailUrl}
                  alt="img"
                  width={300}
                  height={200}
                  style={{
                    objectFit: 'cover'
                  }}
                />
              }
            </dd>
          )}
          <dt>
            <span id="videoUrl">
              <Translate contentKey="hotelTvApp.seriesMovie.videoUrl">Video Url</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity.videoUrl}</dd>
          <dt>
            <span id="region">
              <Translate contentKey="hotelTvApp.seriesMovie.region">Region</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity.region}</dd>
          <dt>
            <span id="year">
              <Translate contentKey="hotelTvApp.seriesMovie.year">Year</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity.year}</dd>
          <dt>
            <span id="director">
              <Translate contentKey="hotelTvApp.seriesMovie.director">Director</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity.director}</dd>
          <dt>
            <span id="actor">
              <Translate contentKey="hotelTvApp.seriesMovie.actor">Actor</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity.actor}</dd>
          <dt>
            <span id="price">
              <Translate contentKey="hotelTvApp.seriesMovie.price">Price</Translate>
            </span>
          </dt>
          <dd>{convertMoneyInput(seriesMovieEntity?.price?.toString())} {
            LANGUAGES_UNIT.filter((unit) => unit.value === seriesMovieEntity.unit)[0]?.label
          }</dd>
          <dt>
            <span id="reviewer">
              <Translate contentKey="hotelTvApp.seriesMovie.reviewer">Reviewer</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity.reviewer}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="hotelTvApp.seriesMovie.description">Description</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity.description}</dd>
          <dt className={`${seriesMovieEntity.type === TypeMovie.SINGLE ? '' : 'hidden'}`}>
            <span id="duration">
              <Translate contentKey="hotelTvApp.seriesMovie.duration">Duration</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity.duration}</dd>
          <dt className={`${seriesMovieEntity.type === TypeMovie.SERIES ? '' : 'hidden'}`}>
            <span id="episode">
              <Translate contentKey="hotelTvApp.seriesMovie.episode">Episode</Translate>
            </span>
          </dt>
          <dd>{seriesMovieEntity.episode}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="hotelTvApp.seriesMovie.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {seriesMovieEntity.createdAt ? <TextFormat value={seriesMovieEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="hotelTvApp.seriesMovie.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {seriesMovieEntity.updatedAt ? <TextFormat value={seriesMovieEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
        </dl>
        <Button onClick={() => history.back()} replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/series-movie/${seriesMovieEntity.id}/edit?menuId=${seriesMovieEntity?.menuService?.id}&language=${seriesMovieEntity?.menuService?.language}`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default SeriesMovieDetailExtend;
