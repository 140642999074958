import { createAsyncThunk } from '@reduxjs/toolkit';
import { UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';
import { toast } from 'react-toastify';
export interface ResponseS3MultipleImg {
  imgUrl: string;
  welcome: null;
  originFileObj?: RcFile;
}
export interface ResponseS3Img {
  imgUrl: string;
}
export interface ResponseS3Audio {
  audioUrl: string;
}
export interface ResponseS3Video {
  videoUrl: string;
}
interface IRequest {
  file: File;
  type: ETypeFile;
}
export enum ETypeFile {
  video = 'VIDEO',
  image = 'IMAGE',
  ALL = 'ALL'
}

const apiUrl1 = 'api/images/upload';
const apiUrl2 = 'api/images/upload-multipleImage';
const apiUploadAboutHotel = '/api/images/upload-multipleImage/setMedia';
export const uploadSingleFileS3 = createAsyncThunk('product/uploadSingleFile', async ({ file, type }: IRequest) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    const response = await axios.post(apiUrl1, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return { imgUrl: response?.data } as ResponseS3Img | ResponseS3Audio | ResponseS3Video;
  } catch (error) {
    toast.error('upload fail');
    console.error('error', error);
  }
});

export const uploadMultipleFileS3 = createAsyncThunk('product/uploadMultipleFile', async (files: UploadFile<any>[]) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]?.originFileObj as File);
    }
    const response = await axios.post(apiUrl2, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response?.data as ResponseS3MultipleImg[];
  } catch (error) {
    toast.error('upload fail');
    console.error('error', error);
  }
});

type TAboutHotel = {
  imgUrl?: File;
  textMedia?: string;
};
export const uploadAboutHotel = createAsyncThunk('product/uploadMultipleFile', async (data: TAboutHotel[]) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < data.length; i++) {
      formData.append('files', data[i]?.imgUrl as File);
    }
    const response = await axios.post(apiUrl2, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response?.data;
  } catch (error) {
    toast.error('upload fail');
    console.error('error', error);
  }
});
