import { createSlice } from "@reduxjs/toolkit";

export type TConfirmDeviceSlice = {
    data: {
        isOpen: boolean;
        deviceId: string;
        pinCode: string;
    }
}

export const initialState: TConfirmDeviceSlice = {
    data: {
        isOpen: false,
        deviceId: "",
        pinCode: ""
    }
}

export const confirmDeviceSlice = createSlice({
    name: "confirm-endpoint-device",
    initialState,
    reducers: {
        openConfirmEndpointDevice: (state, action) => {
            state.data.isOpen = true;
            state.data.deviceId = action.payload.deviceId;
            state.data.pinCode = action.payload.pinCode;
        },
        closeConfirmEndpointDevice: (state) => {
            state.data.isOpen = false;
            state.data.deviceId = "";
            state.data.pinCode = "";
        }
    }
});

export const { openConfirmEndpointDevice, closeConfirmEndpointDevice } = confirmDeviceSlice.actions;

export default confirmDeviceSlice.reducer;