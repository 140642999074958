import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import HotelPack from './hotel-pack';
import HotelPackDetail from './hotel-pack-detail';
import HotelPackUpdate from './hotel-pack-update';
import HotelPackDeleteDialog from './hotel-pack-delete-dialog';

const HotelPackRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<HotelPack />} />
    <Route path="new" element={<HotelPackUpdate />} />
    <Route path=":id">
      <Route index element={<HotelPackDetail />} />
      <Route path="edit" element={<HotelPackUpdate />} />
      <Route path="delete" element={<HotelPackDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default HotelPackRoutes;
