import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount, translate, ValidatedField, TextFormat, Storage } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IUserInformation } from 'app/shared/model/user-information.model';
import { getEntities, getInformationEntity, multiDeleteUserInformation } from './user-information-extend.reducer';
import ModalConfirm from 'app/components/modal-confirm/ModalConfirm';
import './style.scss';
import { EPath } from 'app/utils/constants/EPath';
import { useDebounce } from 'app/utils/customHooks/useDebounce';
import InputSearch from 'app/components/input-search';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';
import { toast } from 'react-toastify';

export const UserInformationExtend = () => {
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const userInformationList = useAppSelector(state => state.userInformationExtend.entities);
  const loading = useAppSelector(state => state.userInformationExtend.loading);
  const totalItems = useAppSelector(state => state.userInformationExtend.totalItems);

  const [textSearch, setTextSearch] = useState('');
  const debouncedValue = useDebounce<string>(textSearch, 500);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [userLogin, setUserLogin] = useState({});

  const openConfirmDelete = () => {
    setOpenConfirm(!openConfirm);
  };

  const onSubmitConfirmDelete = async () => {
    dispatch(spinLoading());
    try {
      const params = {
        listId: selectedRows
      };
      await dispatch(multiDeleteUserInformation(params));
      setSelectedRows([]);
      toast.success("Success");
    } catch (err) {
      toast.error(err.errorFields[0].errors[0]);
    } finally {
      openConfirmDelete();
      dispatch(spinUnLoading());
      sortEntities();
    }
  };

  const selectRow = (id: string): void => {
    setSelectedRows([...selectedRows, id]);
  };

  const deleteRow = (id: string): void => {
    setSelectedRows(selectedRows.filter(i => i !== id));
  };

  useEffect(() => {
    selectedRows.length > 0 && selectedRows.length === userInformationList.length ? setSelectAll(true) : setSelectAll(false);
  }, [selectedRows]);

  useEffect(() => {
    selectAll ? setSelectedRows(userInformationList.map(userInformation => userInformation.id)) : null;
    if (!selectAll && selectedRows.length === userInformationList.length) {
      setSelectedRows([]);
    }
  }, [selectAll]);

  useEffect(() => {
    dispatch(getInformationEntity()).then((res) => setUserLogin(res.payload?.["data"]));
  }, []);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        nameSearch: textSearch,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=createdDate,desc`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, debouncedValue]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="user-information-heading" data-cy="UserInformationHeading">
        <Translate contentKey="hotelTvApp.userInformation.home.title">User Informations</Translate>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="w-25">
            <InputSearch name={'textSearch'} onChange={e => setTextSearch(e.target.value)} />
          </div>
          <div>
            <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              <Translate contentKey="hotelTvApp.userInformation.home.refreshListLabel">Refresh List</Translate>
            </Button>
            {userLogin && !(userLogin["indexUser"] > 0)
              ? <Link
                to="/user-information/new"
                className="btn btn-primary jh-create-entity"
                id="jh-create-entity"
                data-cy="entityCreateButton"
              >
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="hotelTvApp.userInformation.home.createLabel">Create new User Information</Translate>
              </Link>
              : null
            }
          </div>
        </div>
      </h2>
      <div className="table-responsive">
        <div className="position-absolute mt-2">
          {selectedRows.length > 0 ? (
            <div className="d-flex align-items-center fs-6 gap-2">
              <div style={{ minWidth: '120px' }}>
                {translate('hotelTvApp.userInformation.home.titleSelected')}: {selectedRows.length}
              </div>
              {userLogin && !(userLogin["indexUser"] > 0) &&
                <Button size="sm" color="danger" onClick={openConfirmDelete}>
                  <FontAwesomeIcon icon="trash" />{' '}
                </Button>
              }
            </div>
          ) : null}
        </div>
        {userInformationList && userInformationList.length > 0 ? (
          <Table responsive className="table table-hover mt-5">
            <thead>
              <tr>
                <th className="hand position-sticky start-0 bg-white">
                  <ValidatedField
                    className="checkbox-select-table-row"
                    type="checkbox"
                    name={'checkboxAllRow'}
                    checked={selectAll}
                    onChange={e => {
                      const { checked } = e.target;
                      checked ? setSelectAll(true) : setSelectAll(false);
                    }}
                  />
                </th>
                <th className="hand" onClick={sort('stt')}>
                  <Translate contentKey="hotelTvApp.userInformation.stt">STT</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                {role.includes(AUTHORITIES.ADMIN) ? (
                  <>
                    <th className="hand" onClick={sort('hotel')}>
                      <Translate contentKey="hotelTvApp.userInformation.hotel">Hotel/Resort</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand" onClick={sort('idHotel')}>
                      <Translate contentKey="hotelTvApp.userInformation.idHotel">ID Hotel</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                  </>
                ) : null}
                <th className="hand" onClick={sort('login')}>
                  <Translate contentKey="hotelTvApp.userInformation.login">User name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="hotelTvApp.userInformation.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('role')}>
                  <Translate contentKey="hotelTvApp.userInformation.role">Role</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="hotelTvApp.userInformation.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('phoneNumber')}>
                  <Translate contentKey="hotelTvApp.userInformation.phoneNumber">Phone Number</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('email')}>
                  <Translate contentKey="hotelTvApp.userInformation.email">Email</Translate> <FontAwesomeIcon icon="sort" />
                </th>

                <th className="hand" onClick={sort('position')}>
                  <Translate contentKey="hotelTvApp.userInformation.position">Position</Translate> <FontAwesomeIcon icon="sort" />
                </th>

                <th className="hand" onClick={sort('createdDate')}>
                  <Translate contentKey="hotelTvApp.userInformation.createdDate">Created Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>

                <th className="hand text-center position-sticky end-0 bg-white">
                  <Translate contentKey="hotelTvApp.userInformation.actions">Actions</Translate>{' '}
                </th>
              </tr>
            </thead>
            <tbody>
              {userInformationList.map((userInformation, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable" onClick={() => navigate(`${EPath.UserInformation}/${userInformation.id}`)}>
                  <td className="position-sticky start-0 bg-white">
                    <div onClick={e => e.stopPropagation()}>
                      <ValidatedField
                        className="checkbox-select-table-row"
                        type="checkbox"
                        name={`user${i}`}
                        checked={selectedRows.includes(userInformation.id)}
                        onChange={e => {
                          const { checked } = e.target;
                          if (checked) {
                            !selectedRows.includes(userInformation.id) ? selectRow(userInformation.id) : null;
                          } else {
                            deleteRow(userInformation.id);
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td className='stt'>{(paginationState.activePage - 1) * paginationState.itemsPerPage + i + 1}</td>
                  {role.includes(AUTHORITIES.ADMIN) ? (
                    <>
                      <td className="hotel">{userInformation.hotel ? userInformation.hotel.name : ''}</td>
                      <td className="id-hotel">{userInformation.hotel ? userInformation.hotel.idHotel : ''}</td>
                    </>
                  ) : null}
                  <td className="username">{userInformation.userDTO.login ?? ''}</td>
                  <td className="name">{userInformation.name}</td>
                  <td className="data-row-xl">
                    {userInformation.userDTO.authorities.map((i) =>
                      <Badge key={i} color="info">{i.substring(5)}</Badge>
                    )}
                  </td>
                  <td className="activated">
                    {userInformation.userDTO.activated ? (
                      <Badge color="success">{translate('global.form.active')}</Badge>
                    ) : (
                      <Badge color="danger">{translate('global.form.deactive')}</Badge>
                    )}
                  </td>
                  <td className="phoneNumber">{userInformation.phoneNumber}</td>
                  <td className="email">{userInformation.userDTO ? userInformation.userDTO.email : ''}</td>
                  <td className="position">{userInformation.position}</td>
                  <td className="createdDate">
                    {userInformation.userDTO &&
                      <TextFormat type="date" value={userInformation.userDTO.createdDate} format={APP_DATE_FORMAT} />
                    }
                  </td>
                  <td className="text-end position-sticky end-0 bg-white">
                    <div className="btn-group flex-btn-group-container" onClick={e => e.stopPropagation()}>
                      <Button
                        tag={Link}
                        to={`${EPath.UserInformation}/${userInformation.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                        className="d-flex align-items-center gap-2"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      {userLogin && !(userLogin["indexUser"] > 0)
                        && <>
                          <Button
                            tag={Link}
                            to={`${EPath.UserInformation}/${userInformation.id}/edit`}
                            color="primary"
                            size="sm"
                            data-cy="entityEditButton"
                            className="d-flex align-items-center gap-2"
                          >
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </Button>
                          <Button
                            tag={Link}
                            to={`${EPath.UserInformation}/${userInformation.id}/delete`}
                            color="danger"
                            size="sm"
                            data-cy="entityDeleteButton"
                            className="d-flex align-items-center gap-2"
                          >
                            <FontAwesomeIcon icon="trash" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </Button>
                        </>
                      }
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hotelTvApp.userInformation.home.notFound">No User Informations found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={userInformationList && userInformationList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <ModalConfirm
        isOpen={openConfirm}
        toggle={openConfirmDelete}
        title={translate('hotelTvApp.userInformation.confirm.titleConfirm')}
        onSubmit={onSubmitConfirmDelete}
      >
        {`
          ${translate('hotelTvApp.userInformation.confirm.contentConfirm')} 
          ${selectedRows.length} 
          ${translate('hotelTvApp.userInformation.confirm.dataConfirm')}?
        `}
      </ModalConfirm>
    </div>
  );
};

export default UserInformationExtend;
