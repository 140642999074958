import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IRoom } from 'app/shared/model/room.model';
import { getEntities as getRooms } from 'app/entities/room/room.reducer';
import { IChannelPackMaster } from 'app/shared/model/channel-pack-master.model';
import { getEntities as getChannelPackMasters } from 'app/entities/channel-pack-master/channel-pack-master.reducer';
import { IApiKey } from 'app/shared/model/api-key.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { Language } from 'app/shared/model/enumerations/language.model';
import { getEntity, updateEntity, createEntity, reset } from './api-key.reducer';

export const ApiKeyUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const rooms = useAppSelector(state => state.room.entities);
  const channelPackMasters = useAppSelector(state => state.channelPackMaster.entities);
  const apiKeyEntity = useAppSelector(state => state.apiKey.entity);
  const loading = useAppSelector(state => state.apiKey.loading);
  const updating = useAppSelector(state => state.apiKey.updating);
  const updateSuccess = useAppSelector(state => state.apiKey.updateSuccess);
  const statusValues = Object.keys(Status);
  const languageValues = Object.keys(Language);

  const handleClose = () => {
    navigate('/api-key' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getRooms({}));
    dispatch(getChannelPackMasters({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.activationDate = convertDateTimeToServer(values.activationDate);
    values.expiryDate = convertDateTimeToServer(values.expiryDate);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...apiKeyEntity,
      ...values,
      room: rooms.find(it => it.id.toString() === values.room.toString()),
      channelPackMaster: channelPackMasters.find(it => it.id.toString() === values.channelPackMaster.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          activationDate: displayDefaultDateTime(),
          expiryDate: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          status: 'ACTIVATE',
          language: 'JA',
          ...apiKeyEntity,
          activationDate: convertDateTimeFromServer(apiKeyEntity.activationDate),
          expiryDate: convertDateTimeFromServer(apiKeyEntity.expiryDate),
          createdAt: convertDateTimeFromServer(apiKeyEntity.createdAt),
          updatedAt: convertDateTimeFromServer(apiKeyEntity.updatedAt),
          room: apiKeyEntity?.room?.id,
          channelPackMaster: apiKeyEntity?.channelPackMaster?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hotelTvApp.apiKey.home.createOrEditLabel" data-cy="ApiKeyCreateUpdateHeading">
            <Translate contentKey="hotelTvApp.apiKey.home.createOrEditLabel">Create or edit a ApiKey</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="api-key-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('hotelTvApp.apiKey.key')} id="api-key-key" name="key" data-cy="key" type="text" />
              <ValidatedField
                label={translate('hotelTvApp.apiKey.uniqueKey')}
                id="api-key-uniqueKey"
                name="uniqueKey"
                data-cy="uniqueKey"
                type="text"
              />
              <ValidatedField
                label={translate('hotelTvApp.apiKey.author')}
                id="api-key-author"
                name="author"
                data-cy="author"
                type="text"
              />
              <ValidatedField
                label={translate('hotelTvApp.apiKey.activationDate')}
                id="api-key-activationDate"
                name="activationDate"
                data-cy="activationDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('hotelTvApp.apiKey.expiryDate')}
                id="api-key-expiryDate"
                name="expiryDate"
                data-cy="expiryDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('hotelTvApp.apiKey.status')}
                id="api-key-status"
                name="status"
                data-cy="status"
                type="select"
              >
                {statusValues.map(status => (
                  <option value={status} key={status}>
                    {translate('hotelTvApp.Status.' + status)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('hotelTvApp.apiKey.language')}
                id="api-key-language"
                name="language"
                data-cy="language"
                type="select"
              >
                {languageValues.map(language => (
                  <option value={language} key={language}>
                    {translate('hotelTvApp.Language.' + language)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('hotelTvApp.apiKey.isActive')}
                id="api-key-isActive"
                name="isActive"
                data-cy="isActive"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hotelTvApp.apiKey.createdAt')}
                id="api-key-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('hotelTvApp.apiKey.updatedAt')}
                id="api-key-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('hotelTvApp.apiKey.isDeleted')}
                id="api-key-isDeleted"
                name="isDeleted"
                data-cy="isDeleted"
                check
                type="checkbox"
              />
              <ValidatedField id="api-key-room" name="room" data-cy="room" label={translate('hotelTvApp.apiKey.room')} type="select">
                <option value="" key="0" />
                {rooms
                  ? rooms.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="api-key-channelPackMaster"
                name="channelPackMaster"
                data-cy="channelPackMaster"
                label={translate('hotelTvApp.apiKey.channelPackMaster')}
                type="select"
              >
                <option value="" key="0" />
                {channelPackMasters
                  ? channelPackMasters.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/api-key" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ApiKeyUpdate;
