import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IChannel, defaultValue } from 'app/shared/model/channel.model';

type TypeMultiDelete = {
  listId: string[];
}

const initialState: EntityState<IChannel> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

const apiUrlExtend = 'api/channels-extend';
const apiUrl = 'api/channels';

// Actions

export const getEntitiesExtend = createAsyncThunk(
  'channel_extend/fetch_entity_list',
  async ({ page, size, sort, nameSearch }: IQueryParams & {nameSearch?: string}) => {
    const requestUrl = `${apiUrlExtend}${sort ? `?page=${page}&size=${size}&sort=${sort}&nameSearch=${nameSearch}&` : '?'}cacheBuster=${new Date().getTime()}`;
    return axios.get<IChannel[]>(requestUrl);
  }
);

export const getAllChannelActive = createAsyncThunk('channel_extend/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrlExtend}${`?page=${page}&size=${size}&status=ACTIVATE&`}cacheBuster=${new Date().getTime()}`;
  return axios.get<IChannel[]>(requestUrl);
});

export const getEntityExtend = createAsyncThunk(
  'channel_extend/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IChannel>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntityExtend = createAsyncThunk(
  'channel_extend/create_entity',
  async (entity: IChannel, thunkAPI) => {
    const result = await axios.post<IChannel>(apiUrlExtend, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntityExtend = createAsyncThunk(
  'channel_extend/update_entity',
  async (entity: IChannel, thunkAPI) => {
    const result = await axios.put<IChannel>(`${apiUrlExtend}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntityExtend = createAsyncThunk(
  'channel_extend/partial_update_entity',
  async (entity: IChannel, thunkAPI) => {
    const result = await axios.patch<IChannel>(`${apiUrlExtend}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntityExtend = createAsyncThunk(
  'channel_extend/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrlExtend}/${id}`;
    const result = await axios.delete<IChannel>(requestUrl);
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const multiDeleteChannel = createAsyncThunk(
  'channel_extend/multi_delete_channel_extend',
  async (params: TypeMultiDelete, thunkAPI) => {
    const requestUrl = `${apiUrlExtend}/delete-many`;
    const result = await axios.post<any>(requestUrl, params);
    return result;
  },
  { serializeError: serializeAxiosError }
)

// slice

export const ChannelExtendSlice = createEntitySlice({
  name: 'channel_extend',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntityExtend.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntityExtend.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(multiDeleteChannel.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntitiesExtend), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          updateSuccess: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10)
        };
      })
      .addMatcher(isFulfilled(getAllChannelActive), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10)
        };
      })
      .addMatcher(isFulfilled(createEntityExtend, updateEntityExtend, partialUpdateEntityExtend), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(createEntityExtend, getEntityExtend), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntityExtend, updateEntityExtend, partialUpdateEntityExtend, deleteEntityExtend, multiDeleteChannel), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  }
});

export const { reset } = ChannelExtendSlice.actions;

// Reducer
export default ChannelExtendSlice.reducer;
