import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { UploadFile } from 'antd/es/upload/interface';
import UploadSingleFile from 'app/components/upload/UploadSingleFile';
import { ETypeFile, ResponseS3MultipleImg, uploadSingleFileS3 } from 'app/entities/s3-upload/s3.upload';
import { IMedia, IMediaRequest, IResponsePayload, IUploadFileExtend } from 'app/entities/welcome/welcome-extend-update';
import { IChannel } from 'app/shared/model/channel.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { omit } from 'lodash';
import { createEntityExtend, getEntityExtend, updateEntityExtend } from './channel-extend.reducer';
import { reset } from './channel.reducer';
import { FormProvider, useForm } from 'react-hook-form';
import CommonInput from 'app/components/CommonInput';
import CommonSelect from 'app/components/CommonSelect';
import { CONST_CONVERSION, variables } from 'app/utils/constants/variables';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

export const ChannelUpdateExtend = () => {
  const dispatch = useAppDispatch();
  const [media, setMedia] = useState<IMedia>();
  const navigate = useNavigate();
  const statusValues = Object.keys(Status);
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const channelEntity = useAppSelector(state => state.channelExtend.entity);
  const loading = useAppSelector(state => state.channelExtend.loading);
  const updating = useAppSelector(state => state.channelExtend.updating);
  const updateSuccess = useAppSelector(state => state.channelExtend.updateSuccess);
  const [errorFile, setErrorFile] = useState(false);
  const [url, setUrl] = useState('');
  const methods = useForm({
    mode: 'onSubmit', criteriaMode: 'firstError', reValidateMode: 'onChange'
  });

  const handleClose = () => {
    navigate('/channel' + location.search);
  };

  const getFileUrl = async () => {
    const mediaObj = {} as IMediaRequest;
    const resp1 = (await dispatch(
      uploadSingleFileS3({ file: media.image.originFileObj as File, type: ETypeFile.image })
    )) as IResponsePayload;
    if (resp1?.payload?.imgUrl) {
      mediaObj.imageBackground = resp1?.payload?.imgUrl;
    }
    return mediaObj;
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntityExtend(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    setErrorFile((media?.image?.size / CONST_CONVERSION.VALUE) > variables.IMAGE_SIZE);
  }, [media])

  const saveEntity = async values => {
    dispatch(spinLoading());
    if (!errorFile) {
      const newMedia = media ? await getFileUrl() : '';
      const entity: IChannel = {
        ...channelEntity,
        ...values,
        thumbnailUrl: newMedia ? newMedia.imageBackground : url
      };

      if (isNew) {
        await dispatch(createEntityExtend(omit(entity, 'id')));
      } else {
        await dispatch(updateEntityExtend(omit(entity, 'updatedAt')));
      }
    }
    dispatch(spinUnLoading());
  };

  useEffect(() => {
    isNew
      ? methods.reset({})
      : methods.reset({
        ...channelEntity
      })

    setUrl(channelEntity?.thumbnailUrl);
  }, [channelEntity]);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hotelTvApp.channel.home.createOrEditLabel" data-cy="ChannelCreateUpdateHeading">
            {isNew ? (
              <Translate contentKey="hotelTvApp.channel.home.createLabel">Create a new Channel</Translate>
            ) : (
              <Translate contentKey="hotelTvApp.channel.home.editLabel">Edit a Channel</Translate>
            )}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(saveEntity)}>
                <CommonInput
                  name='name'
                  label={
                    <p style={{ margin: 0 }}>
                      {`${translate('hotelTvApp.channel.name')}`}
                      {<span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  type='text'
                  placeholder={translate('hotelTvApp.channel.name')}
                  rules={{
                    required: {
                      value: true,
                      message: translate('hotelTvApp.channel.message.missName')
                    }
                  }}
                />
                <CommonInput
                  name='description'
                  type='textarea'
                  label={translate('hotelTvApp.channel.description')}
                  placeholder={translate('hotelTvApp.channel.description')}
                  rows={5}
                />
                <div className="text-center mb-2">
                  {`${translate('entity.recomment.image')}`}
                </div>
                <UploadSingleFile
                  accept="image/*"
                  // url={methods.getValues("thumbnailUrl")}
                  url={url}
                  isVideo={false}
                  isAudio={false}
                  media={media as IMedia}
                  isSingleImage={true}
                  setAudioOrVideoOrImage={setMedia}
                  isNew={isNew}
                  setUrl={setUrl}
                />
                {errorFile && <p style={{ color: "red", textAlign: "center" }}>{translate('entity.recomment.image')}</p>}
                <CommonInput
                  name='videoUrl'
                  label={
                    <p style={{ margin: 0 }}>
                      {`${translate('hotelTvApp.channel.videoUrl')}`}
                      {<span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  type='text'
                  placeholder={translate('hotelTvApp.channel.videoUrl')}
                  rules={{
                    required: {
                      value: true,
                      message: translate('hotelTvApp.channel.message.missUrl')
                    }
                  }}
                />
                <CommonSelect
                  name='status'
                  label={translate('hotelTvApp.channel.status')}
                  placeholder={translate('hotelTvApp.channel.status')}
                  options={statusValues.map(status => (
                    { key: status, value: status, label: translate('hotelTvApp.Status.' + status) }
                  ))}
                />
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/channel" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </form>
            </FormProvider>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ChannelUpdateExtend;
