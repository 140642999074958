import 'app/config/dayjs.ts';
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Card } from 'reactstrap';

import { AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import AppRoutes from 'app/routes';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import Header from 'app/shared/layout/header/header';
import { getProfile } from 'app/shared/reducers/application-profile';
import { getAccount, getSession } from 'app/shared/reducers/authentication';
import { Sidebar } from './shared/layout/sidebar/sidebar';
import ModalTerminalDevice from './components/modal-terminal-device/ModalTerminalDevice';
import { closeConfirmEndpointDevice } from './entities/confirmDevice';
import { getEntities } from './entities/device/device.reducer';
import BreadcrumbItem from './components/Breadcrumb/BreadcrumbItem';
import { translate } from 'react-jhipster';
import FallBack from './components/FallBack';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();
  const paramsEndpointDevice = useAppSelector(state => state.confirmDevice.data);

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);
  const account = useAppSelector(state => state.authentication.account);
  const isLoading = useAppSelector(state => state.fallBack.value);
  const paddingTop = '60px';

  const toggle = () => {
    dispatch(closeConfirmEndpointDevice());
    dispatch(getEntities({ page: 1, size: 10, sort: "" }));
  }

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    isAuthenticated ? dispatch(getAccount()) : null
  }, [isAuthenticated]);

  return (
    <BrowserRouter basename={baseHref}>
      <div className="app-container" style={{ paddingTop }}>
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <ErrorBoundary>
          <Header
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            currentLocale={currentLocale}
            ribbonEnv={ribbonEnv}
            isInProduction={isInProduction}
            isOpenAPIEnabled={isOpenAPIEnabled} 
            name={isAuthenticated ? account.login : translate("global.header.nameAccount")}
          />
        </ErrorBoundary>
        <div className="container-fluid view-container" id="app-view-container">
          <div className="body-container">
            <Sidebar className="sidebar" />
            <div>
              <div style={{ paddingLeft: '285px', paddingBottom: '15px' }}>
                <BreadcrumbItem />
              </div>
              <Card className="jh-card">
                <ErrorBoundary>
                  <AppRoutes />
                </ErrorBoundary>
              </Card>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      <ModalTerminalDevice
        isOpen={paramsEndpointDevice.isOpen}
        toggle={toggle}
        deviceId={paramsEndpointDevice.deviceId}
        idHotel={paramsEndpointDevice.pinCode}
      />
      <FallBack isLoading={isLoading} />
    </BrowserRouter>
  );
};

export default App;
