import dayjs from 'dayjs';
import { IIdChannelPacks } from 'app/shared/model/id-channel-packs.model';
import { IPack } from 'app/shared/model/pack.model';
import { IHotel } from 'app/shared/model/hotel.model';

export interface IHotelPack {
  id?: string;
  totalPackService?: number | null;
  author?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isDeleted?: boolean | null;
  idChannelPacks?: IIdChannelPacks[] | null;
  packs?: IPack[] | null;
  hotel?: IHotel | null;
}

export const defaultValue: Readonly<IHotelPack> = {
  isDeleted: false,
};
