import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { GetProp, Select, Table } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import { STATUS_ENUM } from 'app/config/constants';
import { useAppDispatch } from 'app/config/store';
import { getTotalMovieByLanguage } from 'app/entities/series-movie/series-movie-extend.reducer';
import { Language } from 'app/shared/model/enumerations/language.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { ITEMS_PER_PAGE_MIN } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { getListMovieInPack } from '../channel-pack-master-extend.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

interface DataTypeMovie {
  id: string;
  order: number;
  type: string;
  name: string;
  menuService: {
    name: string;
  };
  thumbnailUrl: string;
  status: Status;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

type TProps = {
  language: Language;
  isNew: boolean;
  packId: string;
  dataMovie: DataTypeMovie[];
  setDataMovie: React.Dispatch<React.SetStateAction<DataTypeMovie[]>>;
};

const TableMovie = ({
  isNew,
  language,
  packId,
  dataMovie,
  setDataMovie,
}: TProps) => {
  const dispatch = useAppDispatch();

  const [tableParamMovie, setTableParamMovie] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: ITEMS_PER_PAGE_MIN
    }
  });
  const [totalMovie, setTotalMovie] = useState<number>(0);
  const [dataMovieCurrent, setDataMovieCurrent] = useState<DataTypeMovie[]>([]);
  const [dataMapMovieCurrent, setDataMapMovieCurrent] = useState<DataTypeMovie[]>([]);
  const [dataMovieUpdateCurrent, setDataMovieUpdateCurrent] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const handleChangeStatusMovie = (e, movieId: string) => {
    const updatedDataMap = dataMapMovieCurrent.map(movie => {
      if (movie.id === movieId) {
        return { ...movie, status: e };
      }
      return movie;
    });
    setDataMapMovieCurrent(updatedDataMap);

    const updatedDataMoiveUpdate = dataMovieUpdateCurrent.map(movie => {
      if (movie.id === movieId) {
        return { ...movie, status: e };
      }
      return movie;
    });
    setDataMovieUpdateCurrent(updatedDataMoiveUpdate);
  };

  const columnMovie: ColumnsType<DataTypeMovie> = [
    {
      title: <Translate contentKey="hotelTvApp.seriesMovie.stt">STT</Translate>,
      dataIndex: 'order',
      render: (_, record, index) => tableParamMovie.pagination.current * tableParamMovie.pagination.pageSize + index + 1 - tableParamMovie.pagination.pageSize,
      width: '10%'
    },
    {
      title: <Translate contentKey="hotelTvApp.seriesMovie.type">Type</Translate>,
      dataIndex: 'type',
      width: '10%'
    },
    {
      title: <Translate contentKey="hotelTvApp.seriesMovie.category">Category</Translate>,
      dataIndex: 'category',
      render: (_, record) => record.menuService.name,
      width: '20%'
    },
    {
      title: <Translate contentKey="hotelTvApp.seriesMovie.name">Name</Translate>,
      dataIndex: 'name',
      width: '20%'
    },
    {
      title: <Translate contentKey="hotelTvApp.channel.thumbnailUrl">Thumbnail Url</Translate>,
      dataIndex: 'thumbnailUrl',
      align: 'center',
      render: url => {
        if (url) {
          return <img style={{ width: '100px', height: '50px', objectFit: 'cover' }} src={url} alt="img_channel" />;
        }
      },
      width: '20%'
    },
    {
      title: <Translate contentKey="hotelTvApp.channel.status">Status</Translate>,
      dataIndex: 'status',
      render: (_, record) => (
        <Select
          defaultValue={record.status}
          style={{ width: 130 }}
          options={STATUS_ENUM.map((i) => ({
            value: i.value,
            label: <p style={{ margin: '0', color: i.value === 'ACTIVATE' ? "green" : "red" }}>{i.label}</p>
          }))}
          onChange={e => handleChangeStatusMovie(e, record.id)}
        />
      ),
      width: '20%'
    }
  ];

  useEffect(() => {
    setDataMovieUpdateCurrent([]);
  }, []);

  useEffect(() => {
    if (dataMovieCurrent) {
      const mapMovies = dataMovieCurrent.map(movie => {
        const mapMovie = dataMovieUpdateCurrent?.find(m => m.id === movie.id);
        if (mapMovie) {
          return { ...movie, status: mapMovie.status };
        }
        return movie;
      });
      setDataMapMovieCurrent(mapMovies);

      const newMovies = dataMovieCurrent.filter(movie => !dataMovieUpdateCurrent?.some(m => m.id === movie.id));
      setDataMovieUpdateCurrent([...dataMovieUpdateCurrent, ...newMovies]);
    }
  }, [dataMovieCurrent]);

  useEffect(() => {
    setDataMovie(dataMovieUpdateCurrent);
  }, [dataMovieUpdateCurrent])

  useEffect(() => {
    setTableParamMovie(
      {
        pagination: {
          current: 1,
          pageSize: tableParamMovie.pagination.pageSize,
          total: totalMovie
        }
      }
    )
  }, [tableParamMovie.pagination.pageSize]);

  //List movie new
  const getTotalMovie = async () => {
    const res = (await dispatch(
      getTotalMovieByLanguage({
        language: language,
        page: tableParamMovie.pagination.current - 1,
        size: tableParamMovie.pagination.pageSize
      })
    )) as any;
    setTotalMovie(Number(res.payload.headers['x-total-count']));

    if (isNew) {
      setDataMovieCurrent(res.payload.data.map(movie => ({ ...movie, status: 'DEACTIVATE' })));
    }
  };

  useEffect(() => {
    getTotalMovie();
  }, [language, tableParamMovie.pagination.pageSize, tableParamMovie.pagination.current, isNew]);

  //update total Movie by Language
  useEffect(() => {
    setTableParamMovie({
      ...tableParamMovie,
      pagination: {
        ...tableParamMovie.pagination,
        total: totalMovie
      }
    });
  }, [totalMovie]);

  const getDataMovie = async () => {
    const res = (await dispatch(
      getListMovieInPack({
        moviePage: tableParamMovie.pagination.current - 1,
        movieSize: tableParamMovie.pagination.pageSize,
        // sort: `${paginationState.sort},${paginationState.order}`,
        packId: packId,
        language: language
      })
    )) as any;
    setDataMovieCurrent(res.payload.seriesMovies);
  };

  useEffect(() => {
    if (!isNew) {
      getDataMovie();
    }
  }, [tableParamMovie.pagination.current, tableParamMovie.pagination.pageSize, language, packId]);

  const handleTableMovie: TableProps['onChange'] = (pagination, filters, sorter) => {
    setTableParamMovie({
      pagination,
      filters,
      ...sorter
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParamMovie.pagination?.pageSize) {
      setDataMovieCurrent([]);
      setDataMapMovieCurrent([]);
    }
  };

  return (
    <div className="m-3">
      <div className="d-flex gap-1 align-items-center mb-3 cursor-pointer" onClick={() => setShowTable(!showTable)}>
        <div>{translate(`hotelTvApp.menuService.${language}`)}</div>
        {showTable ? (
          <FontAwesomeIcon icon={faCaretDown} style={{ fontSize: 12, fontWeight: 'bold' }} />
        ) : (
          <FontAwesomeIcon icon={faCaretUp} style={{ fontSize: 12, fontWeight: 'bold' }} />
        )}
      </div>

      {showTable && (
        <Table
          columns={columnMovie}
          rowKey={record => record.id}
          dataSource={dataMapMovieCurrent}
          pagination={{
            ...tableParamMovie.pagination,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 50]
          }}
          onChange={handleTableMovie}
        />
      )}
    </div>
  );
};
export default TableMovie;
