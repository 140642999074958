import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/room">
        <Translate contentKey="global.menu.entities.room" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-information">
        <Translate contentKey="global.menu.entities.userInformation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/welcome">
        <Translate contentKey="global.menu.entities.welcome" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/contract">
        <Translate contentKey="global.menu.entities.contract" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/pack">
        <Translate contentKey="global.menu.entities.pack" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/channel-pack-master">
        <Translate contentKey="global.menu.entities.channelPackMaster" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/channel">
        <Translate contentKey="global.menu.entities.channel" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/service-hotel">
        <Translate contentKey="global.menu.entities.serviceHotel" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/api-key">
        <Translate contentKey="global.menu.entities.apiKey" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/menu-service">
        <Translate contentKey="global.menu.entities.menuService" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/series-movie">
        <Translate contentKey="global.menu.entities.seriesMovie" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/hotel">
        <Translate contentKey="global.menu.entities.hotel" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/hotel-pack">
        <Translate contentKey="global.menu.entities.hotelPack" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/intro-hotel">
        <Translate contentKey="global.menu.entities.introHotel" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
