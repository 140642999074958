import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { DeleteOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Space, message } from 'antd';
import UploadSingleFile from 'app/components/upload/UploadSingleFile';
import { getEntitiesExtend as getMenuServices } from 'app/entities/menu-service/menu-service-extend.reducer';
import { TypeMovie } from 'app/shared/model/enumerations/type-movie.model';
import { ETypeFile, uploadSingleFileS3 } from '../s3-upload/s3.upload';
import { IMedia, IMediaRequest, IResponsePayload } from '../welcome/welcome-extend-update';
import { createEntityExtend, updateEntityExtend } from './series-movie-extend.reducer';
import { getEntity, reset } from './series-movie.reducer';
import { Status } from 'app/shared/model/enumerations/status.model';
import { cloneDeep, omit } from 'lodash';
import { toast } from 'react-toastify';
import { LANGUAGES_UNIT } from 'app/config/constants';
import { CONST_CONVERSION, variables } from 'app/utils/constants/variables';
import { convertMoneyInput, stringToNumberMoney } from 'app/utils/helpers/func';
import { FormProvider, useForm } from 'react-hook-form';
import CommonInput from 'app/components/CommonInput';
import CommonSelect from 'app/components/CommonSelect';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

export const SeriesMovieUpdateExtend = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();

  const params = new URLSearchParams(location.search);
  const menuId = params.get('menuId');
  const language = params.get('language');
  const isNew = id === undefined;
  const [form] = Form.useForm();

  const menuServices = useAppSelector(state => state.menuServiceExtend.entities);
  const seriesMovieEntity = useAppSelector(state => state.seriesMovieExtend.entity);
  const loading = useAppSelector(state => state.seriesMovie.loading);
  const updating = useAppSelector(state => state.seriesMovie.updating);
  const updateSuccess = useAppSelector(state => state.seriesMovie.updateSuccess);
  const updateSuccessExtend = useAppSelector(state => state.seriesMovieExtend.updateSuccess);
  const statusValues = Object.keys(Status);
  const typeMovieValues = Object.keys(TypeMovie);
  const [media, setMedia] = useState<IMedia>();
  const [url, setUrl] = useState('');
  const [selectedType, setSelectedType] = useState('SINGLE');
  const [errorImage, setErrorImage] = useState(false);

  const methods = useForm({
    mode: 'onSubmit', criteriaMode: 'firstError', reValidateMode: 'onChange'
  });

  const handleClose = () => {
    navigate(`/series-movie?menuId=${seriesMovieEntity?.menuService?.id ? seriesMovieEntity.menuService.id : menuId}&language=${language}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getMenuServices({ language: language }));
  }, []);

  useEffect(() => {
    if (updateSuccess || updateSuccessExtend) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    console.log("media: ", media);
    if (media) {
      setErrorImage((media.image?.size / CONST_CONVERSION.VALUE) > variables.IMAGE_SIZE);
    }
  }, [media]);

  const getFileUrl = async () => {
    const mediaObj = {} as IMediaRequest;
    const resp1 = (await dispatch(
      uploadSingleFileS3({ file: media.image.originFileObj as File, type: ETypeFile.image })
    )) as IResponsePayload;
    if (resp1?.payload?.imgUrl) {
      mediaObj.imageBackground = resp1?.payload?.imgUrl;
    }
    return mediaObj;
  };

  const movies = [{ name: null, videoUrl: null }];

  const saveEntity = async values => {
    dispatch(spinLoading());
    try {
      if (!errorImage) {
        const newMedia = media ? await getFileUrl() : '';

        const { videoUrl, duration, thumbnailUrl, ...restValues } = values;
        const entity = {
          ...seriesMovieEntity,
          ...restValues,
          videoUrl: selectedType === 'SINGLE' ? videoUrl : '',
          duration: selectedType === 'SINGLE' ? duration : '',
          thumbnailUrl: newMedia ? newMedia.imageBackground : url,
          menuService: menuId && menuServices.find(it => it.id.toString() === menuId),
          price: stringToNumberMoney(values.price),
        };

        await form.validateFields();

        const newMovies = cloneDeep(form.getFieldsValue());
        newMovies.movies?.forEach((movie, index) => {
          movie.index = index + 1;
        });
        const newData = { ...entity, ...newMovies };
        if (isNew) {
          await dispatch(createEntityExtend(omit(newData, 'id')));
        } else {
          await dispatch(updateEntityExtend(newData));
        }
      }
    } catch (err) {
      if (err.errorFields) {
        toast.error(err.errorFields[0].errors[0]);
      }
    } finally {
      dispatch(spinUnLoading());
    }
  };

  useEffect(() => {
    if (seriesMovieEntity?.thumbnailUrl && !isNew) {
      setUrl(seriesMovieEntity?.thumbnailUrl);
    } else {
      setUrl('');
    }
  }, [seriesMovieEntity?.thumbnailUrl, isNew]);

  useEffect(() => {
    if (seriesMovieEntity?.type && !isNew) {
      setSelectedType(seriesMovieEntity?.type);
    }
  }, [seriesMovieEntity?.type, isNew]);


  useEffect(() => {
    if (seriesMovieEntity?.movies && !isNew && selectedType === 'SERIES') {
      const fieldsValue = seriesMovieEntity?.movies?.map(movie => ({
        name: movie?.name,
        videoUrl: movie?.videoUrl
      }));
      form.setFieldValue('movies', fieldsValue);
    } else {
      form.resetFields(['movies']);
    }
  }, [seriesMovieEntity?.movies, isNew, selectedType]);

  useEffect(() => {
    isNew
      ? methods.reset({
        type: selectedType,
        unit: LANGUAGES_UNIT[0].value,
        status: statusValues[0]
      })
      : methods.reset({
        ...seriesMovieEntity,
        type: selectedType,
        menuService: seriesMovieEntity?.menuService?.id,
        price: convertMoneyInput(seriesMovieEntity?.price?.toString())
      });
  }, [seriesMovieEntity]);

  const handleTypeChange = event => {
    setSelectedType(event);
    form.setFieldsValue({
      movies: movies
    });
  };

  useEffect(() => {
    if (isNew && selectedType !== 'SINGLE') {
      form.setFieldsValue({
        movies: movies
      });
    }
  }, [form, selectedType]);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hotelTvApp.seriesMovie.home.createOrEditLabel" data-cy="SeriesMovieCreateUpdateHeading">
            {isNew
              ? <Translate contentKey="hotelTvApp.seriesMovie.home.createLabel">Create a SeriesMovie</Translate>
              : <Translate contentKey="hotelTvApp.seriesMovie.home.editLabel">Edit a SeriesMovie</Translate>
            }
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(saveEntity)}>
                <CommonInput
                  name='name'
                  label={
                    <p style={{ margin: 0 }}>
                      {`${translate('hotelTvApp.seriesMovie.name')}`}
                      {<span style={{ color: '#FF7851' }}>*</span>}
                    </p>
                  }
                  placeholder={translate('hotelTvApp.seriesMovie.name')}
                  rules={{
                    required: {
                      value: true,
                      message: translate('hotelTvApp.seriesMovie.message.missName')
                    }
                  }}
                  type='text'
                />
                <CommonSelect
                  name='type'
                  label={translate('hotelTvApp.seriesMovie.type')}
                  placeholder={translate('hotelTvApp.seriesMovie.type')}
                  options={typeMovieValues.map((typeMovie) => (
                    { key: typeMovie, value: typeMovie, label: translate('hotelTvApp.TypeMovie.' + typeMovie) }
                  ))}
                  onChange={handleTypeChange}
                />
                {selectedType !== 'SINGLE' && (
                  <Form form={form} name="dynamic_form_nest_item" className={`w-100 form_hotel_service`} autoComplete="off">
                    <Form.List name="movies">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <div key={key} className="d-flex align-items-center">
                              <div className="mr-3">{index + 1}.</div>
                              <Space
                                style={{ display: 'flex', marginBottom: 8, justifyContent: 'space-between' }}
                                align="baseline"
                                className="service-hotel"
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, 'name']}
                                  required
                                  rules={[{ required: true, message: translate('hotelTvApp.seriesMovie.message.missName') }]}
                                  validateTrigger="onBlur"
                                >
                                  <Input placeholder="Name" className="w-100" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'videoUrl']}
                                  required
                                  rules={[{ required: true, message: translate('hotelTvApp.seriesMovie.message.missUrl') }]}
                                  validateTrigger="onBlur"
                                >
                                  <Input placeholder="Video URL" className="w-100" />
                                </Form.Item>

                                {fields && fields.length > 1 ? (
                                  <DeleteOutlined
                                    style={{ color: 'red' }}
                                    onClick={() => remove(name)}
                                  />
                                ) : (
                                  <div></div>
                                )}
                              </Space>
                            </div>
                          ))}
                          <Form.Item>
                            <Button onClick={() => add()}>{translate('hotelTvApp.seriesMovie.addEpisode')}</Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form>
                )}
                {selectedType === 'SINGLE' &&
                  <CommonInput
                    name='videoUrl'
                    type='text'
                    rules={{
                      required: {
                        value: selectedType === 'SINGLE',
                        message: translate('hotelTvApp.seriesMovie.message.missUrl')
                      }
                    }}
                    label={
                      <p style={{ margin: 0 }}>
                        {`${translate('hotelTvApp.seriesMovie.videoUrl')}`}
                        {<span style={{ color: '#FF7851' }}>*</span>}
                      </p>
                    }
                    placeholder={translate('hotelTvApp.seriesMovie.videoUrl')}
                  />
                }
                <CommonInput
                  name='description'
                  label={translate('hotelTvApp.seriesMovie.description')}
                  placeholder={translate('hotelTvApp.seriesMovie.description')}
                  type="textarea"
                  rows={5}
                />
                <CommonSelect
                  name='status'
                  label={translate('hotelTvApp.seriesMovie.status')}
                  placeholder={translate('hotelTvApp.seriesMovie.status')}
                  options={statusValues.map((status) => (
                    { key: status, value: status, label: translate('hotelTvApp.Status.' + status) }
                  ))}
                />
                <CommonInput
                  name="region"
                  label={translate('hotelTvApp.seriesMovie.region')}
                  type='text'
                  placeholder={translate('hotelTvApp.seriesMovie.region')}
                />
                <CommonInput
                  name="year"
                  label={translate('hotelTvApp.seriesMovie.year')}
                  type='text'
                  placeholder={translate('hotelTvApp.seriesMovie.year')}
                  rules={{
                    pattern: {
                      value: /\b\d{4}\b/,
                      message: translate('hotelTvApp.seriesMovie.message.patternYear')
                    }
                  }}
                />
                <CommonInput
                  name="director"
                  label={translate('hotelTvApp.seriesMovie.director')}
                  type='text'
                  placeholder={translate('hotelTvApp.seriesMovie.director')}
                />
                <CommonInput
                  name='actor'
                  label={translate('hotelTvApp.seriesMovie.actor')}
                  placeholder={translate('hotelTvApp.seriesMovie.actor')}
                  type="textarea"
                  rows={5}
                />
                <CommonInput
                  name='price'
                  type="text"
                  label={translate('hotelTvApp.seriesMovie.price')}
                  placeholder={translate('hotelTvApp.seriesMovie.price')}
                  onChange={(e) => methods.setValue('price', convertMoneyInput(e))}
                  rules={{
                    pattern: {
                      value: /^[0-9.,]+$/,
                      message: translate("entity.validation.patternPrice")
                    }
                  }}
                />
                <CommonSelect
                  name='unit'
                  label={translate('hotelTvApp.seriesMovie.unit')}
                  placeholder={translate('hotelTvApp.seriesMovie.unit')}
                  options={LANGUAGES_UNIT.map((unit) => (
                    { key: unit.value, value: unit.value, label: unit.label }
                  ))}
                />
                <CommonInput
                  name="reviewer"
                  label={translate('hotelTvApp.seriesMovie.reviewer')}
                  placeholder={translate('hotelTvApp.seriesMovie.reviewer')}
                  type='text'
                  rules={{
                    pattern: {
                      value: /^[0-9]+$/,
                      message: translate("hotelTvApp.seriesMovie.message.patternReviewer")
                    }
                  }}
                />
                <CommonInput
                  name="evaluate"
                  label={translate('hotelTvApp.seriesMovie.evaluate')}
                  placeholder={translate('hotelTvApp.seriesMovie.evaluate')}
                  type='text'
                  rules={{
                    max: {
                      value: 10,
                      message: translate('hotelTvApp.seriesMovie.message.maxLengthEvaluate')
                    },
                    min: {
                      value: 0,
                      message: translate('hotelTvApp.seriesMovie.message.minLengthEvaluate')
                    },
                    pattern: {
                      value: /^[0-9.]+$/,
                      message: translate("hotelTvApp.seriesMovie.message.patternEvaluate")
                    }
                  }}
                />
                <div>Image:</div>
                <div className='text-center mb-2'>{`${translate('entity.recomment.image')} ${translate('entity.recomment.dimension')}: 330*454`}</div>
                <UploadSingleFile
                  accept="image/*"
                  url={url}
                  isVideo={false}
                  isAudio={false}
                  media={media as IMedia}
                  isSingleImage={true}
                  setAudioOrVideoOrImage={setMedia}
                  isNew={isNew}
                  setUrl={setUrl}
                />
                {errorImage && <p style={{ color: "red" }}>{translate('entity.recomment.image')}</p>}
                {selectedType === 'SINGLE' &&
                  <CommonInput
                    name="duration"
                    label={translate('hotelTvApp.seriesMovie.duration')}
                    placeholder={translate('hotelTvApp.seriesMovie.placeholderDuration')}
                    type='text'
                  />
                }
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={handleClose} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </form>
            </FormProvider>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SeriesMovieUpdateExtend;
