import React, { useEffect, useState } from 'react';
import { TeamOutlined, NotificationOutlined, UnorderedListOutlined, HomeOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { useLocation, useNavigate, Route, Routes, Link } from 'react-router-dom';
import { useAppSelector } from 'app/config/store';
import './sidebar.scss';
import { Storage, Translate } from 'react-jhipster';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { EPath } from 'app/utils/constants/EPath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faChartPie,
  faCubes,
  faDoorOpen,
  faFileSignature,
  faFilm,
  faFolder,
  faHotel,
  faInbox,
  faMicrochip,
  faReceipt,
  faServer,
  faTv
} from '@fortawesome/free-solid-svg-icons';
import { DropdownItem } from 'reactstrap';
import { AUTHORITIES } from 'app/config/constants';
import { isArray } from 'lodash';
import { AUTH_TOKEN_KEY, logout } from 'app/shared/reducers/authentication';

const { Header, Content, Sider } = Layout;

export const Sidebar = ({ className }) => {
  const role = Storage.session.get('role');
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [itemActive, setItemActive] = useState([]);
  const [selectedPath, setSelectedPath] = useState(currentPath);
  const account = useAppSelector(state => state.authentication.account);

  useEffect(() => {
    const element = document?.querySelector('.jh-card') as HTMLElement;
    if (account.id && element) {
      element.style.marginLeft = '280px';
    } else {
      element.style.marginLeft = '0px';
    }
  }, [account.id]);

  useEffect(() => {
    setSelectedPath(currentPath);
  }, [currentPath]);

  const items2 = [
    {
      key: EPath.Dashboard,
      label: (
        <Link style={{ textDecoration: 'none' }} to={EPath.Dashboard}>
          <DropdownItem tag={Link} to={EPath.Dashboard} data-cy="data-cy-dashboard">
            <FontAwesomeIcon icon={faChartPie} /> <Translate contentKey="global.menu.dashboard" />
          </DropdownItem>
        </Link>
      ),
      role: [AUTHORITIES.ADMIN, AUTHORITIES.HOTEL, AUTHORITIES.MONITOR]
    },
    {
      key: EPath.Hotel,
      label: (
        <Link style={{ textDecoration: 'none' }} to={EPath.Hotel}>
          <DropdownItem tag={Link} to={EPath.Hotel} data-cy="data-cy-hotel">
            <FontAwesomeIcon icon={faHotel} /> <Translate contentKey="global.menu.entities.hotel" />
          </DropdownItem>
        </Link>
      ),
      role: [AUTHORITIES.ADMIN]
    },
    {
      key: 'channelLists',
      icon: <FontAwesomeIcon icon={faBars} />,
      label: <Translate contentKey="global.menu.entities.channelList" />,
      role: [AUTHORITIES.ADMIN],
      children: [
        {
          key: EPath.Channel,
          label: (
            <Link style={{ textDecoration: 'none' }} to={EPath.Channel}>
              <DropdownItem tag={Link} to={EPath.Channel} data-cy="data-cy-channel">
                <FontAwesomeIcon icon={faTv} /> <Translate contentKey="global.menu.entities.liveTV" />
              </DropdownItem>
            </Link>
          ),
          role: [AUTHORITIES.ADMIN, AUTHORITIES.HOTEL]
        },
        {
          key: EPath.ListCategoryMovie,
          label: (
            <Link style={{ textDecoration: 'none' }} to={EPath.ListCategoryMovie}>
              <DropdownItem tag={Link} to={EPath.ListCategoryMovie} data-cy="data-cy-menu-service">
                <FontAwesomeIcon icon={faFilm} /> <Translate contentKey="global.menu.entities.movieChannel" />
              </DropdownItem>
            </Link>
          ),
          role: [AUTHORITIES.ADMIN, AUTHORITIES.HOTEL, AUTHORITIES.CONTENT_MANAGER]
        }
      ]
    },
    {
      key: EPath.UserInformation,
      label: (
        <Link style={{ textDecoration: 'none' }} to={EPath.UserInformation}>
          <MenuItem icon="users" to={EPath.UserInformation}>
            <Translate contentKey="global.menu.entities.userInformation" />
          </MenuItem>
        </Link>
      ),
      role: [AUTHORITIES.ADMIN, AUTHORITIES.HOTEL]
    },
    {
      key: EPath.Contract,
      label: (
        <Link style={{ textDecoration: 'none' }} to={EPath.Contract}>
          <DropdownItem tag={Link} to={EPath.Contract} data-cy="data-cy-contract">
            <FontAwesomeIcon icon={faFileSignature} /> <Translate contentKey="global.menu.entities.contract" />
          </DropdownItem>
        </Link>
      ),
      role: [AUTHORITIES.ADMIN]
    },
    {
      key: EPath.ChannelPackMaster,
      label: (
        <Link style={{ textDecoration: 'none' }} to={EPath.ChannelPackMaster}>
          <DropdownItem tag={Link} to={EPath.ChannelPackMaster} data-cy="data-cy-channel-pack-master">
            <FontAwesomeIcon icon={faCubes} /> <Translate contentKey="global.menu.entities.channelPackMaster" />
          </DropdownItem>
        </Link>
      ),
      role: [AUTHORITIES.ADMIN, AUTHORITIES.HOTEL, AUTHORITIES, AUTHORITIES.MONITOR, AUTHORITIES.SERVICE_PACK]
    },
    {
      key: EPath.Device,
      label: (
        <Link style={{ textDecoration: 'none' }} to={EPath.Device}>
          <DropdownItem tag={Link} to={EPath.Device} data-cy="data-cy-device">
            <FontAwesomeIcon icon={faMicrochip} /> <Translate contentKey="global.menu.entities.apiKey" />
          </DropdownItem>
        </Link>
      ),
      role: [AUTHORITIES.ADMIN, AUTHORITIES.HOTEL, AUTHORITIES.DEVICE_MANAGER, AUTHORITIES.MONITOR]
    },
    {
      key: EPath.MenuService,
      label: (
        <Link style={{ textDecoration: 'none' }} to={EPath.MenuService}>
          <DropdownItem tag={Link} to={EPath.MenuService} data-cy="data-cy-menuservice">
            <FontAwesomeIcon icon={faServer} /> <Translate contentKey="global.menu.entities.menuServiceHotel" />
          </DropdownItem>
        </Link>
      ),
      role: [AUTHORITIES.ADMIN, AUTHORITIES.HOTEL, AUTHORITIES.CONTENT_MANAGER, AUTHORITIES.MONITOR]
    },
    {
      key: 'aboutHotel',
      icon: <FontAwesomeIcon icon={faReceipt} />,
      label: <Translate contentKey="global.menu.entities.aboutHotel" />,
      role: [AUTHORITIES.ADMIN, AUTHORITIES.HOTEL, AUTHORITIES.CONTENT_MANAGER, AUTHORITIES.MONITOR],
      children: [
        {
          key: EPath.IntroHotel,
          label: (
            <Link style={{ textDecoration: 'none' }} to={EPath.IntroHotel}>
              <DropdownItem tag={Link} to={EPath.IntroHotel} data-cy="data-cy-channel">
                <FontAwesomeIcon icon={faInbox} /> <Translate contentKey="global.menu.entities.introHotel" />
              </DropdownItem>
            </Link>
          ),
          role: [AUTHORITIES.ADMIN, AUTHORITIES.HOTEL, AUTHORITIES.CONTENT_MANAGER]
        },
        {
          key: EPath.LogoHotel,
          label: (
            <Link style={{ textDecoration: 'none' }} to={EPath.LogoHotel}>
              <DropdownItem tag={Link} to={EPath.LogoHotel} data-cy="data-cy-menu-service">
                <FontAwesomeIcon icon={faFolder} /> <Translate contentKey="global.menu.entities.logoHotel" />
              </DropdownItem>
            </Link>
          ),
          role: [AUTHORITIES.ADMIN, AUTHORITIES.HOTEL, AUTHORITIES.CONTENT_MANAGER, AUTHORITIES.MONITOR]
        }
      ]
    },
    {
      key: EPath.Welcome,
      label: (
        <Link style={{ textDecoration: 'none' }} to={EPath.Welcome}>
          <DropdownItem tag={Link} to={EPath.Welcome} data-cy="data-cy-welcome">
            <FontAwesomeIcon icon={faDoorOpen} /> <Translate contentKey="global.menu.entities.welcome" />
          </DropdownItem>
        </Link>
      ),
      role: [AUTHORITIES.ADMIN, AUTHORITIES.HOTEL, AUTHORITIES.CONTENT_MANAGER, AUTHORITIES.MONITOR]
    },
    // {
    //   key: EPath.Noti,
    //   label: (
    //     <Link style={{ textDecoration: 'none' }} to={EPath.Noti}>
    //       <DropdownItem tag={Link} to={EPath.Noti} data-cy="data-cy-menuservice">
    //         <FontAwesomeIcon icon={"bell"} /> <Translate contentKey="global.menu.entities.noti" />
    //       </DropdownItem>
    //     </Link>
    //   ),
    //   role: [AUTHORITIES.ADMIN, AUTHORITIES.HOTEL, AUTHORITIES.CONTENT_MANAGER, AUTHORITIES.MONITOR]
    // },
  ];
  useEffect(() => {
    if (role && isArray(role))  {
      const items = items2.filter(item => role?.some((i) => item.role.includes(i)));
      setItemActive(items);
    } else{
      Storage.session.remove(AUTH_TOKEN_KEY)
    }
  }, [account.id]);

  return (
    <div className={`${className}`}>
      {account.id && (
        <Sider className="newsidebar" width={250}>
          <Menu
            className="scroll-menu"
            mode="inline"
            selectedKeys={[selectedPath]}
            defaultOpenKeys={['path']}
            style={{ height: '100%', borderRight: 0 }}
            items={itemActive}
          />
        </Sider>
      )}
    </div>
  );
};
