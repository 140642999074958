import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IServiceHotel, defaultValue } from 'app/shared/model/service-hotel.model';

type TypeMultiDelete = {
  listId: string[];
}

const initialState: EntityState<IServiceHotel> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};
type serviceHotelQueryParams = IQueryParams & {
  menuId?: string;
  language?: string;
  nameSearch?: string;
};

const apiUrl = 'api/service-hotels-extend';

// Actions

export const getEntitiesExtend = createAsyncThunk(
  'serviceHotelExtend/fetch_entity_list',
  async ({ page, size, sort, menuId, language, nameSearch }: serviceHotelQueryParams) => {
    const requestUrl = `${apiUrl}${
      sort ? `?page=${page}&menuId=${menuId}&language=${language}&size=${size}&sort=${sort}&nameSearch=${nameSearch}&` : '?'
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<IServiceHotel[]>(requestUrl);
  }
);

export const getEntityExtend = createAsyncThunk(
  'serviceHotelExtend/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IServiceHotel>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntityExtend = createAsyncThunk(
  'serviceHotelExtend/create_entity',
  async (entity: IServiceHotel, thunkAPI) => {
    const result = await axios.post<IServiceHotel>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntityExtend = createAsyncThunk(
  'serviceHotelExtend/update_entity',
  async (entity: IServiceHotel, thunkAPI) => {
    const result = await axios.put<IServiceHotel>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntityExtend = createAsyncThunk(
  'serviceHotelExtend/partial_update_entity',
  async (entity: IServiceHotel, thunkAPI) => {
    const result = await axios.patch<IServiceHotel>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntityExtend = createAsyncThunk(
  'serviceHotelExtend/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IServiceHotel>(requestUrl);
    thunkAPI.dispatch(getEntitiesExtend({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const multiDeleteServiceHotel = createAsyncThunk(
  'serviceHotelExtend/multi_delete_service_hotel_extend',
  async (params: TypeMultiDelete, thunkAPI) => {
    const requestUrl = `${apiUrl}/delete-many`;
    const result = await axios.post(requestUrl, params);
    return result;
  },
  { serializeError: serializeAxiosError }
)

// slice

export const ServiceHotelSliceExtend = createEntitySlice({
  name: 'serviceHotelExtend',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntityExtend.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntityExtend.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(multiDeleteServiceHotel.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntitiesExtend), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          updateSuccess: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10)
        };
      })
      .addMatcher(isFulfilled(createEntityExtend, updateEntityExtend, partialUpdateEntityExtend), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntitiesExtend, getEntityExtend), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntityExtend, updateEntityExtend, partialUpdateEntityExtend, deleteEntityExtend, multiDeleteServiceHotel), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  }
});

export const { reset } = ServiceHotelSliceExtend.actions;

// Reducer
export default ServiceHotelSliceExtend.reducer;
