import dayjs from 'dayjs';
import { IChannelShip } from 'app/shared/model/channel-ship.model';
import { ISeriesMovieShip } from 'app/shared/model/series-movie-ship.model';
import { IApiKey } from 'app/shared/model/api-key.model';
import { IHotel } from 'app/shared/model/hotel.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IChannelPackMaster {
  id?: string;
  name?: string | null;
  description?: string | null;
  packageDuration?: number | null;
  liveTVNumber?: string | null;
  movieNumber?: string | null;
  author?: string | null;
  order?: number | null;
  status?: Status | null;
  isSet?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isDeleted?: boolean | null;
  channels?: IChannelShip[] | null;
  seriesMovies?: ISeriesMovieShip[] | null;
  apiKeys?: IApiKey[] | null;
  hotel?: IHotel | null;
}

export const defaultValue: Readonly<IChannelPackMaster> = {
  isSet: false,
  isDeleted: false,
};
