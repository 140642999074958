import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate, Storage } from 'react-jhipster';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { Inform } from 'app/shared/model/enumerations/inform.model';
import { createEntityExtend, updateEntityExtend } from './menu-service-extend.reducer';
import { getEntity, reset } from './menu-service.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { TMenuService } from '.';
import { spinLoading, spinUnLoading } from 'app/shared/reducers/fallback-reducer';

export const MenuServiceUpdateExtend = (props: TMenuService) => {
  const { type } = props;
  const role = Storage.session.get('role');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const language = params.get('language');
  const { id } = useParams<'id'>();
  const { idMenuService } = useParams<'idMenuService'>();
  const isNew = role.includes(AUTHORITIES.ADMIN) ? (type === Inform.ABOUT_HOTEL ? idMenuService === undefined : id === undefined) : id === undefined;
  const menuServiceEntity = useAppSelector(state => state.menuService.entity);
  const loading = useAppSelector(state => state.menuServiceExtend.loading);
  const updating = useAppSelector(state => state.menuServiceExtend.updating);
  const updateSuccess = useAppSelector(state => state.menuServiceExtend.updateSuccess);

  const handleClose = () => {
    history.back();
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      role.includes(AUTHORITIES.ADMIN)
        ? (type === Inform.ABOUT_HOTEL ? dispatch(getEntity(idMenuService)) : dispatch(getEntity(id)))
        : dispatch(getEntity(id))
    }
    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    dispatch(spinLoading());
    const entity = {
      ...menuServiceEntity,
      ...values,
      language: language,
      ...(role.includes(AUTHORITIES.ADMIN) ? { hotelId: id } : {}),
      type: type
    };

    if (isNew) {
      await dispatch(createEntityExtend(entity));
    } else {
      await dispatch(updateEntityExtend(entity));
    }
    dispatch(spinUnLoading())
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
        ...menuServiceEntity,
        hotel: menuServiceEntity?.hotel
      };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hotelTvApp.menuService.home.createOrEditLabel" data-cy="MenuServiceCreateUpdateHeading">
            {isNew ? (
              <Translate contentKey="hotelTvApp.menuService.home.createLabel">Create a new Channel</Translate>
            ) : (
              <Translate contentKey="hotelTvApp.menuService.home.editLabel">Edit a Channel</Translate>
            )}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label={translate('hotelTvApp.menuService.name')}
                id="menu-service-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate('hotelTvApp.menuService.message.missName'),
                  },
                  validate: value => {
                    return !!value.trim() || translate('hotelTvApp.menuService.message.missName');
                  },
                }}
              />
              <ValidatedField
                label={translate('hotelTvApp.menuService.description')}
                id="menu-service-description"
                name="description"
                data-cy="description"
                type="textarea"
                rows={5}
              />
              <Button
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                onClick={() => history.back()}
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MenuServiceUpdateExtend;
