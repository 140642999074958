import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Badge } from 'reactstrap';
import { Translate, TextFormat, Storage, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './device.reducer';
import { EPath } from 'app/utils/constants/EPath';
import { Status } from 'app/shared/model/enumerations/status.model';

export const DeviceDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { idDevice } = useParams<'idDevice'>();
  const role = Storage.session.get('role');

  useEffect(() => {
    role.includes(AUTHORITIES.ADMIN) ? dispatch(getEntity(idDevice)) : dispatch(getEntity(id))
  }, []);

  const deviceEntity = useAppSelector(state => state.device.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="apiKeyDetailsHeading">
          <Translate contentKey="hotelTvApp.apiKey.detail.title">ApiKey</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="room">
              <Translate contentKey="hotelTvApp.apiKey.room">Room</Translate>
            </span>
          </dt>
          <dd>{deviceEntity?.room?.name}</dd>
          <dt>
            <span id="uniqueKey">
              <Translate contentKey="hotelTvApp.apiKey.deviceId">Device Id</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.uniqueKey}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="hotelTvApp.apiKey.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.isActive
            ? <Badge color="primary">{translate("global.form.active")}</Badge>
            : <Badge color="danger">{translate("global.form.deactive")}</Badge>}
          </dd>
          <dt>
            <Translate contentKey="hotelTvApp.apiKey.channelPackMaster">Channel Pack Master</Translate>
          </dt>
          <dd>{deviceEntity.channelPackMaster 
            ? `${deviceEntity.channelPackMaster.name} - (${deviceEntity.channelPackMaster.packageDuration ? deviceEntity.channelPackMaster.packageDuration : translate('hotelTvApp.apiKey.noDuration')} ${translate('hotelTvApp.apiKey.day')})`
            : ''}
          </dd>
          <dt>
            <span id="status">
              <Translate contentKey="hotelTvApp.apiKey.status">Status Channel</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.status
            ? deviceEntity.status === Status.ACTIVATE
              ? <Badge color="primary">{translate("global.form.active")}</Badge>
              : <Badge color="danger">{translate("global.form.deactive")}</Badge>
            : null
          }
          </dd>
          <dt>
            <span id="activationDate">
              <Translate contentKey="hotelTvApp.apiKey.activationDate">Activation Date</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.activationDate ? <TextFormat value={deviceEntity.activationDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="expiryDate">
              <Translate contentKey="hotelTvApp.apiKey.expirationDate">Expiry Date</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.expiryDate ? <TextFormat value={deviceEntity.expiryDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="author">
              <Translate contentKey="hotelTvApp.apiKey.updatedBy">Update By</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.author}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="hotelTvApp.apiKey.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.createdAt ? <TextFormat value={deviceEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="hotelTvApp.apiKey.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{deviceEntity.updatedAt ? <TextFormat value={deviceEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button onClick={() => history.back()} color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          hidden={role.includes(AUTHORITIES.MONITOR)}
          tag={Link}
          to={role.includes(AUTHORITIES.ADMIN)
            ? `${EPath.DetailDeviceADMIN}/${id}/${deviceEntity.id}/edit`
            : `${EPath.Device}/${deviceEntity.id}/edit`
          }
          replace color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DeviceDetail;
